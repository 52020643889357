import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ComProduct } from '@models';

@Injectable()
export class ComProductService extends BaseService<ComProduct> {
    getProductById(id: string): Promise<{data: ComProduct}> {
        return this.httpClient
            .get(`/api/v1/products/${id}`)
            .toPromise()
            .then((res: {data: ComProduct}) => res);
    }
}
