import { VEN } from "./ven.model";

export interface Resource {
    id: string;
    type: string;
    registration_name?: string;
    resource_id: string;
    registration_id: string;
    archived?: boolean;
    vens?: VEN[];
}

export class Resource {
    constructor() {
        this.id = null;
        this.type = 'none';
        this.registration_name = null
        this.resource_id = null;
        this.registration_id = null;
        this.archived = null;
        this.vens = [];
    }
}
