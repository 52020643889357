import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { HomeViewComponent } from './index';
import {MatTableModule} from "@angular/material/table";
import { RouterModule } from '@angular/router';
import {MatStepperModule} from "@angular/material/stepper";
import {MatButtonModule} from "@angular/material/button";
import { EventTableModule } from '@components';

@NgModule({
  declarations: [HomeViewComponent],
  exports: [HomeViewComponent],
    imports: [
        CommonModule,
        EventTableModule,
        TranslateModule,
        MatIconModule,
        MatSortModule,
        MatRippleModule,
        ClipboardModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatTableModule,
        RouterModule,
        MatStepperModule,
        MatButtonModule
    ],
})
export class HomeViewModule {}
