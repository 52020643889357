import { VEN } from "./ven.model";

export interface MarketContext {
    id: string;
    operator_id: string;
    operator_name: string;
    program_id: string;
    program_name: string;
    context_url: string;
    archived: boolean;
    vens?: Array<VEN>;
}

export class MarketContext {
    constructor() {
        this.id = null;
        this.operator_id = null
        this.operator_name = null;
        this.program_id = null;
        this.program_name = null;
        this.context_url = null;
        this.archived = null;
        this.vens = [];
    }
}
