export interface Node {
  id: string;
  display_label: string;
  children?: Node[];
  status?: string;
}

export class Node {
  constructor(
    id: string,
    display_label: string,
    children: Node[],
    status: string,
  ) {
    this.id = id;
    this.display_label = display_label;
    this.children = children;
    this.status = status;
  }
}
