import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DetailsPageHeaderModule } from '@components';
import { GroupDetailsViewComponent } from './index';
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { InlineEditModule, VensTableModule } from "@components";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatInputModule } from "@angular/material/input";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [GroupDetailsViewComponent],
  exports: [GroupDetailsViewComponent],
    imports: [
        CommonModule,
        InlineEditModule,
        TranslateModule,
        DetailsPageHeaderModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatIconModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        RouterModule,
        VensTableModule
    ],
})
export class GroupDetailsViewModule {}
