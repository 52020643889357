import { Injectable } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';

@Injectable()
export class RouterService {
  isNavigating: boolean;

  constructor(private router: Router) {}

  initRouterCheck(): Promise<void> {
    return new Promise<void>(resolve => {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart && event.url !== '/') {
          if (event.id === 1 && event.url.includes('create')) {
            //this.router.navigateByUrl('/');
            return;
          }
          this.isNavigating = true;
        }
        if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.isNavigating = false;
        }
      });
      resolve();
    });
  }
}
