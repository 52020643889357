import { VenRegistration } from './ven-registration.model';
export interface VEN {
  id?: string;
  ven_id: string;
  name: string;
  fingerprint: string;
  signal_style: string;
  opt_out_enabled: boolean;
  archived: boolean;
  registration_id: number;
  resources;
  markets;
  groups?;
  registration?: VenRegistration;
}

export class VEN {
  constructor() {
    this.ven_id = null;
    this.fingerprint = null;
    this.name = null;
    this.registration_id = null;
    this.resources = null;
    this.archived = null;
    this.markets = null;
    this.groups = null;
    this.registration = null;
  }
}
