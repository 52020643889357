import { Injectable } from '@angular/core';
import { LongDateFormatKey, localeData } from 'moment';
import * as moment from 'moment-timezone';
import { BaseService } from './base.service';
import { Timezone } from '@models';

@Injectable()
export class TimezonesService extends BaseService<Timezone> {
  timezones: Timezone[] = [];
  private projectTimezones = new Map<string, string>();
  override path = '/api/v1/timezones';

  loadTimezones(): Promise<Timezone[]> {
    const cached = localStorage.getItem('timezones');
    if (cached) {
        this.timezones.push(...JSON.parse(cached));
        return Promise.resolve(this.timezones);
    }
    return this.getList()
      .toPromise()
      .then((rawResp) => {
        rawResp.sort(
          (a, b) => Number(a.display_label) - Number(b.display_label),
        );
        localStorage.setItem('timezones', JSON.stringify(rawResp));
        this.timezones = [...rawResp];
        return this.timezones;
    });
  }

  setTimezone(projectId: string, timezone: string): void {
    this.projectTimezones.set(projectId, timezone);
  }

  gettimezone_name(projectId: string): string {
    const projectTimezone = this.projectTimezones.get(projectId);
    const timezone = this.timezones.find(
      timezone => timezone.timezone_name === projectTimezone,
    );
    return timezone?.timezone_name;
  }

  getTimezonedisplay_label(projectId: string): string {
    if (!this.timezones?.length) {
      return '-';
    }
    const projectTimezone = this.projectTimezones.get(projectId);
    const timezone = this.timezones.find(
      timezone => timezone.timezone_name === projectTimezone,
    );
    return timezone?.display_label ?? '-';
  }

  getDateTimedisplay_label(
    projectId: string,
    date: string,
    longDateFormat: LongDateFormatKey = 'lll',
  ): string {
    if (!date) {
      return '';
    }
    const timezone_name = this.gettimezone_name(projectId);
    const userLocale = localeData(
      this.adjustLocale(this.cookieService.getLocale()),
    );
    let momentDate = moment(date);
    // If there is no timezone for any reason, stick with GMT Time
    if (timezone_name) {
      momentDate = momentDate.tz(timezone_name);
    }
    return momentDate.format(userLocale.longDateFormat(longDateFormat));
  }

  private adjustLocale(locale: string): string {
    return locale.toLowerCase().replace('_', '-');
  }
}
