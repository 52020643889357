import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../guards/auth.guard';
import {PermissionEnum } from '@models';
import {
  EventResolver,
  ResourceResolver,
  GroupResolver,
  MarketContextsResolver,
  DetailResourceResolver,
  VenResolver,
} from '@resolvers';
import {
    EventDetailsViewComponent,
    HomeViewComponent,
    LeviathanJrModule,
    MarketContextListViewComponent,
    EventListViewComponent,
    GroupDetailsViewComponent,
    GroupEditViewComponent,
    GroupListViewComponent,
    MarketContextEditViewComponent,
    MarketContextDetailsViewComponent,
    ReportEditViewComponent,
    ResourceDetailsViewComponent,
    ResourceEditViewComponent,
    ResourceListViewComponent,
    UnverifiedVenListViewComponent,
    VenDetailsViewComponent,
    VenEditViewComponent,
    VenListViewComponent,
    VenRegistrationListViewComponent,
} from '@views';

const routerOptions: ExtraOptions = {
  onSameUrlNavigation: 'reload',
  relativeLinkResolution: 'legacy',
  enableTracing: false,
};

const routes: Routes = [
  {
    path: '',
    component: HomeViewComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read]},
    resolve: { events: EventResolver, vens: VenResolver },
  },
  {
    path: 'events/:eventId',
    component: EventDetailsViewComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read]},
    resolve: { data: EventResolver },
  },
  {
    path: 'events',
    component: EventListViewComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read]},
  },
  {
    path: 'market-contexts',
    component: MarketContextListViewComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read]},
  },
  {
    path: 'market-contexts/create',
    component: MarketContextEditViewComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read]},
  },
  {
    path: 'market-contexts/:contextId',
    component: MarketContextDetailsViewComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read]},
    resolve: { data: MarketContextsResolver },
  },
  {
    path: 'resources',
    component: ResourceListViewComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read]},
  },
  {
    path: 'resources/create',
    component: ResourceEditViewComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read]},
  },
  {
    path: 'resources/:resourceId',
    component: ResourceDetailsViewComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read]},
    resolve: { data: DetailResourceResolver },
  },
  {
    path: 'resources/:resourceId/edit',
    component: ResourceEditViewComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read]},
  },
  {
    path: 'groups',
    component: GroupListViewComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read]},
  },
  {
    path: 'groups/create',
    component: GroupEditViewComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read]},
  },
  {
    path: 'groups/:groupId',
    component: GroupDetailsViewComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read]},
    resolve: { data: GroupResolver },
  },
  {
    path: 'groups/:groupId/edit',
    component: GroupDetailsViewComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read]},
    resolve: { data: GroupResolver },
  },
  {
    path: 'vens/:venId/reports/:reportId/edit',
    component: ReportEditViewComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read]},
  },
  {
    path: 'vens',
    component: VenListViewComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read]},
  },
  {
    path: 'vens/create',
    component: VenEditViewComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read]},
  },
  {
    path: 'vens/:venId/edit',
    component: VenEditViewComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read]},
    resolve: { data: VenResolver },
  },
  {
    path: 'vens/:venId',
    component: VenDetailsViewComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read]},
    resolve: { data: VenResolver },
  },
  {
    path: 'unverified-vens',
    component: UnverifiedVenListViewComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read]},
  },
  {
    path: 'ven-registrations',
    component: VenRegistrationListViewComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read]},
  },
  {
    path: 'doc',
    redirectTo: '/doc/index.html',
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [
    LeviathanJrModule,
    RouterModule.forRoot(routes, routerOptions),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, EventResolver, DetailResourceResolver, VenResolver, MarketContextsResolver, ResourceResolver, GroupResolver],
})
export class CoreRoutingModule {}
