import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { BaseTableViewComponent } from '../../common/base-table-view';
import { MarketContext } from '@models';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent, ExportModalComponent, ExportModalData } from '@components';
import { GlobalAlertService, MarketContextService } from '@services';
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
    selector: 'market-context-list-view',
    templateUrl: './index.html',
    styleUrls: ['./style.scss'],
})
export class MarketContextListViewComponent extends BaseTableViewComponent implements OnInit, OnDestroy {
    BAD_REQUEST = 'Oops, There was a problem with your request';
    DELETE_SUCCESS = 'Market was deleted successfully';
    DISABLED_SUCCESS = 'Market was disabled successfully';
    RESTORED_SUCCESS = 'Market was restored successfully';
    MARKET_LIST_FOUND = 'Market list was found successfully';
    subscriptions: Subscription[] = [];
    marketContexts: MarketContext[];
    columnsToDisplay = ['marketId', 'operator', 'program', 'actions'];

    @ViewChild('deleteButton', { read: ElementRef }) deleteButtonElement: ElementRef;
    isOpenModal: boolean = false;

    constructor(
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        private marketService: MarketContextService,
        private messageService: GlobalAlertService,
        private _focusMonitor: FocusMonitor,
    ) {
        super();
        this.BAD_REQUEST = this.translateService.instant('notification.bad_request');
        this.DELETE_SUCCESS = this.translateService.instant('markets.delete.notification.success');
        this.DISABLED_SUCCESS = this.translateService.instant('markets.disabled.notification.success');
        this.RESTORED_SUCCESS = this.translateService.instant('markets.restored.notification.success');
    }

    ngOnInit(): void {
        this.initialize(this.route);
    }

    ngAfterViewInit() {
        this._focusMonitor.stopMonitoring(this.deleteButtonElement);
    }

    override dataCall(qp) {
        this.router.navigate(['market-contexts'], {replaceUrl: true, queryParams: qp});
        return this.marketService.getPage(qp);
    }

    openExportModal(): void {
        const data: ExportModalData = {
            service: this.marketService,
            name: 'Market Contexts',
            headers: [
                'ID',
                'Market Context URI',
                'Operator ID',
                'Operator Name',
                'Program Flex ID',
                'Program Name',
                'Archived status',
                'Associated VENs',
            ],
            mapper: (mc: MarketContext) => [
                mc.id,
                mc.context_url,
                mc.operator_id,
                mc.operator_name,
                mc.program_id,
                mc.program_name,
                mc.archived + '',
                `"${mc.vens.map((vens) => vens.id).join(',')}"`,
            ],
            params: {
                archived: this.archived,
            }
        };
        if (this.search) data.params['q'] = this.search;

        this.dialog.open(ExportModalComponent, {width: '350px', data});
    }

    getList(service: Observable<MarketContext[]>) {
        try {
            const listOfResources =  service.subscribe({
                next: (marketList: MarketContext[]) => {
                    this.messageService.setSuccess(this.MARKET_LIST_FOUND);
                    this.marketService.setOpenModal(true);
                    this.isOpenModal = true;
                    this.marketService.setMarketList(marketList)
                    this.loadData();
                },
                error: () => {
                    this.messageService.setError(this.BAD_REQUEST);
                },
            });
            this.subscriptions.push(listOfResources);
        } finally {
            this.closeOutDialogs();
        }
    }

    deleteMarket(market: MarketContext) {
        try {
            const deleteMarketContextSub =  this.marketService.deleteMarketContext$(market.id).subscribe({
                next:() => {
                    this.messageService.setSuccess(this.DELETE_SUCCESS);
                },
                error:() => {
                    this.messageService.setError(this.BAD_REQUEST);
                },
            });
            this.subscriptions.push(deleteMarketContextSub);
        } finally {
            this.closeOutDialogs();
            this.loadData();
        }
    }

    updateArchived(context: MarketContext, disabled: boolean) {
        try {
            const updateMarketContextSub = this.marketService.updateMarketContext$(context.id, context).subscribe({
                next: (updatedContext) => {
                    disabled ? this.messageService.setSuccess(this.DISABLED_SUCCESS): this.messageService.setSuccess(this.RESTORED_SUCCESS);
                    return updatedContext;
                },
                error: () => {
                    this.messageService.setError(this.BAD_REQUEST);
                },
            });
            this.subscriptions.push(updateMarketContextSub);
        } finally {
            this.closeOutDialogs();
            this.loadData();
        }
    }

    openDeleteDialog(market: MarketContext) {
        this.dialog.open(DialogComponent, {
            width: '531px',
            data: {
                title: this.translateService.instant('markets.dialog.delete'),
                secondButtonText: this.translateService.instant('actions.accept'),
                firstButtonText: this.translateService.instant('actions.cancel'),
                secondButtonCallback: () => this.deleteMarket(market),
                firstButtonCallback: () => this.dialog.closeAll(),
            },
        });
    }

    openRestoreDialog(market: MarketContext) {
        this.dialog.open(DialogComponent, {
            width: '531px',
            data: {
                title: this.translateService.instant('markets.dialog.restore'),
                secondButtonText: this.translateService.instant('actions.accept'),
                firstButtonText: this.translateService.instant('actions.cancel'),
                secondButtonCallback: () => {
                    market.archived = false;
                    this.updateArchived(market, false);
                    this.closeOutDialogs();
                },
                firstButtonCallback: () => this.dialog.closeAll(),
            },
        });
    }

    openDisableDialog(market: MarketContext) {
        this.dialog.open(DialogComponent, {
            width: '531px',
            data: {
                title: this.translateService.instant('markets.dialog.disable'),
                secondButtonText: this.translateService.instant('actions.accept'),
                firstButtonText: this.translateService.instant('actions.cancel'),
                secondButtonCallback: () => {
                    market.archived = true;
                    this.updateArchived(market, true);
                    this.closeOutDialogs();
                },
                firstButtonCallback: () => this.dialog.closeAll(),
            },
        });
    }

    closeOutDialogs() {
        this.dialog.closeAll();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscriber) => subscriber.unsubscribe());
    }
    navigateTo(){
        this.router.navigate(['/market-contexts/create']);
    }

}
