<div class="section-header-container">
    <h2>{{ 'home.greeting' | translate }}</h2>
</div>
<div class="section-main-container">
    <div class="table-section">
        <div class="section-header-container">
            <h2>{{ 'home.events.header' | translate }}</h2>
        </div>
        <div class="my-10">
            <event-table
                [dataSource]="eventDataSource"
                [enableSort]="false"
            ></event-table>
        </div>
        <button mat-stroked-button routerLink="events" color="primary">
            {{ 'home.button.view_events' | translate }}
        </button>
    </div>
    <div class="table-section vens">
        <div class="section-header-container">
            <h2>{{ 'home.vens.header' | translate }}</h2>
        </div>
        <div class="table-container-wrapper">
            <table class="table events" [dataSource]="venData" mat-table>
                <!-- VEN ID STARTS -->
                <ng-container matColumnDef="id">
                    <th class="mat-column-event-id" mat-header-cell *matHeaderCellDef>
                        {{ 'home.vens.table.ven_id' | translate }}
                    </th>
                    <td class="mat-column-event-id" mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.id && element.id !== ''; else dashEmpty">
                            <a [routerLink]="['/vens', element.id]">{{ element.ven_id }}<br />({{ element.name }})</a>
                        </ng-container>
                        <ng-template #dashEmpty> - </ng-template>
                    </td>
                </ng-container>
                <!-- VEN ID ENDS -->

                <!-- GROUP STARTS -->
                <ng-container matColumnDef="groups">
                    <th class="mat-column-groups" mat-header-cell *matHeaderCellDef>
                        {{ 'home.vens.table.groups' | translate }}
                    </th>
                    <td class="mat-column-groups" mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.groups && element.groups.length !== 0; else dashEmpty">
                            <div *ngFor="let group of element.groups">
                                <span class="title-bold">{{ 'vens.table.cell.group_id' | translate }}: </span>
                                <a routerLink="{{ '/groups/' + group.id }}">
                                    {{ group.group_id ? group.group_id : '-' }}
                                </a>
                                <br />
                            </div>
                        </ng-container>
                        <ng-template #dashEmpty> - </ng-template>
                    </td>
                </ng-container>
                <!-- GROUP ENDS -->
                <!-- MARKET STARTS -->
                <ng-container matColumnDef="markets">
                    <th class="mat-column-context-url" mat-header-cell *matHeaderCellDef>
                        {{ 'home.vens.table.markets' | translate }}
                    </th>
                    <td class="mat-column-context-url" mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.markets && element.markets.length !== 0; else dashEmpty">
                            <span *ngFor="let market of element.markets">
                                <span class="title-bold">{{ 'vens.table.cell.url' | translate }}: </span>
                                <a routerLink="{{ '/market-contexts/' + market.id }}">
                                    {{ market.context_url ? market.context_url : '-' }}
                                </a>

                                <br />
                            </span>
                        </ng-container>
                        <ng-template #dashEmpty> - </ng-template>
                    </td>
                </ng-container>
                <!-- MARKET ENDS -->
                <!-- REGISTRATION ID STARTS -->
                <ng-container matColumnDef="registrations">
                    <th class="mat-column-event-id" mat-header-cell *matHeaderCellDef>
                        {{ 'home.vens.table.registration_id' | translate }}
                    </th>

                    <td class="mat-column-event-id" mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.registration; else dashEmpty">
                            <span>{{
                                element.registration.registration_id ? element.registration.registration_id : '-'
                            }}</span>
                        </ng-container>
                        <ng-template #dashEmpty> - </ng-template>
                    </td>
                </ng-container>
                <!-- REGISTRATION ID ENDS -->
                <!-- RESOURCES STARTS -->
                <ng-container matColumnDef="resources">
                    <th class="mat-column-resources" mat-header-cell *matHeaderCellDef>
                        {{ 'home.vens.table.resources' | translate }}
                    </th>
                    <td class="mat-column-resources" mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.resources && element.resources.length !== 0; else dashEmpty">
                            <span *ngFor="let resource of element.resources">
                                <span class="title-bold">{{ 'vens.table.cell.resource_id' | translate }}: </span>
                                <a routerLink="{{ '/resources/' + resource.id }}">
                                    {{ resource.resource_id ? resource.resource_id : '-' }}
                                </a>

                                <br />
                            </span>
                        </ng-container>
                        <ng-template #dashEmpty> - </ng-template>
                    </td>
                </ng-container>
                <!-- RESOURCES ENDS -->
                <tr mat-header-row *matHeaderRowDef="columnsToDisplayVen"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplayVen"></tr>
            </table>
        </div>
        <button mat-stroked-button routerLink="vens" color="primary">{{ 'home.button.view_vens' | translate }}</button>
    </div>
</div>
