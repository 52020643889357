import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from './base.service';
import { VEN } from '@models';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class VenService extends BaseService<VEN> {
  override path = '/api/v1/vens';

  getAllVensBySearch$(params?: HttpParams): Observable<VEN[]> {
    return this.getList(params);
  }

  getAllVens$(): Observable<VEN[]> {
    return this.getList();
  }

  getVen$(id: number | string): Observable<VEN> {
    return this.getById(id);
  }

  createVen$(resource: Partial<VEN>): Observable<VEN> {
    return this.add(resource);
  }

  updateVen$(id: number | string, context: VEN | Partial<VEN>): Observable<VEN> {
    return this.update(id, context);
  }

  deleteVen$(id: number | string): Observable<VEN> {
    return this.delete(id);
  }

  private openModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private venList: BehaviorSubject<VEN[]> = new BehaviorSubject<VEN[]>([]);

  public setOpenModal(value: boolean): void {
    this.openModal.next(value);
  }

  public getOpenModal(): BehaviorSubject<boolean> {
    return this.openModal;
  }

  public setVenList(value: VEN[]): void {
    this.venList.next(value);
  }

  public getVenList(): BehaviorSubject<VEN[]> {
    return this.venList;
  }
}
