import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Resource } from '@models';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '@components';
import { ResourceService, GlobalAlertService } from '@services';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'resource-edit-view',
  templateUrl: './index.html',
  styleUrls: ['./style.scss'],
})
export class ResourceEditViewComponent implements OnInit, OnDestroy {
  BAD_REQUEST = 'Oops, There was a problem with your request';
  RESOURCE_SUCCESS = 'Resource was saved successfully';

  subscriptions: Subscription[] = [];
  resource: Partial<Resource> = {type: 'none'};
  id_validation = '^(\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12})$';
  resourceForm = new UntypedFormGroup({
    registration_name: new UntypedFormControl(''),
    resource_id: new UntypedFormControl('', Validators.required),
    registration_id: new UntypedFormControl('', Validators.required),
    type: new UntypedFormControl('none'),
    der_os_program_id: new UntypedFormControl(''),
    der_os_site_ids: new UntypedFormControl(''),
  });
  TYPES = ['none', 'storage'];

  get registration_id() {
    return this.resourceForm.get('registration_id');
  }
  get resourceId() {
    return this.resourceForm.get('resourceId');
  }
  submitting = false;

  constructor(
    private translateService: TranslateService,
    public dialog: MatDialog,
    private resourceService: ResourceService,
    private ngZone: NgZone,
    private router: Router,
    private messageService: GlobalAlertService,
  ) {
    this.BAD_REQUEST = this.translateService.instant('notification.bad_request');
    this.RESOURCE_SUCCESS = this.translateService.instant('resources.create.notification.success');
  }

  ngOnInit(): void {
    const registrationNameSub = this.resourceForm.get('registration_name').valueChanges.subscribe((value) => {
      this.resource.registration_name = value;
    });
    const resourceIdSub = this.resourceForm.get('resource_id').valueChanges.subscribe((value) => {
      this.resource.resource_id = value;
    });
    const registrationIdSub = this.resourceForm.get('registration_id').valueChanges.subscribe((value) => {
      this.resource.registration_id = value;
    });
    const registrationTypeSub = this.resourceForm.get('type').valueChanges.subscribe((value) => {
      this.resource.type = value;
    });

    const der_os_program_idSub = this.resourceForm.get('der_os_program_id').valueChanges.subscribe((value) => {
      this.setResourceIdFromDerOs();
    });
    const der_os_site_idsSub = this.resourceForm.get('der_os_site_ids').valueChanges.subscribe((value) => {
      this.setResourceIdFromDerOs();
    });

    this.subscriptions.push(
        registrationNameSub,
        resourceIdSub,
        registrationIdSub,
        registrationTypeSub,
        der_os_program_idSub,
        der_os_site_idsSub,
    );
  }

  handleEventCancel() {
    this.submitting = false;
    this.router.navigate([`/resources`]);
  }

  changeType() {
    if (this.resourceForm.get('type').value === 'storage') {
        const [pid, sids] = this.resourceForm.get('resource_id').value.split('_');
        this.resourceForm.get('der_os_program_id').setValue(pid || '');
        this.resourceForm.get('der_os_site_ids').setValue(sids || '');
    } else {
        this.setResourceIdFromDerOs();
    }
  }

  setResourceIdFromDerOs() {
    const pid = this.resourceForm.get('der_os_program_id').value;
    const sids = this.resourceForm.get('der_os_site_ids').value;
    let resourceId = `${pid}_${sids}`;
    if (resourceId.endsWith('_')) {
        resourceId = resourceId.slice(0, -1);
    }
    this.resourceForm.get('resource_id').setValue(resourceId);
  }

  createResource() {
    this.submitting = true;
    try {
      const createResourceSub = this.resourceService.createResource$(this.resource).subscribe({
        next: (resource) => {
          this.ngZone.run(() => {
            this.messageService.setSuccess(this.RESOURCE_SUCCESS);
            this.router.navigateByUrl(`resources/${resource.id}`);
          });
        },
        error: () => {
          this.messageService.setError(this.BAD_REQUEST);
        },
      });
      this.subscriptions.push(createResourceSub);
    } finally {
      this.submitting = false;
      this.dialog.closeAll();
    }
  }

  openCancelOrAcceptDialog() {
    this.dialog.open(DialogComponent, {
      width: '531px',
      data: {
        title: this.translateService.instant('resources.create.verification'),
        secondButtonText: this.translateService.instant('actions.accept'),
        firstButtonText: this.translateService.instant('actions.cancel'),
        secondButtonCallback: () => this.createResource(),
        firstButtonCallback: () => this.dialog.closeAll(),
      },
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscriber) => subscriber.unsubscribe());
  }
}
