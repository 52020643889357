import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { Group } from '@models';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GlobalAlertService, GroupService, LoaderService } from '@services';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'group-detail-view',
  templateUrl: './index.html',
  styleUrls: ['./style.scss'],
})
export class GroupDetailsViewComponent implements OnInit, OnDestroy {
  GROUP_SUCCESS = 'Group was updated successfully';
  fieldControls: any[] = [];
  no_group = false;
  NO_GROUP = 'Ooops, The Group you requested was not found';

  subscriptions: Subscription[] = [];
  group: Group;

  id_validation = '^(\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12})$';

  @Input() editGroupForm: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private groupService: GroupService,
    public dialog: MatDialog,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private messageService: GlobalAlertService,
    private router: Router,
  ) {
    this.GROUP_SUCCESS = this.translateService.instant('groups.edit.notification.success');

    this.subscriptions.push(
      this.route.data.subscribe((data) => {
        if (Object.keys(data['data']).length === 0) {
          this.messageService.setError(this.NO_GROUP);
          this.no_group = true;
          this.router.navigate([`/groups`]);
        } else {
          this.group = this.pruneObject(data['data']);
          this.setUpForm();
        }
      }),
    );
  }

  ngOnInit(): void {}

  pruneObject(object: any): any {
    Object.keys(object).forEach((key) => {
      if (object[key] == null) {
        object[key] = '';
      }
    });
    return object;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscriber) => subscriber.unsubscribe());
  }

  handleEdit = (formControl: UntypedFormControl, fieldToUpdate: keyof Group, shouldUpdate$?: Subject<boolean>) => {
    this.loaderService.setIgnoreNext('groups');
    this.group[fieldToUpdate.valueOf()] = formControl.value;
    this.updateGroup(shouldUpdate$, fieldToUpdate);
    this.dialog.closeAll();
  };

  updateGroup(shouldUpdate$?: Subject<boolean> | undefined, fieldToUpdate?: string | undefined): void {
    const updateGroupSub = this.groupService.updateGroup$(this.group.id, this.group).subscribe({
      next: (group: Group) => {
        if (shouldUpdate$) {
          shouldUpdate$.next(true);
        }
        this.group = group;
        this.messageService.setSuccess(this.GROUP_SUCCESS);
        return group;
      },
      error: (err) => {
        console.log(err);
        this.fieldControls[fieldToUpdate].setErrors({ incorrect: true });
        if (shouldUpdate$) {
          shouldUpdate$.next(false);
        }
      },
    });
    this.subscriptions.push(updateGroupSub);
  }

  private setUpForm(): void {
    Object.keys(this.group).forEach((param) => {
      if (typeof this.group[param] == 'boolean') {
        this.group[param] = JSON.parse(this.group[param]);
        this.fieldControls[param] = new UntypedFormControl(JSON.parse(this.group[param]), []);
      } else {
        this.fieldControls[param] = new UntypedFormControl(this.group[param], []);
      }
    });
  }
}
