import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberCommaReplace',
})
export class NumberCommaReplacePipe implements PipeTransform {
  transform(value: string): number {
    const replacedString = value.replaceAll(',', '');
    return Number.parseInt(replacedString);
  }
}
