import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { VenEditViewComponent } from './index';
import { MatTableModule } from "@angular/material/table";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { EditSubscriptionsModule } from './edit-subscriptions/module';

@NgModule({
  declarations: [VenEditViewComponent],
  exports: [VenEditViewComponent],
    imports: [
        CommonModule,
        TranslateModule,
        MatTableModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        MatIconModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        EditSubscriptionsModule,
    ],
})
export class VenEditViewModule {}
