<div class="section-header-container flex-col">
    <div class="section-greeting">
        <mat-icon>info_outline</mat-icon>
        <h3>{{ 'groups.greeting' | translate }}</h3>
    </div>
</div>
<div class="section-main-container">
    <div class="table-section">
        <div class="section-header-container">
            <h2>{{ 'groups.title' | translate }}</h2>
            <div class="button-group">
                <button class="icon-btn x-btn-primary" routerLink="/groups/create" color="accent">
                    <mat-icon>add</mat-icon>
                    {{ 'groups.create.button' | translate }}
                </button>
                <button class="icon-btn x-btn-primary" color="accent" (click)="openExportModal()" [disabled]="total === 0">
                    <mat-icon>keyboard_arrow_up</mat-icon>
                    {{  'groups.export.button' | translate }}
                </button>
            </div>
        </div>
        <div class="options">
            <div class="search-container">
                <mat-form-field class="search-bar" appearance="outline">
                    <input
                        matInput
                        class="search-input"
                        type="text"
                        placeholder="{{ 'events.search' | translate }}"
                        [(ngModel)]="_search"
                        (keyup.enter)="setSearch()"
                    />
                    <button matSuffix mat-icon-button aria-label="Search" (click)="setSearch()">
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>
                <mat-chip-list *ngIf="search" style="margin-top: 5px; margin-left: 15px">
                    <mat-chip [selectable]="false" (removed)="removeSearch()">
                        {{ search }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
            <mat-checkbox [(ngModel)]="archived" (change)="updateParams()">
                <h3>{{ 'actions.show_archived' | translate }}</h3>
            </mat-checkbox>
        </div>
        <div class="table-container-wrapper">
            <table class="table actions-table" [dataSource]="dataSource" mat-table>
                <!-- Group Label Column -->
                <ng-container matColumnDef="label">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'groups.table.label' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <a [routerLink]="['/groups', element.id ? element.id : null]">{{
                            element.group_id ? element.group_id : '-'
                        }}</a>
                    </td>
                </ng-container>

                <!-- Portfolio id Column -->
                <ng-container matColumnDef="portfolio">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'groups.table.portfolio' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span>
                            <span class="title-bold">{{ 'groups.table.cell.portfolio_id' | translate }}:</span>
                            {{ element.portfolio_id ? element.portfolio_id : '-' }}
                        </span>
                        <br />
                        <span>
                            <span class="title-bold">{{ 'groups.table.cell.portfolio_name' | translate }}:</span>
                            {{ element.portfolio_name ? element.portfolio_name : '-' }}
                        </span>
                    </td>
                </ng-container>

                <!-- Product Name Column -->
                <ng-container matColumnDef="product">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'groups.table.product' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span>
                            <span class="title-bold">{{ 'groups.table.cell.product_id' | translate }}:</span>
                            {{ element.product_id ? element.product_id : '-' }}
                        </span>
                        <br />
                        <span>
                            <span class="title-bold">{{ 'groups.table.cell.product_name' | translate }}:</span>
                            {{ element.product_name ? element.product_name : '-' }}
                        </span>
                    </td>
                </ng-container>

                <!-- Buttons Column -->
                <ng-container matColumnDef="actions" stickyEnd>
                    <th mat-header-cell class="actions-header" *matHeaderCellDef>
                        {{ 'groups.table.actions' | translate }}
                    </th>
                    <td mat-cell class="actions-cell" *matCellDef="let element" [ngSwitch]="element.archived">
                        <div class="btn-container">
                            <button
                                matTooltip="{{ 'actions.edit' | translate }}"
                                class="action-btn"
                                mat-icon-button
                                [routerLink]="['/groups', element.id]"
                            >
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button
                                matTooltip="{{ 'actions.disable' | translate }}"
                                class="action-btn"
                                mat-icon-button
                                *ngSwitchCase="false"
                                (click)="openDisableDialog(element)"
                            >
                                <mat-icon>do_not_disturb</mat-icon>
                            </button>
                            <button
                                matTooltip="{{ 'actions.restore' | translate }}"
                                class="action-btn"
                                mat-icon-button
                                *ngSwitchCase="true"
                                (click)="openRestoreDialog(element)"
                            >
                                <mat-icon>restore</mat-icon>
                            </button>
                            <button
                                matTooltip="{{ 'actions.delete' | translate }}"
                                class="action-btn"
                                mat-icon-button
                                (click)="openDeleteDialog(element)"
                            >
                                <mat-icon>delete_outline</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" [attr.colspan]="columnsToDisplay.length" style="text-align: center">
                        {{ 'groups.table.empty' | translate }}
                    </td>
                </tr>
            </table>
        </div>
        <mat-paginator
            [disabled]="loading"
            [length]="total"
            [pageIndex]="page - 1"
            [pageSize]="per_page"
            [pageSizeOptions]="[5, 10, 20, 50]"
            (page)="pageEvent($event)">
        </mat-paginator>
    </div>
</div>
<leviathan-jr-modal *ngIf="isOpenModal"></leviathan-jr-modal>
