export interface Locale {
  id: string;
  display_label: string;
  locale_name: string;
}

export class Locale {
  constructor() {
    this.id = '1';
    this.display_label = 'English (US)';
    this.locale_name = 'en_US';
  }
}
