import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DetailsPageHeaderModule } from '@components';
import { VenDetailsViewComponent } from './index';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from "@angular/material/table";
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InlineEditModule, ResourceTableModule, GroupTableModule } from '@components';
import { RouterModule } from '@angular/router';
import { VenRegistrationDisplayModule } from './ven-registration/module';

@NgModule({
  declarations: [VenDetailsViewComponent],
  exports: [VenDetailsViewComponent],
  imports: [
    CommonModule,
    InlineEditModule,
    TranslateModule,
    DetailsPageHeaderModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatTooltipModule,
    VenRegistrationDisplayModule,
    RouterModule,
    ResourceTableModule,
    GroupTableModule,
  ],
})
export class VenDetailsViewModule {}
