import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { Resource } from '@models';
import { ResourceService, GlobalAlertService, LoaderService } from '@services';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'resource-details-view',
  templateUrl: './index.html',
  styleUrls: ['./style.scss'],
})
export class ResourceDetailsViewComponent implements OnInit, OnDestroy {
  BAD_REQUEST = 'Oops, There was a problem with your request';
  RESOURCE_SUCCESS = 'Resource was updated successfully';
  fieldControls: any[] = [];
  noResource:boolean = false;
  NO_RESOURCE = 'Ooops, The Resource you requested was not found';
  TYPES = ['none', 'storage'];

  subscriptions: Subscription[] = [];
  resource: Resource;

  der_os_program_id = new UntypedFormControl('', [Validators.required]);
  der_os_site_ids = new UntypedFormControl('', [Validators.required]);

  id_validation = '^(\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12})$';

  @Input() resourceForm: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private resourceService: ResourceService,
    public dialog: MatDialog,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private messageService: GlobalAlertService,
    private router: Router,
  ) {
    this.BAD_REQUEST = this.translateService.instant('notification.bad_request');
    this.RESOURCE_SUCCESS = this.translateService.instant('resources.edit.notification.success');

    this.subscriptions.push(
      this.route.data.subscribe((resp) => {
        resp['data'].type = resp['data'].type || 'none';

        if (resp['data'].type === 'storage' && resp['data'].resource_id) {
            const [pid, sids] = resp['data'].resource_id.split('_');
            this.der_os_program_id.setValue(pid || '');
            this.der_os_site_ids.setValue(sids || '');
        }

        if (Object.keys(resp['data']).length === 0) {
          this.messageService.setError(this.NO_RESOURCE);
          this.noResource = true;
          this.router.navigate([`/markets`]);
        } else {
          this.resource = this.pruneObject(resp['data']);
          this.setUpForm();
        }
      }),
    );
  }

  ngOnInit(): void {}

  pruneObject(object: any): any {
    Object.keys(object).forEach((key) => {
      if (object[key] == null) {
        object[key] = '';
      }
    });
    return object;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscriber) => subscriber.unsubscribe());
  }

  handleEdit = (formControl: UntypedFormControl, fieldToUpdate: keyof Resource, shouldUpdate$?: Subject<boolean>) => {
    this.loaderService.setIgnoreNext('resources');

    const field = fieldToUpdate.valueOf();
    if (this.fieldControls['type'].value === 'storage' && field === 'resource_id') {
        const resource_id = this.derOsresource_id();
        this.fieldControls['resource_id'].setValue(resource_id);
        formControl = this.fieldControls['resource_id'];
    }

    this.resource[field] = formControl.value;
    this.updateResource(shouldUpdate$, fieldToUpdate);
    this.dialog.closeAll();
  };

  changeType() {
    this.resource.type = this.fieldControls['type'].value;
    if (this.fieldControls['type'].value === 'storage') {
        const [pid, sids] = this.fieldControls['resource_id'].value.split('_');
        this.der_os_program_id.setValue(pid || '');
        this.der_os_site_ids.setValue(sids || '');
    } else {
        const resource_id = this.derOsresource_id();
        this.fieldControls['resource_id'].setValue(resource_id || '');
    }
    this.updateResource(new Subject<boolean>(),'resource_type');
  }

  derOsresource_id() {
    const rid = `${this.der_os_program_id.value}_${this.der_os_site_ids.value}`;
    if (rid.endsWith('_')) {
       return rid.slice(0, -1);
    }
    return rid;
  }

  updateResource(shouldUpdate$?: Subject<boolean> | undefined, fieldToUpdate?: string | undefined): void {
    const updateResourceSub = this.resourceService.updateResource$(this.resource.id, this.resource).subscribe({
      next: (resource: Resource) => {
        if (shouldUpdate$) {
          shouldUpdate$.next(true);
        }
        this.resource = resource;
        this.messageService.setSuccess(this.RESOURCE_SUCCESS);
        return resource;
      },
      error: () => {
        this.messageService.setError(this.BAD_REQUEST);
        this.fieldControls[fieldToUpdate].setErrors({ incorrect: true });
        if (shouldUpdate$) {
          shouldUpdate$.next(false);
        }
      },
    });
    this.subscriptions.push(updateResourceSub);
  }

  private setUpForm(): void {
    Object.keys(this.resource).forEach((param) => {
      if (typeof this.resource[param] == 'boolean') {
        this.resource[param] = JSON.parse(this.resource[param]);
        this.fieldControls[param] = new UntypedFormControl(JSON.parse(this.resource[param]), []);
      } else if (typeof this.resource[param] != 'object') {
        this.fieldControls[param] = new UntypedFormControl(this.resource[param], [Validators.required]);
      }
    });
  }

}
