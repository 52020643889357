import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EventTableComponent } from './index';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
    declarations: [EventTableComponent],
    exports: [EventTableComponent],
    imports: [
        CommonModule,
        TranslateModule,
        MatTableModule,
        MatSortModule,
        RouterModule,
    ],
})
export class EventTableModule {}
