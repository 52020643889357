import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from './base.service';
import { Resource } from "@models";
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ResourceService extends BaseService<Resource> {
    override path = '/api/v1/resources';

    getAllResources$(params?: HttpParams): Observable<Resource[]> {
        return this.getList(params);
    }

    getResource$(id: number | string): Observable<Resource> {
        return this.getById(id);
    }

    createResource$(resource: Partial<Resource>): Observable<Resource> {
        return this.add(resource);
    }

    updateResource$(id : number | string, context : Resource | Partial<Resource>): Observable<Resource> {
        return this.update(id, context);
    }

    deleteResource$(id: number | string): Observable<Resource> {
        return this.delete(id);
    }

    private openModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private resourcesList: BehaviorSubject<Resource[]> = new BehaviorSubject<Resource[]>([]);

    public setOpenModal(value: boolean): void {
        this.openModal.next(value);
    }

    public getOpenModal(): BehaviorSubject<boolean> {
        return this.openModal;
    }

    public setResourcesList(value: Resource[]): void {
        this.resourcesList.next(value);
    }

    public getResourcesList(): BehaviorSubject<Resource[]> {
        return this.resourcesList;
    }
}

