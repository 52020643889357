import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GlobalFooterModule } from 'ngx-global-contact-footer';
import { GlobalNavModule } from 'ngx-global-nav';
import { AppComponent } from './app.component';
import { AlertModule, LoaderModule } from '@components';
import { CoreModule } from '@core';
import { LeviathanJrModule } from '@views';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AlertModule,
    GlobalFooterModule.forRoot({ namespace: 'ngx-global-contact-footer.' }),
    GlobalNavModule.forRoot({ namespace: 'ngx-global-nav.' }),
    MatSidenavModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    LoaderModule,
    AlertModule,
    LeviathanJrModule,
  ],
  exports: [BrowserModule, BrowserAnimationsModule, CoreModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
