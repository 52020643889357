import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { BaseTableViewComponent } from '../../common/base-table-view';
import { Resource } from '@models';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent, ExportModalComponent, ExportModalData } from '@components';
import { ResourceService, GlobalAlertService } from '@services';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'resource-list-view',
    templateUrl: './index.html',
    styleUrls: ['./style.scss'],
})
export class ResourceListViewComponent extends BaseTableViewComponent implements OnInit, OnDestroy {
    BAD_REQUEST = 'Oops, There was a problem with your request';
    RESOURCE_SUCCESS = 'Resource was deleted successfully';
    DISABLED_SUCCESS = 'Resource was disabled successfully';
    RESTORED_SUCCESS = 'Resource was restored successfully';
    RESOURCE_LIST_SUCCESS = 'Resources list was found successfully';
    subscriptions: Subscription[] = [];
    isOpenModal: boolean = false;

    app_base_url: string = environment.app_base;
    columnsToDisplay = ['resource', 'registration', 'registration_name', 'resource_type', 'actions'];

    linkConfig = {
        href: '/resources/create',
        target: '_self'
    }

    constructor(
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        private resourceService: ResourceService,
        private messageService: GlobalAlertService,
    ) {
        super();
        this.BAD_REQUEST = this.translateService.instant('notification.bad_request');
        this.RESOURCE_SUCCESS = this.translateService.instant('resources.delete.notification.success');
        this.DISABLED_SUCCESS = this.translateService.instant('resources.disabled.notification.success');
        this.RESTORED_SUCCESS = this.translateService.instant('resources.restored.notification.success');
    }

    ngOnInit(): void {
        this.initialize(this.route);
    }

    override dataCall(qp) {
        this.router.navigate(['resources'], {replaceUrl: true, queryParams: qp});
        return this.resourceService.getPage(qp);
    }

    openModal() {
        const data: ExportModalData = {
            service: this.resourceService,
            name: 'Resources',
            headers: [
                'ID',
                'Resource ID',
                'Flex Registration Name',
                'Flex Registration ID',
                'Resource Type',
                'Archived status',
                'Associated VENs',
            ],
            mapper: (resource: Resource) => [
                resource.id,
                resource.resource_id,
                resource.registration_id,
                resource.registration_name,
                this.translateService.instant(`resources.create.form.resource_types.${resource.type || 'none'}`),
                resource.archived,
                `"${resource.vens.map((vens) => vens.id).join(',')}"`,
            ],
            params: {
                archived: this.archived,
            }
        };
        if (this.search) data.params['q'] = this.search;

        this.dialog.open(ExportModalComponent, {width: '350px', data});

    }

    getList(service: Observable<Resource[]>) {
        try {
            const listOfResources =  service.subscribe({
                next: (resourcesList: Resource[]) => {
                    this.messageService.setSuccess(this.RESOURCE_LIST_SUCCESS);
                    this.resourceService.setOpenModal(true);
                    this.isOpenModal = true;
                    this.resourceService.setResourcesList(resourcesList)
                    this.loadData();
                },
                error: () => {
                    this.messageService.setError(this.BAD_REQUEST);
                },
            });
            this.subscriptions.push(listOfResources);
        } finally {
            this.closeOutDialogs();
        }
    }

    updateArchived(resource: Resource, disabled: boolean) {
        try {
            const updateResourceSub = this.resourceService.updateResource$(resource.id, resource).subscribe({
                next: (updatedResource) => {
                    disabled
                        ? this.messageService.setSuccess(this.DISABLED_SUCCESS)
                        : this.messageService.setSuccess(this.RESTORED_SUCCESS);
                    this.loadData();
                },
                error: () => {
                    this.messageService.setError(this.BAD_REQUEST);
                },
            });
            this.subscriptions.push(updateResourceSub);
        } finally {
            this.closeOutDialogs();
        }
    }

    deleteResource(resource: Resource) {
        try {
            const deleteResourceSub = this.resourceService.deleteResource$(resource.id).subscribe({
                next: () => {
                    this.messageService.setSuccess(this.RESOURCE_SUCCESS);
                    this.loadData();
                },
                error: () => {
                    this.messageService.setError(this.BAD_REQUEST);
                },
            });
            this.subscriptions.push(deleteResourceSub);
        } finally {
            this.closeOutDialogs();
        }
    }

    openDeleteDialog(resource: Resource) {
        this.dialog.open(DialogComponent, {
            width: '531px',
            data: {
                title: this.translateService.instant('resources.dialog.delete'),
                secondButtonText: this.translateService.instant('actions.accept'),
                firstButtonText: this.translateService.instant('actions.cancel'),
                secondButtonCallback: () => this.deleteResource(resource),
                firstButtonCallback: () => this.closeOutDialogs(),
            },
        });
    }

    openRestoreDialog(resource: Resource) {
        this.dialog.open(DialogComponent, {
            width: '531px',
            data: {
                title: this.translateService.instant('resources.dialog.restore'),
                secondButtonText: this.translateService.instant('actions.accept'),
                firstButtonText: this.translateService.instant('actions.cancel'),
                secondButtonCallback: () => {
                    resource.archived = false;
                    this.updateArchived(resource, false);
                    this.closeOutDialogs();
                },
                firstButtonCallback: () => this.closeOutDialogs(),
            },
        });
    }

    openDisableDialog(resource: Resource) {
        this.dialog.open(DialogComponent, {
            width: '531px',
            data: {
                title: this.translateService.instant('resources.dialog.disable'),
                secondButtonText: this.translateService.instant('actions.accept'),
                firstButtonText: this.translateService.instant('actions.cancel'),
                secondButtonCallback: () => {
                    resource.archived = true;
                    this.updateArchived(resource, true);
                    this.closeOutDialogs();
                },
                firstButtonCallback: () => this.closeOutDialogs(),
            },
        });
    }

    closeOutDialogs() {
        this.dialog.closeAll();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscriber) => subscriber.unsubscribe());
    }

    navigateTo(){
        this.router.navigate(['/resources/create']);
    }

}
