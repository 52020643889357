import { Component, OnDestroy, OnInit,  } from '@angular/core';
import { Group, MarketContext, Resource, VEN } from '@models';
import { TranslateService } from '@ngx-translate/core';
import { GroupService, MarketContextService, ResourceService, VenService } from '@services';
import { Subscription } from 'rxjs';
import { groups, marketContexts, resources, VENS } from './csvData/headers';
import { groupBody, marketContextBody, resourcesBody, vensBody } from './csvData/body';

@Component({
  selector: 'leviathan-jr-modal',
  templateUrl: './index.html',
  styleUrls: ['./styles.scss'],
})
export class ModalComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  RESOURCES_TYPE = '';

  public openModal: boolean;
  public resourcesList: Resource[];
  public groupList: Group[];
  public marketList: MarketContext[];
  public type: string = '';
  public venList: VEN[];

  constructor(
    private resourcesServices: ResourceService,
    private groupServices: GroupService,
    private marketContextServices: MarketContextService,
    private venService: VenService,
    private translateService: TranslateService,
  ) {
    this.RESOURCES_TYPE = 'resources.create.form.resource_types';
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.getResourcesList(),
      this.getGroupList(),
      this.getMarketContextList(),
      this.getVenList(),
    );
  }
  getResourcesList(){
    return this.resourcesServices.getResourcesList().subscribe((value: Resource[]) => {
      this.resourcesList = value;
      if (this.resourcesList.length > 0) {
        this.type = 'Resources';
        this.resourcesServices.getOpenModal().subscribe((value: boolean) => {
          this.openModal = value;
        });
      }
    })
  }

  getGroupList(){
    return this.groupServices.getGroupList().subscribe((value: Group[]) => {
      this.groupList = value;
      if (this.groupList.length > 0) {
        this.type = 'Group';
        this.groupServices.getOpenModal().subscribe((value: boolean) => {
          this.openModal = value;
        });
      }
    })
  }
  getMarketContextList(){
    return this.marketContextServices.getMarketList().subscribe((value: MarketContext[]) => {
      this.marketList = value;
      if (this.marketList.length > 0) {
        this.type = 'Market Context';
        this.marketContextServices.getOpenModal().subscribe((value: boolean) => {
          this.openModal = value;
        });
      }
    })
  }
  getVenList(){
    return this.venService.getVenList().subscribe((value: VEN[]) => {
      this.venList = value;
      if (this.venList.length > 0) {
        this.type = 'VEN';
        this.venService.getOpenModal().subscribe((value: boolean) => {
          this.openModal = value;
        });
      }
    })
  }
  getType = {
    Resources: () => {
      return this.resourcesList;
    },
    Group: () => {
      return this.groupList;
    },
    'Market Context': () => {
      return this.marketList;
    },
    VEN: () => {
      return this.venList;
    }
  };

  getCSVHeader = {
    Resources: () => {
      return resources;
    },
    Group: () => {
      return groups;
    },
    'Market Context': () => {
      return marketContexts;
    },
    VEN : () => {
      return VENS;
    }
   };

  getCSVBody = {
    Resources: (data) => {
      return resourcesBody(data, this.translateService, this.RESOURCES_TYPE);
    },
    Group: (data) => {
      return groupBody(data);
    },
    'Market Context': (data) => {
      return marketContextBody(data);
    },
    VEN: (data) => {
      return vensBody(data);
    }
  };


  handleOpenClose = () => {
    this.closeModal[this.type]();
    this.resetValues();
  };
  applyModal = () => {
    this._buildCsv();
  };
  _buildCsv(): any {
    const element = this.getType[this.type]();
    // set when API is ready
    const csvHeader = this.getCSVHeader[this.type]()();
    const csvBody = element?.flatMap((data, index: number) => {
      return this.getCSVBody[this.type](data).flatMap(value => `"${value}"`).join(',');
    });
    this._downloadCsv(csvHeader.join(',') + '\n' + csvBody.join('\n'), `${this.type}_csv_`);
  }
  _downloadCsv(csvData: string, csvName: string) {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', csvName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.closeModal[this.type]();
  }
  closeModal = {
    Resources: () => {
      return this.resourcesServices.setOpenModal(false);
    },
    Group: () => {
      return this.groupServices.setOpenModal(false);
    },
    'Market Context': () => {
      return this.marketContextServices.setOpenModal(false);
    },
    VEN: () => {
      return this.venService.setOpenModal(false);
    }
  }
  resetValues(){
    this.resourcesList = [];
    this.groupList = [];
    this.venList = [];
    this.marketList = [];
    this.resourcesServices.setResourcesList([])
    this.groupServices.setGroupList([])
    this.venService.setVenList([])
    this.marketContextServices.setMarketList([])
    this.openModal = false;
    this.type = '';

  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscriber) => subscriber.unsubscribe());
    this.resetValues();
  }
}
