import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LeviathanJr } from "@views";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";

import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from "@angular/router";

import { HomeViewModule } from '../home/module';
import { EventListViewModule } from "../event-list/module";
import { EventDetailsViewModule } from '../event-details/module';
import { MarketContextListViewModule } from "../market-context-list/module";
import { MarketContextEditViewModule } from "../market-context-edit/module";
import { MarketContextDetailsViewModule } from "../market-context-details/module";
import { GroupDetailsViewModule } from '../group-details/module';
import { GroupEditViewModule } from "../group-edit/module";
import { GroupListViewModule } from "../group-list/module";
import { ReportEditViewModule} from "../report-edit/module";
import { ResourceListViewModule} from "../resource-list/module";
import { ResourceDetailsViewModule } from '../resource-details/module';
import { ResourceEditViewModule } from '../resource-edit/module';
import { UnverifiedVenListViewModule } from "../unverified-ven-list/module";
import { VenDetailsViewModule } from "../ven-details/module";
import { VenEditViewModule } from "../ven-edit/module";
import { VenListViewModule } from "../ven-list/module";
import { VenRegistrationListViewModule } from '../ven-registration-list/module';

@NgModule({
  declarations: [LeviathanJr],
  exports: [LeviathanJr],
    imports: [
        CommonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        MatTooltipModule,
        MatInputModule,
        MatMenuModule,

        TranslateModule,
        RouterModule,

        // Views
        HomeViewModule,
        EventListViewModule,
        EventDetailsViewModule,
        MarketContextListViewModule,
        MarketContextEditViewModule,
        MarketContextDetailsViewModule,
        GroupDetailsViewModule,
        GroupEditViewModule,
        GroupListViewModule,
        ResourceListViewModule,
        ResourceDetailsViewModule,
        ResourceEditViewModule,
        ReportEditViewModule,
        VenDetailsViewModule,
        UnverifiedVenListViewModule,
        VenEditViewModule,
        VenListViewModule,
        VenRegistrationListViewModule,
    ],
})
export class LeviathanJrModule {}
