<ng-container *ngIf="loading">
    <div [ngClass]="'editing'">
        <mat-expansion-panel
            (opened)="expandPanel()"
            (closed)="panelOpenedState = false"
            togglePosition="before"
            class="expansion-panel panel_{{ index }}"
        >
            <mat-expansion-panel-header class="panel-override" collapsedHeight="74px" expandedHeight="48px">
                <mat-panel-title class="point-title">
                    <div data-automation="registration_id">
                        <span>
                            {{ registration.id }}
                        </span>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="flex flex-wrap">
                <!-- If new just regular create form -->
                <ng-container *ngIf="newSignal"> </ng-container>

                <!-- Already existing INLINE EDIT -->
                <ng-container>
                    <!-- Registration Id -->
                    <div class="form-field-wrapper">
                        <div class="flex items-start">
                            <span class="text-light-color">{{ 'vens.details.registration.id' | translate }}:</span>
                            <mat-icon
                                matTooltip="{{ 'vens.details.registration.registrationId.description' | translate }}"
                                matTooltipPosition="right"
                                [color]="'primary'"
                                class="tooltip-icon"
                                matTooltipClass="'tooltip'"
                            >
                                info_outline
                            </mat-icon>
                        </div>
                        <div>
                            {{ registration.registration_id }}
                        </div>
                    </div>

                    <!-- Poll Rate -->
                    <div class="form-field-wrapper">
                        <div class="flex items-start">
                            <span class="text-light-color"
                                >{{ 'vens.details.registration.poll_rate' | translate }}:</span
                            >
                            <mat-icon
                                matTooltip="{{ 'vens.details.registration.poll_rate.description' | translate }}"
                                matTooltipPosition="right"
                                [color]="'primary'"
                                class="tooltip-icon"
                                matTooltipClass="'tooltip'"
                            >
                                info_outline
                            </mat-icon>
                        </div>
                        <div>
                            {{ registration.poll_rate }}
                        </div>
                    </div>
                </ng-container>
            </div>
        </mat-expansion-panel>
    </div>
</ng-container>
