import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimFileExtension',
})
export class TrimFileExtensionPipe implements PipeTransform {
  transform(filename: string): string {
    return filename.split('.').slice(0, -1).join('.');
  }
}
