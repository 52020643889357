import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { VenRegistrationListViewComponent } from './index';
import { MatTableModule } from "@angular/material/table";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [VenRegistrationListViewComponent],
  exports: [VenRegistrationListViewComponent],
    imports: [
        CommonModule,
        TranslateModule,
        MatTableModule,
        MatIconModule,
        MatFormFieldModule,
        MatCheckboxModule,
        RouterModule,
        FormsModule,
        MatButtonModule,
        MatInputModule,
        MatPaginatorModule,
        MatChipsModule,
        MatTooltipModule
    ],
})
export class VenRegistrationListViewModule {}
