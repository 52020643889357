import { NgModule } from '@angular/core';
import { LocalePipe } from './locale.pipe';
import { CapitalizeFirstLetterEachPipe } from './capitalize-first-letter-each.pipe';
import { KeepOnlyThreeMonthCharacterPipe } from './keep-only-three-month-character.pipe';
import { NumberCommaReplacePipe } from './number-comma-replace.pipe';
import { PrintUnitPipe } from './print-unit.pipe';
import { RemoveDoubleDecimalZeroPipe } from './remove-double-decimal-zero.pipe';
import { RemoveUnitPipe } from './remove-unit.pipe';
import { StringTruncatePipe } from './string-truncate.pipe';
import { TrimFileExtensionPipe } from './trim-file-extension.pipe';

@NgModule({
  declarations: [
    LocalePipe,
    TrimFileExtensionPipe,
    StringTruncatePipe,
    CapitalizeFirstLetterEachPipe,
    NumberCommaReplacePipe,
    PrintUnitPipe,
    RemoveUnitPipe,
    RemoveDoubleDecimalZeroPipe,
    KeepOnlyThreeMonthCharacterPipe,
  ],
  exports: [
    LocalePipe,
    TrimFileExtensionPipe,
    StringTruncatePipe,
    CapitalizeFirstLetterEachPipe,
    NumberCommaReplacePipe,
    PrintUnitPipe,
    RemoveUnitPipe,
    RemoveDoubleDecimalZeroPipe,
    KeepOnlyThreeMonthCharacterPipe,
  ],
})
export class PipeModule {}
