import { Component, Input } from '@angular/core';
import { Group } from '@models';

@Component({
    selector: 'group-table',
    templateUrl: './index.html',
})
export class GroupTableComponent {
    @Input() groups: Group[];

    groupCols = ['label', 'portfolio', 'product'];
}
