export const resources = () => [
  'Resource ID',
  'Flex Registration Name',
  'Flex Registration ID',
  'Resource Type',
  'Archived status',
  'Associated VENs',
];

export const groups = () => [
    'Portfolio ID',
    'Portfolio Name',
    'Product ID', 
    'Product Name',
    'Group Label',
    'Archived status',
    'Associated VENs',
  ];
export const marketContexts = () => [
    'Market Context URI',
    'Operator ID',
    'Operator Name',
    'Program Flex ID', 
    'Program Name',
    'Archived status',
    'Associated VENs',
  ];
  export const VENS = () => [
    'VEN Name',
    'VEN ID',
    'RSA Fingerprint',
    'Registered', 
    'Signal Style',
    'Resources',
    'Groups', 
    'Market Context',
  ];