import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'leviathan-jr',
  templateUrl: './leviathan-jr.html',
  styleUrls: ['./leviathan-jr.scss'],
})
export class LeviathanJr implements OnInit, OnDestroy {
  apiKey: string;
  labelTest: string;
  subscriptions: Subscription[] = [];
  constructor(
      private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
   this.selectedMenu('oadr');
  }

  selectedMenu(e){
    const translationSub = this.translateService.get('tabs.oadr').subscribe(() => {
      if (e == 'vens') {
       return this.labelTest = this.translateService.instant('tabs.oadr.vens');
      } else if (e == 'unverifiedVens')  {
        return this.labelTest = this.translateService.instant('tabs.oadr.unverified_vens');
      } else if (e == 'registrations')  {
       return  this.labelTest = this.translateService.instant('tabs.oadr.registrations');
      } else  {
        return this.labelTest = this.translateService.instant('tabs.oadr');
      }
    });
    this.subscriptions.push(translationSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscriber) => subscriber.unsubscribe());
  }
}
