import { Component, Input } from '@angular/core';
import { VEN } from '@models';

@Component({
  selector: 'leviathan-jr-vens-table',
  templateUrl: './vens-table.component.html',
  styleUrls: ['./vens-table.component.scss'],
})
export class VensTableComponent {

  @Input() tableData: Array<VEN>;

  tableColumns: Array<string> = ['ven_id', 'ven_name'];

  constructor() {

  }

}
