import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResourceService, GlobalAlertService } from '@services';

@Injectable()
export class ResourceResolver implements Resolve<any> {
    constructor(
        private resourceService: ResourceService,
        private globalAlertService: GlobalAlertService,
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const id = route.paramMap.get('id') || route.queryParamMap.get('id');
        if (id) {
            return this.resourceService.getResource$(id).pipe(
                catchError((err: unknown) => {
                    this.globalAlertService.setError(err.toString());
                    return throwError(() => new Error(err.toString()));
                }),
            );
        }
        return this.resourceService.getAllResources$().pipe(
            catchError((err: unknown) => {
                this.globalAlertService.setError(err.toString());
                return throwError(() => new Error(err.toString()));
            }),
        );
    }
}
