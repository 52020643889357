import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '@services';

export function displayLabels(locale, r) {
    const first = Object.keys(r)[0];
    return r[locale] || r.en_US || r[first] || '';
}

@Pipe({name: 'locale'})
export class LocalePipe implements PipeTransform {
    constructor(
        private i18nService: I18nService,
    ) {}

    transform(obj: any): string {
        if (!obj) return '';

        let r: any = obj;
        if (typeof r === 'string') {
            if (r.startsWith('{')) {
                r = JSON.parse(r);
            } else {
                return r;
            }
        } else if (typeof r.toISOString === 'function') {
            return r.toLocaleString('en-US', {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                timeZoneName: 'short'
            });
        } else if (r.display_labels) {
            r = r.display_labels;
        }

        return displayLabels(this.i18nService.locale, r);
    }
}
