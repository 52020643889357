<div class="section-header-container flex-col">
    <div class="section-greeting">
        <mat-icon>info_outline</mat-icon>
        <h3>{{ 'events.greeting' | translate }}</h3>
    </div>
</div>
<div class="section-main-container">
    <div class="table-section">
        <div class="section-header-container">
            <h2>{{ 'events.title' | translate }}</h2>
        </div>
        <div class="options">
            <div class="search-container">
                <mat-form-field class="search-bar" appearance="outline">
                    <input
                        matInput
                        class="search-input"
                        type="text"
                        placeholder="{{ 'events.search' | translate }}"
                        [(ngModel)]="_search"
                        (keyup.enter)="setSearch()"
                    />
                    <button matSuffix mat-icon-button aria-label="Search" (click)="setSearch()">
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>
                <mat-chip-list *ngIf="search" style="margin-top: 5px; margin-left: 15px">
                    <mat-chip [selectable]="false" (removed)="removeSearch()">
                        {{ search }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
            <mat-checkbox [(ngModel)]="archived" (change)="updateParams()">
                <h3>{{ 'actions.show_archived' | translate }}</h3>
            </mat-checkbox>
        </div>
        <event-table
            [dataSource]="dataSource"
            [order]="order"
            [direction]="direction"
            (updateSort)="updateSort($event)"
        ></event-table>
        <mat-paginator
            [disabled]="loading"
            [length]="total"
            [pageIndex]="page - 1"
            [pageSize]="per_page"
            [pageSizeOptions]="[5, 10, 20, 50]"
            (page)="pageEvent($event)">
        </mat-paginator>
    </div>
</div>
