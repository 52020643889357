import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeDoubleDecimalZero',
})
export class RemoveDoubleDecimalZeroPipe implements PipeTransform {
  transform(value: string): string {
    value = value.replace('.00', '');
    return value;
  }
}
