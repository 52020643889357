<div class="table-container-wrapper w-full">
    <table class="table"
           [dataSource]="dataSource"
           mat-table
           matSort
           [matSortActive]="order"
           [matSortDirection]="direction"
           (matSortChange)="changeSort($event)"
    >
        <!-- Status Column -->
        <ng-container matColumnDef="status">
            <th class="status th" mat-header-cell *matHeaderCellDef>
                {{ 'events.table.status' | translate }}
            </th>
            <td class="status" mat-cell *matCellDef="let element" [ngSwitch]="element.status">
                <span
                    *ngSwitchCase="'completed'"
                    class="complete"
                    matTooltip=" {{ 'events.details.status.completed' | translate }}"
                ></span>
                <span
                    *ngSwitchCase="'cancelled'"
                    class="cancelled"
                    matTooltip="{{ 'events.details.status.cancelled' | translate }}"
                ></span>
                <span
                    *ngSwitchDefault
                    class="active"
                    matTooltip="{{ 'events.details.status.active' | translate }}"
                ></span>
            </td>
        </ng-container>

        <!-- Flex Event ID Column -->
        <ng-container matColumnDef="flex_id">
            <th class="flex-event-id" mat-header-cell *matHeaderCellDef mat-sort-header="event_id" [disabled]="!enableSort">
                {{ 'events.table.flex_id' | translate }}
            </th>
            <td class="flex-event-id" mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.event_id; else dashEmpty">
                    <p class="mb-1">
                        <a class="test" [routerLink]="['/events', element.id]">
                            {{element.event_id}}
                        </a>
                    </p>
                    <p>
                        <a class="test" href="//event-management.{{app_base_url}}/event/{{element.event_id}}"><u>{{ 'events.table.flex_id_link' | translate }}</u></a>
                    </p>
                </ng-container>
                <ng-template #dashEmpty> - </ng-template>
            </td>
        </ng-container>

        <!-- Start Column -->
        <ng-container matColumnDef="start">
            <th class="start-date" mat-header-cell *matHeaderCellDef mat-sort-header="start_dttm" [disabled]="!enableSort">
                {{ 'events.table.start' | translate }}
            </th>
            <td class="start-date" mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.start_dttm && element.start_dttm !== null; else dashEmpty">
                    <span>{{ parseDate(element.start_dttm).toISOString() }}</span>
                </ng-container>
                <ng-template #dashEmpty> - </ng-template>
            </td>
        </ng-container>

        <!-- End Column -->
        <ng-container matColumnDef="end">
            <th class="end-date" mat-header-cell *matHeaderCellDef mat-sort-header="end_dttm" [disabled]="!enableSort">
                {{ 'events.table.end' | translate }}
            </th>
            <td class="end-date" mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.end_dttm && element.end_dttm !== null; else dashEmpty">
                    <span>{{ parseDate(element.end_dttm).toISOString() }}</span>
                </ng-container>
                <ng-template #dashEmpty> - </ng-template>
            </td>
        </ng-container>

        <!-- Market Contexts Column -->
        <ng-container matColumnDef="market_context">
            <th class="market-context" mat-header-cell *matHeaderCellDef>
                {{ 'events.table.market_context' | translate }}
            </th>
            <td class="market-context" mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.market; else dashEmpty">
                    <span>
                        <span class="title-bold">{{ 'events.table.cell.url' | translate }}: </span>
                        <a routerLink="{{ '/market-contexts/' + element.market.id }}">
                            {{ element.market.context_url ? element.market.context_url : '-' }}
                        </a>
                    </span>
                    <br />
                    <span>
                        <span class="title-bold">{{ 'events.table.cell.operator_id' | translate }}:</span>
                        {{ element.market.operator_id ? element.market.operator_id : '-' }}
                    </span>
                    <br />
                    <span>
                        <span class="title-bold">{{ 'events.table.cell.operator_name' | translate }}: </span>
                        <span>{{ element.market.operator_name ? element.market.operator_name : '-' }}</span>
                    </span>
                    <br />
                    <span>
                        <span class="title-bold">{{ 'events.table.cell.program_id' | translate }}: </span>
                        <span>{{ element.market.program_id ? element.market.program_id : '-' }}</span>
                    </span>
                    <br />
                    <span>
                        <span class="title-bold">{{ 'events.table.cell.program_name' | translate }}: </span>
                        <span> {{ element.market.program_name ? element.market.program_name : '-' }}</span>
                    </span>
                </ng-container>
                <ng-template #dashEmpty> - </ng-template>
            </td>
        </ng-container>

        <!-- Confirmations Column -->
        <ng-container matColumnDef="confirmations">
            <th class="resources" mat-header-cell *matHeaderCellDef>
                {{ 'events.table.confirmations' | translate }}
            </th>
            <td class="resources" mat-cell *matCellDef="let element">
                <ng-container>
                    <div *ngFor="let plan of element.participation_plans" class="ven-confirmation-row">
                        <div>
                            <span class="title-bold">{{ 'events.table.cell.ven_id' | translate }}:</span>
                            <a routerLink="{{ '/vens/' + plan.ven.id }}">
                                {{ plan.ven.name }} ({{ plan.ven.ven_id}})
                            </a>
                        </div>
                        <div *ngIf="plan.resources.length > 0">
                            <span class="title-bold">{{ 'events.table.cell.resource_count' | translate }}: </span>
                            {{ plan.resources.length }}
                        </div>
                        <div *ngIf="plan.groups.length > 0">
                            <span class="title-bold">{{ 'events.table.cell.group_count' | translate }}: </span>
                            {{ plan.groups.length }}
                        </div>
                        <div *ngIf="plan.last_conf">
                            {{ 'events.table.cell.ven_confirmed' | translate }}
                        </div>
                        <div *ngIf="!plan.last_conf">
                            {{ 'events.table.cell.ven_unconfirmed' | translate }}
                        </div>
                    </div>
                </ng-container>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="columnsToDisplay.length" style="text-align: center">
                {{ 'events.table.empty' | translate }}
            </td>
        </tr>
    </table>
</div>

