import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'fitted-loader',
  templateUrl: './fitted-loader.component.html',
  styleUrls: ['./fitted-loader.component.scss'],
})
export class FittedLoaderComponent implements OnInit, OnDestroy {
  constructor() {}

  ngOnDestroy(): void {}

  ngOnInit(): void {}
}
