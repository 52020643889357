import { Component, Input } from '@angular/core';
import { ParticipationPlan } from '@models';

@Component({
  selector: 'participation-plan-display',
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})
export class ParticipationPlanDisplayComponent {
    @Input() plan: ParticipationPlan;
    @Input() index: number;

    panelOpenedState = true;
    confirmationCols = ['confirmation_dttm', 'modification_number', 'opt_type'];

    onChange() {
        this.plan.last_conf = this.plan.event_confirmations
            .find(ec => ec.modification_number === this.plan.modification_number);
    }
}
