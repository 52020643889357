import {Injectable} from '@angular/core';
import { Observable} from 'rxjs';
import { BaseService } from './base.service';
import { Group } from "@models";

@Injectable()
export class GroupService extends BaseService<Group> {
    override path = '/api/v1/groups';

    getAllGroups$(): Observable<Group[]> {
        return this.getList();
    }

    getGroup$(id: number | string): Observable<Group> {
        return this.getById(id);
    }

    createGroup$(context: Partial<Group>): Observable<Group> {
        return this.add(context);
    }

    updateGroup$(id : number | string, context : Group | Partial<Group>): Observable<Group> {
        return this.update(id, context);
    }
    deleteGroup$(id: number | string): Observable<Group> {
        return this.delete(id);
    }
}

