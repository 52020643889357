import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { Event } from '@models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'event-table',
  templateUrl: './index.html',
  styleUrls: ['./style.scss'],
})
export class EventTableComponent implements OnChanges {
  @Input() dataSource: MatTableDataSource<Event>;
  @Input() order: string;
  @Input() direction: string;
  @Input() enableSort: boolean = false;
  @Output() updateSort: EventEmitter<Sort> = new EventEmitter<Sort>();

  public status = [];

  columnsToDisplay = ['status', 'flex_id', 'start', 'end', 'market_context', 'confirmations'];

  app_base_url: string = environment.app_base;

  ngOnChanges() {
    if (this.dataSource?.data) {
      this.processPlans();
    }
  }

  processPlans() {
    this.dataSource.data.forEach((event, ind: number) => {
      event.participation_plans.forEach((plan, index: number) => {
        plan.last_conf = plan.event_confirmations.find((ec) => ec.modification_number === plan.modification_number);
        if (!this.status.find((value) => value.status === plan.status && value.index === ind)) {
          this.getStatus(plan.status, ind);
        }
      });
    });
  }

  getStatus(status: string, index: number) {
    switch (status) {
      case 'completed':
        this.status.push({ status: status, index: index });
        break;
      case 'cancelled':
        this.status.push({ status: status, index: index });
        break;
      case 'active':
        this.status.push({ status: status, index: index });
        break;
      default:
        return null;
    }
  }
  parseDate(dateString: string): Date {
    return new Date(dateString);
  }

  changeSort(sort: Sort) {
    this.updateSort.emit(sort);
  }
}
