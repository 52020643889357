import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { ControlContainer, UntypedFormControl, NgForm, Validators } from '@angular/forms';
import {  Subject, Subscription } from 'rxjs';
import { VenRegistration, VEN } from '@models';


@Component({
  selector: 'ven-registration-display',
  templateUrl: './index.html',
  styleUrls: ['./style.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class VenRegistrationDisplayComponent implements OnInit, OnDestroy {
  panelOpenedState: boolean;
  registrationProcessed$ = new Subject<boolean>();

  subscriptions: Subscription[] = [];

  fieldControls: any[] = [];
  loadingRegistration: VenRegistration[] = [];

  @Output() updateRegistration: EventEmitter<any> = new EventEmitter();

  private _reg: VenRegistration;
  private _index: number;

  newReg: boolean = false;

  public loading = false;

  @Input()
  set registration(registration: VenRegistration) {
    this._reg = registration;
    this.setUpForm();
  }

  get registration() {
    return this._reg;
  }

  @Input()
  set index(index: number) {
    this._index = index;
  }

  get index() {
    return this._index;
  }

  @Input() editCallback: (registration: VenRegistration, index: number, registrationProcessed$, remove?: boolean) => void;

  constructor() {
    this.panelOpenedState = false;
  }

  handleEdit = (formControl: UntypedFormControl, fieldToUpdate: keyof VEN, shouldUpdate$?: Subject<boolean>) => {
    this._reg[fieldToUpdate.valueOf()] = formControl.value;
    this.loading = true;
    this.editCallback(this.registration, this.index, this.registrationProcessed$);
    const registrationProcessedSub = this.registrationProcessed$.subscribe(() => {
      shouldUpdate$.next(true);
      this.loading = false;
    });
    this.subscriptions.push(registrationProcessedSub);
  };

  handleDelete = () => {
    this.editCallback(this.registration, this.index, this.registrationProcessed$, true);
  };

  async ngOnInit() {
    this.loading = true;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  expandPanel() {
    this.panelOpenedState = true;
  }

  private setUpForm(): void {
    Object.keys(this.registration).forEach((param) => {
      if (typeof this.registration[param] == 'boolean') {
        this.registration[param] = JSON.parse(this.registration[param]);
        this.fieldControls[param] = new UntypedFormControl(JSON.parse(this.registration[param]), []);
      } else {
        this.fieldControls[param] = new UntypedFormControl(this.registration[param], [Validators.required]);
      }
    });
  }
}
