import { Component, Input } from '@angular/core';
import { Resource } from '@models';

@Component({
    selector: 'resource-table',
    templateUrl: './index.html',
})
export class ResourceTableComponent {
    @Input() resources: Resource[];

    resourceCols = ['registration', 'registration_name', 'resource', 'resource_type'];
}
