import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BaseTableViewComponent } from '../../common/base-table-view';
import { Group } from '@models';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '@components';
import { GlobalAlertService, GroupService } from '@services';

@Component({
  selector: 'group-list-view',
  templateUrl: './index.html',
  styleUrls: ['./style.scss'],
})
export class GroupListViewComponent extends BaseTableViewComponent implements OnInit, OnDestroy {
  BAD_REQUEST = 'Oops, There was a problem with your request';
  GROUP_SUCCESS = 'Group was deleted successfully';
  DISABLED_SUCCESS = 'Group was disabled successfully';
  RESTORED_SUCCESS = 'Group was restored successfully';
  subscriptions: Subscription[] = [];
  columnsToDisplay = ['label', 'portfolio', 'product', 'actions'];

  constructor(
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private groupService: GroupService,
    private messageService: GlobalAlertService,
  ) {
    super();
    this.BAD_REQUEST = this.translateService.instant('notification.bad_request');
    this.GROUP_SUCCESS = this.translateService.instant('groups.delete.notification.success');
    this.DISABLED_SUCCESS = this.translateService.instant('groups.disabled.notification.success');
    this.RESTORED_SUCCESS = this.translateService.instant('groups.restored.notification.success');
  }

  ngOnInit(): void {
      this.initialize(this.route);
  }

  override dataCall(qp) {
    this.router.navigate(['groups'], {replaceUrl: true, queryParams: qp});
    return this.groupService.getPage(qp);
  }

  deleteGroup(group: Group) {
    try {
      const deleteGroupSub = this.groupService.deleteGroup$(group.id).subscribe({
        next: () => {
          this.messageService.setSuccess(this.GROUP_SUCCESS);
          this.loadData();
        },
        error: () => {
          this.messageService.setError(this.BAD_REQUEST);
        },
      });
      this.subscriptions.push(deleteGroupSub);
    } finally {
      this.closeOutDialogs();
    }
  }

  updateArchived(group: Group, disabled: boolean) {
    try {
      const updateGroupSub = this.groupService.updateGroup$(group.id, group).subscribe({
        next: (updatedGroup) => {
          disabled
            ? this.messageService.setSuccess(this.DISABLED_SUCCESS)
            : this.messageService.setSuccess(this.RESTORED_SUCCESS);
          this.loadData();
        },
        error: () => {
          this.messageService.setError(this.BAD_REQUEST);
        },
      });
      this.subscriptions.push(updateGroupSub);
    } finally {
      this.closeOutDialogs();
    }
  }

  openDeleteDialog(group: Group) {
    this.dialog.open(DialogComponent, {
      width: '531px',
      data: {
        title: this.translateService.instant('groups.dialog.delete'),
        secondButtonText: this.translateService.instant('actions.accept'),
        firstButtonText: this.translateService.instant('actions.cancel'),
        secondButtonCallback: () => this.deleteGroup(group),
        firstButtonCallback: () => this.closeOutDialogs(),
      },
    });
  }

  openRestoreDialog(group: Group) {
    this.dialog.open(DialogComponent, {
      width: '531px',
      data: {
        title: this.translateService.instant('groups.dialog.restore'),
        secondButtonText: this.translateService.instant('actions.accept'),
        firstButtonText: this.translateService.instant('actions.cancel'),
        secondButtonCallback: () => {
          group.archived = false;
          this.updateArchived(group, false);
          this.closeOutDialogs();
        },
        firstButtonCallback: () => this.dialog.closeAll(),
      },
    });
  }

  openDisableDialog(group: Group) {
    this.dialog.open(DialogComponent, {
      width: '531px',
      data: {
        title: this.translateService.instant('groups.dialog.disable'),
        secondButtonText: this.translateService.instant('actions.accept'),
        firstButtonText: this.translateService.instant('actions.cancel'),
        secondButtonCallback: () => {
          group.archived = true;
          this.updateArchived(group, true);
          this.closeOutDialogs();
        },
        firstButtonCallback: () => this.dialog.closeAll(),
      },
    });
  }

  closeOutDialogs() {
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscriber) => subscriber.unsubscribe());
  }
}
