<details-page-header
    content="{{ 'groups.edit.title' | translate }}: '{{ group.group_id }}'"
    defaultBack="/groups"
></details-page-header>

<div class="section-main-container">
    <form #groupEditForm="ngForm" id="groupForm">
        <section class="flex flex-wrap">
            <!-- Flex Portfolio ID -->
            <div class="form-field-wrapper">
                <div class="flex items-start">
                    <span class="text-light-color">{{ 'groups.table.portfolio' | translate }}:</span>
                </div>
                <div class="flex">
                    <leviathan-jr-inline-edit
                        ngDefaultControl
                        htmlTag="span"
                        [inputType]="'input'"
                        [pattern]="id_validation"
                        [fieldToUpdate]="'portfolio_id'"
                        [(formControl)]="fieldControls['portfolio_id']"
                        [label]="'groups.placeholder.portfolio' | translate"
                        [callback]="handleEdit"
                    >
                    </leviathan-jr-inline-edit>
                </div>
            </div>

            <!-- Portfolio Name -->
            <div class="form-field-wrapper">
                <div class="flex items-start">
                    <span class="text-light-color">{{ 'groups.create.form.portfolio_name' | translate }}:</span>
                </div>
                <div class="flex">
                    <leviathan-jr-inline-edit
                        ngDefaultControl
                        htmlTag="span"
                        [inputType]="'input'"
                        [fieldToUpdate]="'portfolio_name'"
                        [required]="false"
                        [(formControl)]="fieldControls['portfolio_name']"
                        [label]="'groups.placeholder.portfolio_name' | translate"
                        [callback]="handleEdit"
                    >
                    </leviathan-jr-inline-edit>
                </div>
            </div>

            <!-- Product ID -->
            <div class="form-field-wrapper">
                <div class="flex items-start">
                    <span class="text-light-color">{{ 'groups.table.product' | translate }}:</span>
                </div>
                <div class="flex">
                    <leviathan-jr-inline-edit
                        ngDefaultControl
                        htmlTag="span"
                        [inputType]="'input'"
                        [fieldToUpdate]="'product_id'"
                        [pattern]="id_validation"
                        [(formControl)]="fieldControls['product_id']"
                        [label]="'groups.placeholder.product' | translate"
                        [callback]="handleEdit"
                    >
                    </leviathan-jr-inline-edit>
                </div>
            </div>

            <!-- Product Name -->
            <div class="form-field-wrapper">
                <div class="flex items-start">
                    <span class="text-light-color">{{ 'groups.create.form.product_name' | translate }}:</span>
                </div>
                <div class="flex">
                    <leviathan-jr-inline-edit
                        ngDefaultControl
                        htmlTag="span"
                        [inputType]="'input'"
                        [fieldToUpdate]="'product_name'"
                        [required]="false"
                        [(formControl)]="fieldControls['product_name']"
                        [label]="'groups.placeholder.product_name' | translate"
                        [callback]="handleEdit"
                    >
                    </leviathan-jr-inline-edit>
                </div>
            </div>

            <!-- Label -->
            <div class="form-field-wrapper">
                <div class="flex items-start">
                    <span class="text-light-color">{{ 'groups.table.label' | translate }}:</span>
                </div>
                <div class="flex">
                    <leviathan-jr-inline-edit
                        ngDefaultControl
                        htmlTag="span"
                        [inputType]="'input'"
                        [fieldToUpdate]="'group_id'"
                        [(formControl)]="fieldControls['group_id']"
                        [label]="'groups.placeholder.group_label' | translate"
                        [callback]="handleEdit"
                    >
                    </leviathan-jr-inline-edit>
                </div>
            </div>
            <!-- Group archived -->
            <div class="form-field-wrapper">
                <div class="flex items-start">
                    <span class="text-light-color">{{ 'events.archived' | translate }}:</span>
                </div>
                <div class="flex">
                    <leviathan-jr-inline-edit
                            ngDefaultControl
                            htmlTag="span"
                            [inputType]="'checkbox'"
                            [fieldToUpdate]="'archived'"
                            [(formControl)]="fieldControls['archived']"
                            [label]="group.archived.toString()"
                            [callback]="handleEdit"
                    >
                    </leviathan-jr-inline-edit>
                </div>
            </div>
        </section>
    </form>
</div>

<leviathan-jr-vens-table
    [tableData]="group.vens"
></leviathan-jr-vens-table>
