import { NgModule } from '@angular/core';
import { InlineEditComponent } from './inline-edit.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@NgModule({
  declarations: [InlineEditComponent],
  exports: [InlineEditComponent],
    imports: [
        CommonModule,
        MatIconModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        TranslateModule,
        MatCheckboxModule,
        FormsModule,
        MatProgressSpinnerModule,
    ],
})
export class InlineEditModule {}
