<div class="display-container">
    <fitted-loader *ngIf="loading"></fitted-loader>
    <div class="wrapper">
        <div class="available">
            <span class="section-header">{{ 'vens.edit.available_' + type | translate }}</span>
            <div class="table-wrapper">
                <table class="table" [dataSource]="available" mat-table>
                    <!-- Buttons Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell class="actions-header" *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button
                                    matTooltip="{{ 'vens.details.resources.include' | translate }}"
                                    class="action-btn"
                                    mat-icon-button
                                    (click)="toggleElement(element, true)"
                            >
                                <mat-icon> add </mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="id">
                        <th class="id-column" mat-header-cell *matHeaderCellDef>
                            {{ 'vens.edit.' + type + '.id' | translate }}
                        </th>
                        <td class="id-column" mat-cell *matCellDef="let element">
                            <ng-container *ngIf="element.resource_id">
                                <a routerLink="{{ '/resources/' + element.id }}">
                                    {{ element.resource_id }}
                                </a>
                            </ng-container>
                            <ng-container *ngIf="element.group_id">
                                <a routerLink="{{ '/groups/' + element.id }}">
                                    {{ element.group_id }}
                                </a>
                            </ng-container>
                            <ng-container *ngIf="element.context_url">
                                <a routerLink="{{ '/market-contexts/' + element.id }}">
                                    {{ element.context_url }}
                                </a>
                            </ng-container>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                </table>
            </div>
        </div>
        <div class="selected">
            <span class="section-header">{{ 'vens.edit.selected_' + type | translate }}</span>
            <div class="table-wrapper">
                <table class="table" [dataSource]="selected" mat-table>
                    <!-- Buttons Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell class="actions-header" *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button
                                    matTooltip="{{ 'vens.edit.' + type + '.remove' | translate }}"
                                    class="action-btn"
                                    mat-icon-button
                                    (click)="toggleElement(element, false)"
                            >
                                <mat-icon> remove </mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="id">
                        <th class="id-column" mat-header-cell *matHeaderCellDef>
                            {{ 'vens.edit.' + type + '.id' | translate }}
                        </th>
                        <td class="id-column" mat-cell *matCellDef="let element">
                            <ng-container *ngIf="element.resource_id">
                                <a routerLink="{{ '/resources/' + element.id }}">
                                    {{ element.resource_id }}
                                </a>
                            </ng-container>
                            <ng-container *ngIf="element.group_id">
                                <a routerLink="{{ '/groups/' + element.id }}">
                                    {{ element.group_id }}
                                </a>
                            </ng-container>
                            <ng-container *ngIf="element.context_url">
                                <a routerLink="{{ '/market-contexts/' + element.id }}">
                                    {{ element.context_url }}
                                </a>
                            </ng-container>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                </table>
            </div>
            <span class="section-header bulk-edit" (click)="removeAll()">{{ 'actions.remove_all' | translate }}</span>
        </div>
    </div>
</div>
