import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EventDetailsViewComponent } from './index';
import { DetailsPageHeaderModule } from '@components';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { EventMarketDisplayModule } from './markets/module';
import { ParticipationPlanDisplayModule } from './plan/module';

@NgModule({
  declarations: [EventDetailsViewComponent],
  exports: [EventDetailsViewComponent],
    imports: [
        CommonModule,
        TranslateModule,
        DetailsPageHeaderModule,
        MatInputModule,
        MatIconModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatButtonModule,
        RouterModule,
        EventMarketDisplayModule,
        ParticipationPlanDisplayModule,
    ],
})
export class EventDetailsViewModule {}
