<div class="section-header-container" *ngIf="report">
    <div class="flex-col">
        <h1 *ngIf="!report.id" class="section-header">{{ 'reports.create.header' | translate }}</h1>
        <h1 *ngIf="report.id" class="section-header">{{ 'reports.edit.header' | translate }}</h1>
    </div>
    <div class="right-column">
        <button
            class="icon-btn x-btn-secondary"
            aria-label="Cancel"
            (click)="openCancelReportCreation()"
            data-automation="resourceCancel"
        >
            <mat-icon>clear</mat-icon>
            <span class="btn-text">{{ 'actions.cancel' | translate }}</span>
        </button>

        <button
            class="icon-btn x-btn-primary"
            aria-label="Submit"
            [disabled]="reportForm?.invalid || descriptionForm?.invalid"
            (click)="openCancelOrAcceptDialog()"
            type="submit"
            data-automation="reportSubmit"
        >
            <mat-icon>done</mat-icon>
            <div *ngIf="submitting" class="btn-spinner">
                <mat-spinner diameter="15"></mat-spinner>
            </div>
            <span class="btn-text">{{ 'actions.submit' | translate }}</span>
        </button>
    </div>

</div>

<form #createReport="ngForm" id="reportForm" [formGroup]="reportForm" *ngIf="dataSource">
    <div class="form-wrapper flex flex-wrap form-group">
        <div class="form-field-wrapper w-1/4">
            <mat-form-field floatLabel="always" appearance="outline">
                <mat-label class="text-light-color" >
                    {{ 'reports.type.title' | translate }}:
                </mat-label>
                <mat-select
                    formControlName="type"
                    data-automation="type"
                >
                    <mat-option *ngFor="let style of TYPES" [value]="style">
                        {{  'reports.edit.form.type.' + style  | translate }}
                    </mat-option>
                </mat-select>
                <mat-hint style="margin: 16px 0">{{ 'vtn-reports.edit.type.help' | translate }}</mat-hint>
            </mat-form-field>
        </div>
        <div class="form-field-wrapper w-1/4">
            <mat-form-field floatLabel="always" appearance="outline">
                <mat-label class="text-light-color">{{ 'reports.specifier' | translate }}:</mat-label>
                <input
                    matInput
                    formControlName="specifierId"
                    data-automation="specifierId"
                    placeholder="{{ 'reports.specifier' | translate }}"
                />
                <mat-error *ngIf="(reportForm.get('specifierId')?.touched || reportForm.get('specifierId')?.dirty) && reportForm.get('specifierId').hasError('required')">
                    <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                </mat-error>
                <mat-error *ngIf="(reportForm.get('specifierId')?.touched || reportForm.get('specifierId')?.dirty) && reportForm.get('specifierId').hasError('minlength')">
                    <span class="input-entry">{{ 'common.validation.min_length' | translate }}: 6</span>
                </mat-error>
                <mat-error *ngIf="(reportForm.get('specifierId')?.touched || reportForm.get('specifierId')?.dirty) && reportForm.get('specifierId').hasError('alreadyError')">
                    <span class="input-entry">{{ 'common.validation.already_error_specifierId' | translate }}</span>
                </mat-error>
                <mat-hint style="margin: 16px 0">{{ 'vtn-reports.edit.specifier.help' | translate }}</mat-hint>
            </mat-form-field>
        </div>
        <div class="form-field-wrapper">
            <mat-form-field floatLabel="always" appearance="outline">
                <mat-label class="text-light-color" >
                    {{ 'reports.granularity' | translate }}:
                </mat-label>
                <mat-select
                    formControlName="granularity"
                    data-automation="granularity"
                >
                    <mat-option *ngFor="let gran of GRANULARITY" [value]="gran.value">
                        {{ 'reports.edit.form.granularity.' + gran.label | translate }}
                    </mat-option>
                </mat-select>
                <mat-hint style="margin: 16px 0">{{ 'reports.granularity.icon_info' | translate }}</mat-hint>
            </mat-form-field>
        </div>
    </div>

</form>

<form #createDescription="ngForm"  id="descriptionForm" [formGroup]="descriptionForm" *ngIf="dataSource" class="table-margin-top">
    <div class="flex-col description-button">
        <button class="icon-btn x-btn-primary" (click)="addDescriptions();" color="accent">
            <mat-icon>add</mat-icon>
            <span>{{ 'reports.add.description' | translate }}</span>
        </button>
    </div>
    <div class="descriptions-table-wrapper">
        <ng-container formArrayName="description">
            <table class="table" [dataSource]="dataSource" mat-table >
                <!-- Rid Column -->
                <ng-container matColumnDef="rID">
                    <th mat-header-cell *matHeaderCellDef  matTooltip="{{ 'vtn-reports.edit.descriptions.rid.help' | translate }}" class="action-btn" mat-icon-button>
                        {{ 'reports.edit.descriptions.rid' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                        <mat-form-field>
                            <mat-label>{{ 'reports.edit.descriptions.rid' | translate }}</mat-label>
                            <input matInput formControlName="rID" data-automation="rID"
                                placeholder="{{ 'reports.edit.descriptions.rid' | translate }}" />
                        </mat-form-field>
                        <mat-error *ngIf="(element.get('rID')?.touched || element.get('rID')?.dirty) && element.get('rID').hasError('minlength')">
                            <span class="input-entry">{{ 'common.validation.min_length' | translate }}: 6</span>
                        </mat-error>
                        <mat-error *ngIf="(element.get('rID')?.touched || element.get('rID')?.dirty) && element.get('rID').hasError('required')">
                            <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                        </mat-error>
                    </td>
                </ng-container>
                <!-- Reading Type Column -->
                <ng-container matColumnDef="readingType">
                    <th mat-header-cell *matHeaderCellDef matTooltip="{{ 'reports.description.reading_type' | translate }}" class="action-btn"
                    mat-icon-button>
                        {{ 'reports.edit.descriptions.reading_type' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element;let i = index" [formGroup]="element">
                        <ng-container>
                            {{ element.get('readingType').value ? element.get('readingType').value : '-' }}
                        </ng-container>
                    </td>
                </ng-container>
                <!-- Report Type Column -->
                <ng-container matColumnDef="reportType">
                    <th mat-header-cell *matHeaderCellDef matTooltip="{{ 'reports.description.report_type' | translate }}" class="action-btn"
                    mat-icon-button>
                        {{ 'reports.edit.form.type' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element;let i = index" [formGroup]="element">
                        <ng-container>
                            {{ element.get('reportType').value ? element.get('reportType').value : '-' }}
                        </ng-container>
                    </td>
                </ng-container>
                <!-- Report Data Source -->
                <ng-container matColumnDef="reportDataSource">
                    <th mat-header-cell *matHeaderCellDef matTooltip="{{ 'reports.reportDataSource.description' | translate }}" class="action-btn"
                    mat-icon-button>
                        {{ 'reports.reportDataSource' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element;let i = index" [formGroup]="element">
                        <div class="mb-5">
                            <div *ngIf="ven.groups?.length > 0">
                                <mat-form-field floatLabel="always" appearance="outline" class="market-context-field">
                                    <mat-label class="text-light-color" >
                                        {{ 'tabs.event.groups' | translate }}:
                                    </mat-label>
                                    <mat-select formControlName="reportDataSource" data-automation="reportDataSource"
                                        (selectionChange)="onGroupChange($event, 'group')"
                                        [selected]="reportDataSelected[i]?.value">
                                        <mat-option [value]="null"></mat-option>
                                        <mat-option *ngFor="let group of groupList" [value]="group.group_id" [disabled]="isOptionDisabled(group.group_id)">
                                            {{ group.group_id | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div *ngIf="ven.resources?.length > 0">
                                <mat-form-field floatLabel="always" appearance="outline" class="market-context-field">
                                    <mat-label class="text-light-color" >
                                        {{ 'tabs.event.resources' | translate }}:
                                    </mat-label>
                                    <mat-select formControlName="reportDataSource" data-automation="reportDataSource"
                                        (selectionChange)="onGroupChange($event,'resources')">
                                        <mat-option [value]="null"></mat-option>
                                        <mat-option *ngFor="let resource of resourcesList" [value]="resource.resource_id"  [disabled]="isOptionDisabled(resource.resource_id)">
                                            {{ resource.resource_id | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <!-- Sampling Rate -->
                <!--
                <ng-container matColumnDef="samplingRate">
                    <th mat-header-cell *matHeaderCellDef matTooltip="{{ 'reports.samplingRate.description' | translate }}" class="action-btn"
                    mat-icon-button>
                        {{ 'reports.samplingRate' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element;let i = index" [formGroup]="element">

                        <div *ngFor="let sampling of element.get('samplingRate').value">
                            <ng-container *ngIf="sampling.productId">
                                {{'groups.table.cell.product_id' | translate}}: {{sampling.productId}}
                                <span style="margin-left: 10px;">
                                    <ul class="list-style">
                                        <li>
                                            {{'reports.min_duration' | translate }} : {{ sampling.minPeriod ?
                                            sampling.minPeriod : '-' }}
                                        </li>
                                        <li>{{'reports.max_duration' | translate }} : {{ sampling.maxPeriod ?
                                            sampling.maxPeriod : '-' }}</li>
                                    </ul>
                                </span>
                            </ng-container>
                            <ng-container *ngIf="sampling.reporting_interval_ms">
                                <span style="margin-left: 10px;">
                                    <ul class="list-style">
                                        <li>
                                            {{'reports.samplingRate' | translate }} : {{ sampling.reporting_interval_ms ?
                                            sampling.reporting_interval_ms : '-' }}
                                        </li>
                                    </ul>
                                </span>
                            </ng-container>
                        </div>
                    </td>
                </ng-container>
                -->
                <!-- Market Context  -->
                <ng-container matColumnDef="marketContext">
                    <th mat-header-cell *matHeaderCellDef matTooltip="{{ 'reports.marketContext.description' | translate }}" class="action-btn"
                    mat-icon-button>
                        {{ 'reports.marketContext' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element;let i = index" [formGroup]="element">
                        <div class="mb-5">
                            <mat-form-field floatLabel="always" appearance="outline" class="market-context-field">
                                <mat-label class="text-light-color" >
                                    {{ 'events.table.market_context' | translate }}:
                                </mat-label>
                                <mat-select formControlName="marketContext" data-automation="marketContext">
                                    <mat-option *ngFor="let marketList of marketContextList" [value]="marketList.context_url"  [selected]="element.get('marketContext').value">
                                        {{ marketList.context_url | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </td>
                </ng-container>
                <!-- Buttons Column -->
                <ng-container matColumnDef="actions" stickyEnd>
                    <th class="actions-column" mat-header-cell class="actions-header" *matHeaderCellDef>
                        {{ 'vens.table.actions' | translate }}
                    </th>
                    <td class="actions-column" mat-cell class="actions-cell" *matCellDef="let report; let i = index">
                        <div class="btn-container-report">
                            <button class="icon-btn x-btn-secondary" aria-label="Cancel"
                                (click)="removeItem(i)" data-automation="resourceCancel">
                                <mat-icon>delete_outline</mat-icon>
                                <span class="btn-text">{{ 'reports.table.delete' | translate }}</span>
                            </button>

                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
            </table>
        </ng-container>
    </div>
</form>
