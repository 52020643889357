<div class="section-header-container" *ngIf="ven">
    <div class="flex-col">
        <h1 *ngIf="!ven.id" class="section-header">{{ 'vens.create.header' | translate }}</h1>
        <h1 *ngIf="ven.id" class="section-header">{{ 'vens.edit.title' | translate }}</h1>
    </div>
    <div class="right-column">
        <button
            class="icon-btn x-btn-secondary"
            aria-label="Cancel"
            (click)="handleVenCancel()"
            data-automation="resourceCancel"
        >
            <mat-icon>clear</mat-icon>
            <span class="btn-text">{{ 'actions.cancel' | translate }}</span>
        </button>

        <button
            class="icon-btn x-btn-primary"
            aria-label="Submit"
            [disabled]="venForm.invalid"
            (click)="openCancelOrAcceptDialog()"
            type="submit"
            data-automation="venSubmit"
        >
            <mat-icon>done</mat-icon>
            <div *ngIf="submitting" class="btn-spinner">
                <mat-spinner diameter="15"></mat-spinner>
            </div>
            <span class="btn-text">{{ 'actions.submit' | translate }}</span>
        </button>
    </div>
</div>
<form #createVen="ngForm" id="venForm" [formGroup]="venForm">
    <div class="form-wrapper flex flex-wrap form-group">
        <div class="form-field-wrapper">
            <mat-form-field floatLabel="always" appearance="outline">
                <mat-label class="text-light-color">{{ 'vens.create.form.ven_name' | translate }}:</mat-label>
                <input
                    matInput
                    formControlName="name"
                    data-automation="name"
                    placeholder="{{ 'vens.create.form.placeholder.ven_name' | translate }}"
                />
                <mat-hint style="margin: 16px 0">{{ 'vens.edit.name.hint' | translate }}</mat-hint>
                <mat-error *ngIf="venForm.get('name').hasError('required')">
                    <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-field-wrapper">
            <mat-form-field floatLabel="always" appearance="outline">
                <mat-label class="text-light-color">{{ 'vens.create.form.ven_id' | translate }}:</mat-label>
                <input
                    matInput
                    formControlName="ven_id"
                    data-automation="venId"
                    placeholder="{{ 'vens.create.form.placeholder.ven_id' | translate }}"
                />
                <mat-hint style="margin: 16px 0">{{ 'vens.edit.ven_id.hint' | translate }}</mat-hint>
                <mat-error *ngIf="venForm.get('ven_id').hasError('required')">
                    <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-field-wrapper">
            <mat-form-field floatLabel="always" appearance="outline">
                <mat-label class="text-light-color">{{ 'vens.create.form.finger_print' | translate }}:</mat-label>
                <input
                    matInput
                    formControlName="fingerprint"
                    data-automation="fingerPrint"
                    placeholder="{{ 'vens.create.form.placeholder.finger_print' | translate }}"
                />
                <mat-hint style="margin: 16px 0">{{ 'vens.edit.fingerprint.hint' | translate }}</mat-hint>
            </mat-form-field>
        </div>
        <div class="form-field-wrapper">
            <mat-form-field floatLabel="always" appearance="outline">
                <!-- matTooltip="{{ 'vens.create.form.signal_style_help' | translate }}" -->
                <mat-label class="text-light-color" >
                    {{ 'vens.create.form.signal_style' | translate }}:
                </mat-label>
                <mat-select
                    formControlName="signal_style"
                    data-automation="signal_style"
                >
                    <mat-option *ngFor="let style of SIGNAL_STYLES" [value]="style">
                        {{ 'vens.create.form.signal_styles.' + style | translate }}
                    </mat-option>
                </mat-select>
                <mat-hint style="margin: 16px 0">{{ 'vens.edit.signal_style.hint' | translate }}</mat-hint>
            </mat-form-field>
        </div>
        <div class="form-field-wrapper" *ngIf="ven.id">
            <mat-checkbox formControlName="archived">
                {{ 'vens.details.archived' | translate }}
            </mat-checkbox>
        </div>
    </div>
</form>

<form *ngIf="ven">
    <div class="section-header-container" *ngIf="ven">
        <h2>{{ 'vens.create.subscriptions' | translate }}</h2>
    </div>
    <div class="section-help">{{ 'vens.create.subscriptions.help' | translate }}</div>
    <div class="section-content">
        <div class="form-field-wrapper" style="margin: 0 -26px">
            <mat-form-field floatLabel="always" appearance="outline">
                <mat-label class="text-light-color" >
                    {{ 'vens.create.subscription_style' | translate }}:
                </mat-label>
                <mat-select [(ngModel)]="subscriptionStyle" (selectionChange)="changeSubscriptionStyle()">
                    <mat-option *ngFor="let style of SUBSCRIPTION_STYLES" [value]="style">
                        {{ 'vens.create.subscription_styles.' + style | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="flex flex-wrap">
            <div class="subscription-section">
                <edit-subscriptions
                    [selected]="ven[subscriptionStyle]"
                    [type]="subscriptionStyle"
                    (update)="ven[subscriptionStyle] = $event"
                >
                </edit-subscriptions>
            </div>
        </div>
    </div>
</form>
