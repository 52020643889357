<table class="table actions-table" [dataSource]="resources" mat-table>
    <!-- Registration id Column -->
    <ng-container matColumnDef="registration">
        <th class="reg-id-column" mat-header-cell *matHeaderCellDef>
            {{ 'resources.table.registration' | translate }}
        </th>
        <td class="reg-id-column" mat-cell *matCellDef="let element">
            <a class="test" [routerLink]="['/resources', element.id]">
                {{ element.registration_id ? element.registration_id : '-' }}
            </a>
        </td>
    </ng-container>

    <!-- Registration Name Column -->
    <ng-container matColumnDef="registration_name">
        <th class="reg-name-column" mat-header-cell *matHeaderCellDef>
            {{ 'resources.table.registration_name' | translate }}
        </th>
        <td class="reg-name-column" mat-cell *matCellDef="let element">
            <span>{{ element.registration_name ? element.registration_name : '-' }}</span>
        </td>
    </ng-container>

    <!-- Resource ID Column -->
    <ng-container matColumnDef="resource">
        <th class="resource-name-column" mat-header-cell *matHeaderCellDef>
            {{ 'resources.table.resource' | translate }}
        </th>
        <td class="resource-name-column" mat-cell *matCellDef="let element">
            <a class="test" [routerLink]="['/resources', element.id ? element.id : null]">{{
                element.resource_id ? element.resource_id : '-'
            }}</a>
        </td>
    </ng-container>

    <!-- Resource Type Column -->
    <ng-container matColumnDef="resource_type">
        <th class="resource-type-column" mat-header-cell *matHeaderCellDef>
            {{ 'resources.table.resource_type' | translate }}
        </th>
        <td class="resource-type-column" mat-cell *matCellDef="let element">
            {{ 'resources.create.form.resource_types.' + (element.type || 'none').toLowerCase() | translate }}
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="resourceCols"></tr>
    <tr mat-row *matRowDef="let row; columns: resourceCols"></tr>
</table>
