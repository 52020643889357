<ng-container>
    <div>
        <mat-expansion-panel
            [expanded]="true"
            (opened)="panelOpenedState = true"
            (closed)="panelOpenedState = false"
            togglePosition="before"
            class="expansion-panel panel_{{ index }}"
        >
            <mat-expansion-panel-header class="panel-override" collapsedHeight="74px" expandedHeight="48px">
                <mat-panel-title class="point-title">
                    <div data-automation="marketId">
                        <span>
                            {{ market.context_url }}
                        </span>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="flex flex-wrap">
                <!-- Operator Id -->
                <div class="form-field-wrapper">
                    <div class="flex items-start">
                        <span class="text-light-color">{{ 'vens.details.market.operator_id' | translate }}:</span>
                    </div>
                    <div>
                        <span class="static-detail">
                            {{ market.operator_id }}
                        </span>
                    </div>
                </div>

                <!-- Operator Name -->
                <div class="form-field-wrapper" *ngIf="market.operator_name">
                    <div class="flex items-start">
                        <span class="text-light-color">{{ 'vens.details.market.operator_name' | translate }}:</span>
                    </div>
                    <div>
                        <span class="static-detail">
                            {{ market.operator_name }}
                        </span>
                    </div>
                </div>

                <!-- Program Id -->
                <div class="form-field-wrapper">
                    <div class="flex items-start">
                        <span class="text-light-color">{{ 'vens.details.market.program_id' | translate }}:</span>
                    </div>
                    <div>
                        <span class="static-detail">
                            {{ market.program_id }}
                        </span>
                    </div>
                </div>

                <!-- Program Name -->
                <div class="form-field-wrapper" *ngIf="market.program_name">
                    <div class="flex items-start">
                        <span class="text-light-color">{{ 'vens.details.market.program_name' | translate }}:</span>
                    </div>
                    <div>
                        <span class="static-detail">
                            {{ market.program_name }}
                        </span>
                    </div>
                </div>

                <!-- Context Url -->
                <div class="form-field-wrapper">
                    <div class="flex items-start">
                        <span class="text-light-color">{{ 'vens.details.market.context_url' | translate }}:</span>
                    </div>
                    <div>
                        <span class="static-detail">
                            {{ market.context_url }}
                        </span>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
</ng-container>
