import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { BaseService } from './base.service';
import { VenRegistration } from "@models";

@Injectable()
export class RegistrationService extends BaseService<VenRegistration> {
    override path = '/api/v1/ven-registrations';

    getAllRegistrations$(): Observable<VenRegistration[]> {
        return this.getList();
    }

    getRegistration$(id: number | string): Observable<VenRegistration> {
        return this.getById(id);
    }

    createRegistration$(resource: Partial<VenRegistration>): Observable<VenRegistration> {
        return this.add(resource);
    }

    updateRegistration$(id : number | string, context : VenRegistration | Partial<VenRegistration>): Observable<VenRegistration> {
        return this.update(id, context);
    }

    deleteRegistration$(id: number | string): Observable<VenRegistration> {
        return this.delete(id);
    }
}

