import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DetailsPageHeaderComponent } from './index';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
    declarations: [DetailsPageHeaderComponent],
    exports: [DetailsPageHeaderComponent],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        MatIconModule,
    ],
})
export class DetailsPageHeaderModule {}
