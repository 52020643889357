export interface VenRegistration {
    id?: string;
    registration_id: string;
    poll_rate: string;
}

export class VenRegistration {
    constructor() {
        this.id = null;
        this.registration_id = null;
        this.poll_rate = null;
    }
}
