import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ExportModalComponent } from './index';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ExportModalComponent],
  exports: [ExportModalComponent],
    imports: [
      CommonModule,
      TranslateModule,
      MatProgressBarModule,
    ],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA
    ],
})
export class ExportModalModule {}
