import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GroupListViewComponent } from './index';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
  declarations: [GroupListViewComponent],
  exports: [GroupListViewComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatTableModule,
    RouterModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatChipsModule,
    MatButtonModule,
    FormsModule,
    MatCheckboxModule,
    MatTooltipModule
  ],
})
export class GroupListViewModule {}
