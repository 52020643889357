import { ActivatedRoute } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export class BaseTableViewComponent {
    dataSource: MatTableDataSource<any>;

    loading: boolean = true;
    per_page: number = 10;
    page = 1;
    archived: boolean = false;
    _search: string = '';
    search: string;
    order: string = null;
    direction: string = null;
    total: number;

    initialize(route: ActivatedRoute) {
        const {q, page, per_page, order } = {...route.snapshot.queryParams} as any;
        if (page) this.page = parseInt(page) || 1;
        if (per_page) this.per_page = parseInt(per_page) || 20;
        if (q) this.search = q;

        const [o, d] = (order || '').split('__');
        if (o) {
            this.order = o;
            this.direction = d || 'desc';
        }
        this.loadData();
    }

    setSearch() {
        if (this._search && this._search.length <= 2) return;
        this.search = this._search.trim();
        this._search = '';
        this.page = 1;
        this.loadData();
    }
    removeSearch() {
        this.search = '';
        this.page = 1;
        this.loadData();
    }

    updateSort(sort: Sort) {
        if (sort.direction && sort.active) {
            this.order = sort.active;
            this.direction = sort.direction;
        } else {
            this.order = null;
            this.direction = null;
        }
        this.loadData();
    }

    pageEvent(ev) {
        this.per_page = ev.pageSize;
        this.page = ev.pageIndex + 1;
        this.loadData();
    }

    updateParams() {
        this.page = 1;
        this.loadData();
    }

    dataCall(qp): Promise<{meta?: any, data: any[]}> {
        return null;
    }

    loadData(): Promise<void> {
        const params: any = {
            page: this.page,
            per_page: this.per_page,
            archived: this.archived,
        };
        if (this.search) params.q = this.search;
        if (this.order) params.order = `${this.order}__${this.direction}`;
        if (params.page === 1) Reflect.deleteProperty(params, 'page');
        if (params.per_page === 10) Reflect.deleteProperty(params, 'per_page');
        if (params.archived === false) Reflect.deleteProperty(params, 'archived');

        this.loading = true;
        return this.dataCall(params).then((resp) => {
            this.dataSource = new MatTableDataSource(resp.data);
            this.loading = false;
            this.total = resp.meta.total;
        });
    }
}
