import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseTableViewComponent } from '../../common/base-table-view';
import { MatDialog } from '@angular/material/dialog';
import { EventService } from '@services';

@Component({
  selector: 'event-list-view',
  templateUrl: './index.html',
  styleUrls: ['./style.scss'],
})
export class EventListViewComponent extends BaseTableViewComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private eventService: EventService,
  ) {
    super();
  }

  ngOnInit() {
    this.initialize(this.route);
  }

  override dataCall(qp) {
    this.router.navigate(['events'], {replaceUrl: true, queryParams: qp});
    return this.eventService.getPage(qp);
  }
}
