<details-page-header
    content="{{ 'markets.edit.title' | translate }}: '{{ marketContext.context_url }}'"
    defaultBack="/markets"
></details-page-header>
<div class="section-main-container" *ngIf="!noMarket">
    <form #marketContextEditForm="ngForm" id="marketContextForm">
        <section class="flex flex-wrap">
            <!-- Context Operator -->
            <div class="form-field-wrapper">
                <div class="flex items-start">
                    <span class="text-light-color">{{ 'markets.create.form.operator' | translate }}:</span>
                </div>
                <div class="flex">
                    <leviathan-jr-inline-edit
                        ngDefaultControl
                        htmlTag="span"
                        [inputType]="'input'"
                        [fieldToUpdate]="'operator_id'"
                        [(formControl)]="fieldControls['operator_id']"
                        [label]="'markets.placeholder.operator_flex_id' | translate"
                        [callback]="handleEdit"
                        [pattern]="id_validation"
                    >
                    </leviathan-jr-inline-edit>
                </div>
            </div>

            <!-- Context Operator Name -->
            <div class="form-field-wrapper">
                <div class="flex items-start">
                    <span class="text-light-color">{{ 'markets.create.form.operator_name' | translate }}:</span>
                </div>
                <div class="flex">
                    <leviathan-jr-inline-edit
                        ngDefaultControl
                        htmlTag="span"
                        [inputType]="'input'"
                        [fieldToUpdate]="'operator_name'"
                        [required]="false"
                        [(formControl)]="fieldControls['operator_name']"
                        [label]="'markets.placeholder.operator_name' | translate"
                        [callback]="handleEdit"
                    >
                    </leviathan-jr-inline-edit>
                </div>
            </div>

            <!-- Context Program -->
            <div class="form-field-wrapper">
                <div class="flex items-start">
                    <span class="text-light-color">{{ 'markets.create.form.program' | translate }}:</span>
                </div>
                <div class="flex">
                    <leviathan-jr-inline-edit
                        ngDefaultControl
                        htmlTag="span"
                        [inputType]="'input'"
                        [fieldToUpdate]="'program_id'"
                        [(formControl)]="fieldControls['program_id']"
                        [label]="'markets.placeholder.program_flex_id' | translate"
                        [callback]="handleEdit"
                        [pattern]="id_validation"
                    >
                    </leviathan-jr-inline-edit>
                </div>
            </div>

            <!-- Context Program Name -->
            <div class="form-field-wrapper">
                <div class="flex items-start">
                    <span class="text-light-color">{{ 'markets.create.form.program_name' | translate }}:</span>
                </div>
                <div class="flex">
                    <leviathan-jr-inline-edit
                        ngDefaultControl
                        htmlTag="span"
                        [inputType]="'input'"
                        [required]="false"
                        [fieldToUpdate]="'program_name'"
                        [(formControl)]="fieldControls['program_name']"
                        [label]="'markets.placeholder.program_name' | translate"
                        [callback]="handleEdit"
                    >
                    </leviathan-jr-inline-edit>
                </div>
            </div>

            <!-- Context URL -->
            <div class="form-field-wrapper">
                <div class="flex items-start">
                    <span class="text-light-color">{{ 'markets.create.form.context' | translate }}:</span>
                </div>
                <div class="flex">
                    <leviathan-jr-inline-edit
                        ngDefaultControl
                        htmlTag="span"
                        [inputType]="'input'"
                        [fieldToUpdate]="'context_url'"
                        [(formControl)]="fieldControls['context_url']"
                        [label]="'markets.placeholder.context_uri' | translate"
                        [callback]="handleEdit"
                    >
                    </leviathan-jr-inline-edit>
                </div>
            </div>
            <!-- Context archived -->
            <div class="form-field-wrapper">
                <div class="flex items-start">
                    <span class="text-light-color">{{ 'markets.archived' | translate }}:</span>
                </div>
                <div class="flex">
                    <leviathan-jr-inline-edit
                        ngDefaultControl
                        htmlTag="span"
                        [inputType]="'checkbox'"
                        [fieldToUpdate]="'archived'"
                        [(formControl)]="fieldControls['archived']"
                        [label]="marketContext.archived.toString()"
                        [callback]="handleEdit"
                    >
                    </leviathan-jr-inline-edit>
                </div>
            </div>
        </section>
    </form>
</div>

<leviathan-jr-vens-table
    [tableData]="marketContext.vens"
></leviathan-jr-vens-table>
