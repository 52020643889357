import {
  FormArray,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Group, Report, Resource, VEN } from '@models';
import { ComProductService, GlobalAlertService, MarketContextService, PointService, ReportService, VenService } from '@services';
import { TranslateService } from '@ngx-translate/core';
import { catchError, of, Subject, Subscription, takeUntil } from 'rxjs';
import { millisecondsToIsoDuration } from 'src/common/convertToISOduration';


export class EditReportComponent {
  subscriptions: Subscription[] = [];
  unsubscribe = new Subject<void>();
  BAD_REQUEST = '';
  SUCCESS = 'Report was saved successfully';
  report: Partial<Report> = {};
  reportEdit: Partial<Report>;
  ven: Partial<VEN> = {};
  reportForm: UntypedFormGroup;
  submitting: boolean = false;
  dataSource: MatTableDataSource<any>;
  descriptionForm: UntypedFormGroup;
  reportDataSource = [];
  marketContext = [];
  samplingRate = [];
  marketContextList = [];
  groupList = [];
  resourcesList = [];
  reportDataSelected = [];
  disabledOptions: Set<{ value: string, index: number }> = new Set();
  constructor(
    public reportService: ReportService,
    public fb: UntypedFormBuilder,
    public venService: VenService,
    public productService: ComProductService,
    public messageService: GlobalAlertService,
    public translateService: TranslateService,
    public marketService: MarketContextService,
    public pointService: PointService,
  ) {
    this.SUCCESS = this.translateService.instant('vtn-reports.save.success');
  }

  editReport() {
    if (this.report.id) {
      return this.reportService.getReportById$(this.report.id).subscribe((report: Report) => {
        this.reportEdit = report;
        this.reportFormEdit();
        this.reportDescriptionEdit();
        this.reportEdit?.descriptions.forEach((desc, index: number) => {
          this.getReportDataSourceSelected(desc.reportDataSource, index);
         });
        if (this.reportEdit.descriptions.length === 0) {
          this.removeItem(0);
        }
        this.submitting = false;
      });
    }
    return null;
  }
  getReportDataSourceSelected(reportDataSource: string, index: number){
    const findGroupSelected = this.groupList.find((group: Group) => ( group.group_id === reportDataSource ));
    const findResourceSelected = this.resourcesList.find((resource: Resource) => ( resource.resource_id === reportDataSource ));
    if(findGroupSelected) {
      this.reportDataSelected.push({ type: 'group', checked: true, value: reportDataSource, index: index })
    }
    if(findResourceSelected) {
      this.reportDataSelected.push({ type: 'resources', checked: true, value: reportDataSource, index: index});
    }
    this.reportEdit?.descriptions.forEach((description, index) => {
      this.checkToDisable({value: description?.reportDataSource, index: index}, index)
    });
  }
  reportFormEdit() {
    this.reportForm.get('type').setValue(this.reportEdit.type);
    this.reportForm.get('specifierId').setValue(this.reportEdit.specifier_id);
    this.reportForm.get('granularity').setValue(this.reportEdit.granularity);
  }
  getList(marketContext: MarketContextService) {
    marketContext?.getList().pipe(
      catchError((err: any) => {
        return of(err);
      }),
    ).pipe(takeUntil(this.unsubscribe)).subscribe((marketList) => {
      if(marketList){
        this.marketContextList = marketList;
      }
    });
  }
  isOptionDisabled(option: string): boolean {
    return [...this.disabledOptions].some(item => item?.value === option);
  }
  onGroupChange($event, type: string) {
    // const groupSelected = this.groupList.find((group: Group) => group.group_id === $event.value);
    // const resourcesSelected = this.resourcesList.find((resource: Resource) => resource.resource_id === $event.value);
    const selectIndex = this.items.controls.findIndex((data: any) => data.get('reportDataSource').value === $event.value);
    const object = { value: $event.value, index: selectIndex };
    this.checkToDisable(object, selectIndex);
    // type === 'group' ? this.getSamplingRateGroup(groupSelected) : this.getSamplingRateResources(resourcesSelected);
  }
  checkToDisable(object: { value: string, index: number }, selectIndex) {
    if (this.disabledOptions.size > 0) {
      const optionsToUpdate = [];
      this.disabledOptions.forEach((options) => {
        if (options.index === selectIndex) {
          optionsToUpdate.push(options);
        }
      });
      optionsToUpdate.forEach(option => {
        this.disabledOptions.delete(option);
      });
      this.disabledOptions.add(object);
    } else {
      this.disabledOptions.add(object);
    }
  }
  reportDescriptionEdit() {
    this.reportEdit.descriptions.forEach((description, index) => {
      if (!(this.items.at(index) as FormGroup)) {
        this.addDescriptions();
      }
      (this.items.at(index) as FormGroup).get('rID').setValue(description.rID);
      (this.items.at(index) as FormGroup).get('readingType').setValue(description.readingType);
      (this.items.at(index) as FormGroup).get('reportType').setValue(description.reportType);
      (this.items.at(index) as FormGroup).get('reportDataSource').setValue(description.reportDataSource.groupID || description.reportDataSource.resourceID || '');
      // (this.items.at(index) as FormGroup).get('samplingRate').setValue(description.samplingRate);
      (this.items.at(index) as FormGroup).get('marketContext').setValue(description.marketContext);
    });
  }
  removeItem(index: number) {
    const reporDataToRemove = [];
    this.disabledOptions.forEach((options) => {
      if (options.index === index) {
        reporDataToRemove.push(options);
      }
      options.index > index ? options.index = options.index - 1 : '';
    });
    reporDataToRemove.forEach(option => {
      this.disabledOptions.delete(option);
    });
    this.reportDataSelected.splice(index, 1);
    this.reportDataSelected = this.reportDataSelected.map((report) => {
      if (report.index > index) {
        return { ...report, index: report.index - 1 };
      }
      return report;
    });
    this.items.removeAt(index);
    this.dataSource = new MatTableDataSource(this.items.controls);
  }

  get items(): FormArray {
    return this.descriptionForm?.get('description') as FormArray;
  }
  addDescriptions() {
    this.items.push(this.addItem());
   (this.items.at(this.items.length - 1) as FormGroup)
     .get('marketContext')
     .setValue((this.items.at(0) as FormGroup).get('marketContext').value);
    this.dataSource = new MatTableDataSource(this.items.controls);
  }

  addItem(): FormGroup {
    return this.fb.group({
      rID: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
      readingType: new UntypedFormControl('Contract', [Validators.required]),
      reportType: new UntypedFormControl('deltaUsage', [Validators.required]),
      reportDataSource: new UntypedFormControl(''),
      // samplingRate: new UntypedFormControl([]),
      marketContext: new UntypedFormControl(''),
    });
  }

  getVens() {
    return this.venService.getById(this.ven.id).subscribe((vens: VEN) => {
      this.ven = vens;
      this.dataSource = new MatTableDataSource(this.items.controls);
      this.submitting = false;
      if(this.ven.groups.length){
        this.groupList = this.ven.groups;
      }
      if(this.ven.resources.length){
        this.resourcesList = this.ven.resources;
      }
       this.subscriptions.push(this.editReport());
    });
  }
  getSamplingRateGroup(group: Group) {
      return this.productService
      .getProductById(group?.product_id)
      .then((product) => {
        this.samplingRate = [{
          productId: group.product_id,
          minPeriod: `PT${product?.data.schedule.offer_frequency}${product?.data.schedule.offer_frequency_unit.charAt(
            0,
          )}`,
          maxPeriod: `PT${product?.data.schedule.offer_frequency}${product?.data.schedule.offer_frequency_unit.charAt(
            0,
          )}`,
          onChange: false,
        }];
        this.samplingRateResolve(group.group_id);
      })
      .catch((err: Error) => {
        this.BAD_REQUEST = this.translateService.instant('notification.bad_request.group');
        this.messageService.setError(`${this.BAD_REQUEST}`);
        this.samplingRate = [{
          productId: '',
          minPeriod: 0,
          maxPeriod: 0,
          onChange: false
        }];
        this.samplingRateResolve(group.group_id);
      })
  }

  getSamplingRateResources(resources: Resource){
    const query: any = {
      registration_id: resources.registration_id
     }
      return this.pointService
      .getList(query)
      .pipe(
        catchError((err: any) => {
          this.BAD_REQUEST = this.translateService.instant('notification.bad_request.registration');
          this.messageService.setError(`${this.BAD_REQUEST}`);
          this.samplingRate = [{ reporting_interval_ms: '', registration_id: 0}];
          this.samplingRateResolve(resources.resource_id);
          return of(err);
        }))
      .subscribe((point) => {
        if(point.length > 0){
          this.samplingRate = point?.map((pointData) => ({ reporting_interval_ms: millisecondsToIsoDuration(pointData.reporting_interval_ms) }))
          this.samplingRateResolve(resources.resource_id);
        }
      })
  }
  samplingRateResolve(id: string) {
    this.items.controls.forEach((data: any, index) => {
      if (data.get('reportDataSource').value === id ) {
        (this.items.at(index) as FormGroup).get('samplingRate').setValue(this.samplingRate);
      }
    })
  }
}
