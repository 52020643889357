import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { Resource } from "@models";

@Injectable()
export class ResourceService extends BaseService<Resource> {
    override path = '/api/v1/resources';

    getAllResources$(): Observable<Resource[]> {
        return this.getList();
    }

    getResource$(id: number | string): Observable<Resource> {
        return this.getById(id);
    }

    createResource$(resource: Partial<Resource>): Observable<Resource> {
        return this.add(resource);
    }

    updateResource$(id : number | string, context : Resource | Partial<Resource>): Observable<Resource> {
        return this.update(id, context);
    }

    deleteResource$(id: number | string): Observable<Resource> {
        return this.delete(id);
    }
}

