import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ModalComponent } from './index';
import { CommonModule } from '@angular/common';
import '@dsy/enelx'
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ModalComponent],
  exports: [ModalComponent],
    imports: [
      CommonModule,
      TranslateModule
    ],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA
    ],
})
export class ModalModule {}