import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { GroupService, GlobalAlertService } from '@services';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Group } from '@models';
import { DialogComponent } from '@components';

@Component({
  selector: 'group-edit-view',
  templateUrl: './index.html',
  styleUrls: ['./style.scss'],
})
export class GroupEditViewComponent implements OnInit, OnDestroy {
  BAD_REQUEST = 'Oops, There was a problem with your request';
  GROUP_SUCCESS = 'Group was created successfully';
  subscriptions: Subscription[] = [];
  group: Partial<Group> = {
    archived: false,
  };
  id_validation = '^(\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12})$';
  groupForm = new UntypedFormGroup({
    portfolio: new UntypedFormControl('', Validators.required),
    portfolio_name: new UntypedFormControl(''),
    product: new UntypedFormControl('', Validators.required),
    product_name: new UntypedFormControl(''),
    label: new UntypedFormControl('', Validators.required),
  });
  get portfolio() {
    return this.groupForm.get('portfolio');
  }
  get product() {
    return this.groupForm.get('product');
  }
  submitting = false;

  constructor(
    private translateService: TranslateService,
    public dialog: MatDialog,
    private groupService: GroupService,
    private ngZone: NgZone,
    private router: Router,
    private messageService: GlobalAlertService,
  ) {
    this.BAD_REQUEST = this.translateService.instant('notification.bad_request');
    this.GROUP_SUCCESS = this.translateService.instant('groups.create.notification.success');
  }

  ngOnInit(): void {
    const portfolioSub = this.groupForm.get('portfolio').valueChanges.subscribe((value) => {
      this.group.portfolio_id = value;
    });
    const portfolio_nameSub = this.groupForm.get('portfolio_name').valueChanges.subscribe((value) => {
      this.group.portfolio_name = value;
    });
    const productSub = this.groupForm.get('product').valueChanges.subscribe((value) => {
      this.group.product_id = value;
    });
    const product_nameSub = this.groupForm.get('product_name').valueChanges.subscribe((value) => {
      this.group.product_name = value;
    });
    const labelSub = this.groupForm.get('label').valueChanges.subscribe((value) => {
      this.group.group_id = value;
    });
    this.subscriptions.push(...[portfolioSub, portfolio_nameSub, productSub, product_nameSub, labelSub]);
  }

  handleEventCancel() {
    this.submitting = false;
    this.router.navigate([`/groups`]);
  }

  createGroupContext() {
    this.submitting = true;
    try {
      const createGroupSub = this.groupService.createGroup$(this.group).subscribe({
        next: (group) => {
          this.ngZone.run(() => {
            this.messageService.setSuccess(this.GROUP_SUCCESS);
            this.router.navigateByUrl(`groups/${group.id}`);
          });
        },
        error: () => {
          this.messageService.setError(this.BAD_REQUEST);
        },
      });
      this.subscriptions.push(createGroupSub);
    } finally {
      this.submitting = false;
      this.dialog.closeAll();
    }
  }

  openCancelOrAcceptDialog() {
    this.dialog.open(DialogComponent, {
      width: '531px',
      data: {
        title: this.translateService.instant('groups.create.verification'),
        secondButtonText: this.translateService.instant('actions.accept'),
        firstButtonText: this.translateService.instant('actions.cancel'),
        secondButtonCallback: () => this.createGroupContext(),
        firstButtonCallback: () => this.dialog.closeAll(),
      },
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscriber) => subscriber.unsubscribe());
  }
}
