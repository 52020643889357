import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { BaseService } from './base.service';
import { MarketContext } from "@models";

@Injectable()
export class MarketContextService extends BaseService<MarketContext> {
    override path = '/api/v1/markets';

    getAllMarketContexts$(): Observable<MarketContext[]> {
        return this.getList();
    }

    getMarketContext$(contextId: number | string): Observable<MarketContext> {
        return this.getById(contextId);
    }

    createMarketContext$(context: Partial<MarketContext>): Observable<MarketContext> {
        return this.add(context);
    }

    updateMarketContext$(contextId : number | string, context : MarketContext | Partial<MarketContext>): Observable<MarketContext> {
        return this.update(contextId, context);
    }
    deleteMarketContext$(id: number | string): Observable<MarketContext> {
        return this.delete(id);
    }
}

