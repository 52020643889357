import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ComRegistration } from '@models';

@Injectable()
export class ComRegistrationService extends BaseService<ComRegistration> {
    getRegistrations(by: string, id: string): Promise<{data: ComRegistration[]}> {
        return this.httpClient
            .get('/api/v1/registrations', {params: {by, id, status: 'ACTIVE,ON_HOLD,READY_TO_ACCEPTANCE_TEST,READY_TO_ENROLL'}})
            .toPromise()
            .then((res: {data: ComRegistration | ComRegistration[]}) => {
                if (Array.isArray(res.data)) {
                    return {data: res.data as ComRegistration[]};
                }
                return {data: [res.data as ComRegistration]};
            });
    }
}
