import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable} from 'rxjs';
import { BaseService } from './base.service';
import { Group } from "@models";
import { HttpParams } from '@angular/common/http';

@Injectable()
export class GroupService extends BaseService<Group> {
    override path = '/api/v1/groups';

   getAllGroupsBySearch$(params?: HttpParams): Observable<Group[]> {
        return this.getList(params);
    }    
    getAllGroups$(): Observable<Group[]> {
        return this.getList();
    }

    getGroup$(id: number | string): Observable<Group> {
        return this.getById(id);
    }

    createGroup$(context: Partial<Group>): Observable<Group> {
        return this.add(context);
    }

    updateGroup$(id : number | string, context : Group | Partial<Group>): Observable<Group> {
        return this.update(id, context);
    }
    deleteGroup$(id: number | string): Observable<Group> {
        return this.delete(id);
    }

    private openModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private groupList: BehaviorSubject<Group[]> = new BehaviorSubject<Group[]>([]);

    public setOpenModal(value: boolean): void {
        this.openModal.next(value);
    }

    public getOpenModal(): BehaviorSubject<boolean> {
        return this.openModal;
    }

    public setGroupList(value: Group[]): void {
        this.groupList.next(value);
    }

    public getGroupList(): BehaviorSubject<Group[]> {
        return this.groupList;
    }
    
}

