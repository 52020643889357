import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringTruncate',
})
export class StringTruncatePipe implements PipeTransform {
  transform(filename: string): string {
    return filename.length > 40
      ? filename.substring(0, 40).trim() + '...'
      : filename;
  }
}
