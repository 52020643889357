<section>
    <div class="section-header-container">
        <h2>{{ 'vens.common.table.title' | translate }}</h2>
    </div>
    <div class="empty-section" *ngIf="!tableData || tableData.length == 0">
        <div><mat-icon svgIcon="noData"></mat-icon></div>
        <h6>{{ 'vens.common.table.empty' | translate }}</h6>
    </div>
    <div *ngIf="tableData && tableData.length" class="section-main-container">
        <table class="table" [dataSource]="tableData" mat-table>

            <!-- VEN Id Column -->
            <ng-container matColumnDef="ven_id">
                <th class="reg-id-column" mat-header-cell *matHeaderCellDef>
                    {{ 'vens.common.table.header.ven_id' | translate }}
                </th>
                <td class="reg-id-column" mat-cell *matCellDef="let element">
                    <a [routerLink]="['/vens', element.id]">
                        {{ element.ven_id ? element.ven_id : ('vens.common.table.cell.ven_id_undefined' | translate ) }}
                    </a>
                </td>
            </ng-container>

            <!-- VEN Name Column -->
            <ng-container matColumnDef="ven_name">
                <th class="reg-id-column" mat-header-cell *matHeaderCellDef>
                    {{ 'vens.common.table.header.ven_name' | translate }}
                </th>
                <td class="reg-id-column" mat-cell *matCellDef="let element">
                    <span>{{ element.name }}</span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
        </table>
    </div>
</section>
