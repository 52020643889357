<dsy-modal
  title-label="{{'modal.modalTitle' | translate }}"
  subtitle-label="{{'modal.modalSubtitle' | translate}}"
  confirm-button-label="{{'modal.confirm' | translate}}"
  cancel-button-label="{{'modal.cancel' | translate}}"
  custom-header
  z-index=1
  width="650px"
  closes-on-apply
  background-overlay-hidden
  [isOpen]="openModal"
  [cancelButtonInteractionFunction]="handleOpenClose"
  [preCloseButtonInteractionFunction]="handleOpenClose"
  [applyButtonInteractionFunction]="applyModal"
>
<div slot="header">
  <span class="titleModal">  {{ 'modal.modalTitle' | translate }}</span>
  <div class="bodyModal">
    <span class="mr-2 col-11">  {{ 'modal.modalMessage' | translate }} {{type}}. {{'modal.proceed' | translate}}</span>
  </div>
</div>


</dsy-modal>