import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Event, VEN } from '@models';
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'home-view',
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})
export class HomeViewComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];

  eventDataSource: MatTableDataSource<Event> = new MatTableDataSource();;
  venData: VEN[];

  columnsToDisplayVen = ['id', 'groups', 'markets', 'registrations','resources'];

  app_base_url: string = environment.app_base;

  constructor(
    private route: ActivatedRoute
  ) {

  }

  ngOnInit(): void {
    this.subscriptions.push(
        this.route.data.subscribe(data => {
          this.eventDataSource.data = data['events'].slice(0, 5);
          this.venData = data['vens'].slice(0,5);
        })
    );
  }

  parseDate(dateString: string): Date {
    return new Date(dateString);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscriber => subscriber.unsubscribe());
  }

}
