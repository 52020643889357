import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeUnit',
})
export class RemoveUnitPipe implements PipeTransform {
  transform(value: string): string {
    if (value.indexOf('(') < 0) {
      return value;
    }
    const part1 = value.split('(')[0];
    const part2 = value.split(')')[1];
    return `${part1}${part2}`;
  }
}
