import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CookieService } from './cookie.service';

@Injectable()
export class BaseService<T> {
  protected path: string;

  constructor(
    protected httpClient: HttpClient,
    protected cookieService: CookieService,
  ) {}

  private static handleError(error: string): Observable<never> {
    return throwError(() => new Error(`${error}`));
  }

  getPage(params?: HttpParams): Promise<{data: T[], meta: {total: number}}> {
    return this.httpClient
        .get(this.path, {params})
        .toPromise()
        .then((res: {data: any[], meta?: any}) => {
            const data = res.data.map(data => this.mapResponse({data}, null));
            const result = {data, meta: res.meta};
            return result;
        });
  }

  get(
    params?: HttpParams,
    mappingCallback?: (response: unknown) => T,
  ): Observable<T> {
    return this.httpClient
      .get<T>(this.path, { params })
      .pipe(
        map(item => this.mapResponse(item, mappingCallback)),
        catchError(BaseService.handleError),
      );
  }

  getById(
    id: string | number,
    params?: HttpParams,
    mappingCallback?: (response: unknown) => T,
  ): Observable<T> {
    return this.httpClient
      .get<T>(`${this.path}/${id}`, { params })
      .pipe(
        map(item => this.mapResponse(item, mappingCallback)),
        catchError(BaseService.handleError),
      );
  }

  getList(
    params?: HttpParams,
    mappingCallback?: (response: unknown) => T,
  ): Observable<T[]> {
    return this.httpClient
      .get<T[]>(this.path, { params: {...params, per_page: 1000 }})
      .pipe(
        map(items => {
          const data = items['data'];
          if (!mappingCallback) {
            return <T[]>data;
          }
          return <T[]>data.map(p => mappingCallback(p));
        }),
        catchError(BaseService.handleError),
      );
  }

  getPaginated(
    path: string,
    page: number,
    per_page: number,
    params?: HttpParams,
  ): Observable<T[]> {
    const listParams = new HttpParams()
      .set('page', page.toString())
      .set('per_page', per_page.toString());

    return this.httpClient
      .get<T[]>(`${this.path}?${listParams.toString()}`, {
        params,
      })
      .pipe(
        map(list => list['data']),
        catchError(BaseService.handleError),
      );
  }

  add(
    resource: T | Partial<T>,
    params?: HttpParams,
    mappingCallback?: (response: unknown) => T,
  ): Observable<T> {
    return this.httpClient
      .post<T>(this.path, resource, {
        params,
      })
      .pipe(
        map(item => this.mapResponse(item, mappingCallback)),
        catchError(BaseService.handleError),
      );
  }

  update(
    id: string | number,
    resource?: T | Partial<T> | T[] | Partial<T>[],
    params?: HttpParams,
    mappingCallback?: (response: unknown) => T,
  ): Observable<T> {
    return this.httpClient
      .put<T>(`${this.path}/${id}`, resource, {
        params,
      })
      .pipe(
        map(item => this.mapResponse(item, mappingCallback)),
        catchError(BaseService.handleError),
      );
  }

  delete(
    id: string | number,
    params?: HttpParams,
    mappingCallback?: (response: unknown) => T,
  ): Observable<T> {
    return this.httpClient
      .delete<T>(`${this.path}/${id}`, { params })
      .pipe(
        map(item => this.mapResponse(item, mappingCallback)),
        catchError(BaseService.handleError),
      );
  }

  private mapResponse(item, mappingCallback): T {
    const data = item['data'];
    if (!mappingCallback) {
      return data;
    }
    return mappingCallback(data);
  }
}
