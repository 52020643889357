<details-page-header
    content="{{ 'vens.details.title' | translate }}: '{{ ven.name }}'"
    defaultBack="/vens"
    [editRoute]="['/vens', ven.id, 'edit']"
></details-page-header>

<div class="section-main-container flex">
    <div class="w-1/2 mr-2">
        <h2>{{ 'vens.details.settings.title' | translate }}</h2>
        <table class="attributes-table">
            <!--Ven name -->
            <tr>
                <th>{{ 'vens.create.form.ven_name' | translate }}</th>
                <td>{{ ven.name }}</td>
            </tr>

            <!-- Ven ID -->
            <tr>
                <th>{{ 'vens.create.form.ven_id' | translate }}:</th>
                <td>{{ ven.ven_id }}</td>
            </tr>

            <!-- Fingerprint -->
            <tr>
                <th>{{ 'vens.create.form.finger_print' | translate }}:</th>
                <td>{{ ven.fingerprint }}</td>
            </tr>

            <!-- Signal Style -->
            <tr>
                <th>{{ 'vens.create.form.signal_style' | translate }}:</th>
                <td>{{ ven.signal_style }}</td>
            </tr>

            <!-- Archived -->
            <tr>
                <th>{{ 'vens.details.archived' | translate }}:</th>
                <td>{{ !!ven.archived }}</td>
            </tr>
        </table>
    </div>

    <div class="w-1/2 ml-2">
        <h2>{{ 'vens.details.registration.title' | translate }}</h2>
        <table class="attributes-table">
            <!-- Registered -->
            <tr>
                <th>{{ 'vens.create.form.registered' | translate }}:</th>
                <td>{{ !!ven.registration }}</td>
            </tr>
            <tr *ngIf="ven.registration">
                <th>{{ 'vens.details.registration.id' | translate }}:</th>
                <td>{{ ven.registration.registration_id }}</td>
            </tr>
            <tr *ngIf="ven.registration">
                <th>{{ 'vens.details.registration.pollRate' | translate }}:</th>
                <td>{{ ven.registration.poll_rate }}</td>
            </tr>
        </table>
    </div>
</div>

<h1 class="mt-10">{{ 'vens.details.subscriptions.title' | translate }}</h1>

<div class="text-center text-light-color" *ngIf="!ven.resources.length && !ven.groups.length && !ven.markets.length">{{ 'vens.details.subscriptions.empty' | translate }}</div>

<div class="flex flex-col" *ngIf="ven.resources && ven.resources.length">
    <div class="section-header-container">
        <h2>{{ 'vens.details.resource.title' | translate }}</h2>
    </div>
    <resource-table [resources]="ven.resources"></resource-table>
</div>

<div class="flex flex-col" *ngIf="ven.groups && ven.groups.length">
    <div class="section-header-container">
        <h2>{{ 'vens.details.group.title' | translate }}</h2>
    </div>
    <group-table [groups]="ven.groups"></group-table>
</div>

<div class="flex flex-col" *ngIf="ven.markets && ven.markets.length">
    <div class="section-header-container">
        <h2>{{ 'vens.details.market.title' | translate }}</h2>
    </div>
    <table class="table" [dataSource]="ven.markets" mat-table>
        <!-- Market id Column -->
        <ng-container matColumnDef="market_id">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'markets.table.context' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.id && element.id !== null; else dashEmpty">
                    <a [routerLink]="['/market-contexts', element.id]">{{ element.context_url }}</a>
                </ng-container>
                <ng-template #dashEmpty> - </ng-template>
            </td>
        </ng-container>
        <!-- Operator id Column -->
        <ng-container matColumnDef="operator">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'markets.table.operator' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
                <span>
                    <span class="title-bold">{{ 'markets.table.cell.operator_id' | translate }}:</span>
                    {{ element.operator_id ? element.operator_id : '-' }}
                </span>
                <br />
                <span>
                    <span class="title-bold">{{ 'markets.table.cell.operator_name' | translate }}:</span>
                    {{ element.operator_name ? element.operator_name : '-' }}
                </span>
            </td>
        </ng-container>

        <!-- Program Id Column -->
        <ng-container matColumnDef="program">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'markets.table.program' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
                <span>
                    <span class="title-bold">{{ 'markets.table.cell.program_id' | translate }}:</span>
                    {{ element.program_id ? element.program_id : '-' }}
                </span>
                <br />
                <span>
                    <span class="title-bold">{{ 'markets.table.cell.program_name' | translate }}:</span>
                    {{ element.program_name ? element.program_name : '-' }}
                </span>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="marketCols"></tr>
        <tr mat-row *matRowDef="let row; columns: marketCols"></tr>
    </table>
</div>

<div class="flex justify-between items-center mt-10 mb-5">
    <h1>{{ 'vens.details.reports.title' | translate }}</h1>
</div>

<div *ngIf="reports">
    <table class="table" [dataSource]="reports" mat-table>
        <!-- Report Type -->
        <ng-container matColumnDef="report_type">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'reports.edit.form.type' | translate }}
            </th>
            <td mat-cell *matCellDef="let report">
                <ng-container >
                    {{ 'reports.edit.form.type.' + report.type | translate }}
                </ng-container>
            </td>
        </ng-container>

        <!-- Granularity Column -->
        <ng-container matColumnDef="granularity">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'reports.edit.form.granularity' | translate }}
            </th>
            <td mat-cell *matCellDef="let report">
                {{ 'reports.edit.form.granularity.' + GRANULARITIES[report.granularity] | translate }}
            </td>
        </ng-container>

        <!-- Descriptions Column -->
        <ng-container matColumnDef="descriptions_count">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'vens.details.reports.descriptions_count' | translate }}
            </th>
            <td mat-cell *matCellDef="let report">
                {{ report.descriptions.length }}
            </td>
        </ng-container>

        <!-- Start/End -->
        <ng-container matColumnDef="range">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'vens.details.reports.range' | translate }}
            </th>
            <td mat-cell *matCellDef="let report">
                <div>{{ report.start_dttm | date: 'MM/dd/yyyy' }} - {{ report.end_dttm | date: 'MM/dd/yyyy' }}</div>
            </td>
        </ng-container>

        <!-- Requested/Acknowledged -->
        <ng-container matColumnDef="requested_dttm">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'vens.details.reports.requested_dttm' | translate }} ({{ 'vens.details.reports.acknowledged' | translate }})
            </th>
            <td mat-cell *matCellDef="let report">
                <div *ngIf="report.requested_dttm">{{ report.requested_dttm | date: 'MM/dd/yyyy' }}</div>
                <div *ngIf="!report.requested_dttm">
                    {{ 'vens.details.reports.not_requested' | translate }}
                </div>
                <div *ngIf="report.ven_acknowledged_dttm">({{ report.ven_acknowledged_dttm | date: 'MM/dd/yyyy' }})</div>
                <div *ngIf="!report.ven_acknowledged_dttm">
                    ({{ 'vens.details.reports.not_acknowledged' | translate }})
                </div>
            </td>
        </ng-container>

        <!-- Canceled -->
        <ng-container matColumnDef="canceled">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'vens.details.reports.canceled' | translate }} ({{ 'vens.details.reports.cancel_acknowledged' | translate }})
            </th>
            <td mat-cell *matCellDef="let report">
                <div *ngIf="report.canceled_dttm && report.ven_canceled_dttm">
                    {{ report.canceled_dttm | date: 'MM/dd/yyyy' }} ({{ report.ven_canceled_dttm | date: 'MM/dd/yyyy'}})
                </div>
                <div *ngIf="report.canceled_dttm && !report.ven_canceled_dttm">
                    {{ report.canceled_dttm | date: 'MM/dd/yyyy' }} ({{ 'vens.details.reports.cancel_not_acknowledged' | translate }})
                </div>
                <div *ngIf="!report.canceled_dttm">-</div>
            </td>
        </ng-container>

        <!-- Buttons Column -->
        <ng-container matColumnDef="actions" stickyEnd>
            <th class="actions-column" mat-header-cell class="actions-header" *matHeaderCellDef>
                {{ 'vens.table.actions' | translate }}
            </th>
            <td
                class="actions-column"
                mat-cell
                class="actions-cell"
                *matCellDef="let report"
            >
                <div class="btn-container">
                    <button
                        *ngIf="!report.requested_dttm && !report.canceled_dttm"
                        matTooltip="{{ 'actions.edit' | translate }}"
                        class="action-btn"
                        mat-icon-button
                        [routerLink]="['/vens', ven.id, 'reports', report.id, 'edit']"
                    >
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button
                        *ngIf="!report.canceled_dttm"
                        matTooltip="{{ 'actions.cancel' | translate }}"
                        class="action-btn"
                        mat-icon-button
                        (click)="openCancelReportDialog(report)"
                    >
                        <mat-icon>cancel_outline</mat-icon>
                    </button>
                    <button
                        *ngIf="report.canceled_dttm && (!report.ven_acknowledged_dttm || report.ven_canceled_dttm)"
                        matTooltip="{{ 'actions.archive' | translate }}"
                        class="action-btn"
                        mat-icon-button
                        (click)="openArchiveReportDialog(report)"
                    >
                        <mat-icon>delete_outline</mat-icon>
                    </button>

                </div>
            </td>
        </ng-container>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="reportCols.length" style="text-align: center">
                {{ 'vens.details.reports.empty' | translate }}
            </td>
        </tr>

        <tr mat-header-row *matHeaderRowDef="reportCols"></tr>
        <tr mat-row *matRowDef="let row; columns: reportCols"></tr>
    </table>
</div>
