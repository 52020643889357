import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {GlobalAlertService, GroupService} from '@services';

@Injectable()
export class GroupResolver implements Resolve<any> {
    constructor(
        private groupsService: GroupService,
        private globalAlertService: GlobalAlertService,
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const id = route.paramMap.get('groupId') || route.queryParamMap.get('groupId');
        if (id) {
            return this.groupsService.getGroup$(id).pipe(
                catchError((err: unknown) => {
                    this.globalAlertService.setError(err.toString());
                    return throwError(() => new Error(err.toString()));
                }),
            );
        }
        return this.groupsService.getAllGroups$().pipe(
            catchError((err: unknown) => {
                this.globalAlertService.setError(err.toString());
                return throwError(() => new Error(err.toString()));
            }),
        );
    }
}
