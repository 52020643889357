import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Report } from "@models";
import { Observable } from 'rxjs';

@Injectable()
export class ReportService extends BaseService<Report> {
    override path = '/api/v1/reports';


    createReport$(report: Partial<Report>): Observable<Report> {
        return this.add(report);
      }

    getReportById$(id: number | string): Observable<Report> {
      return this.getById(id);
    }
    editReportById$(id: number | string, report: Partial<Report>): Observable<Report> {
      return this.update(id, report);
    }
}
