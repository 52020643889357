<div class="section-header-container">
    <div class="flex-col">
        <h1 class="section-header">{{ 'resources.create.header' | translate }}</h1>
    </div>
    <div class="right-column">
        <button
            class="icon-btn x-btn-secondary"
            aria-label="Cancel"
            (click)="handleEventCancel()"
            data-automation="resourceCancel"
        >
            <mat-icon>clear</mat-icon>
            <span class="btn-text">{{ 'actions.cancel' | translate }}</span>
        </button>

        <button
            class="icon-btn x-btn-primary"
            aria-label="Submit"
            [disabled]="resourceForm.invalid"
            (click)="openCancelOrAcceptDialog()"
            type="submit"
            data-automation="resourceSubmit"
        >
            <mat-icon>done</mat-icon>
            <div *ngIf="submitting" class="btn-spinner">
                <mat-spinner diameter="15"></mat-spinner>
            </div>
            <span class="btn-text">{{ 'actions.submit' | translate }}</span>
        </button>
    </div>
</div>
<form #createResource="ngForm" id="resourceForm" [formGroup]="resourceForm">
    <div class="form-wrapper flex flex-wrap form-group">
        <div class="form-field-wrapper">
            <div>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label class="text-light-color"
                        >{{ 'resources.create.form.registration_name' | translate }}:</mat-label
                    >
                    <input
                        matInput
                        required
                        formControlName="registration_name"
                        data-automation="registration_name"
                        placeholder="{{ 'resources.create.form.placeholder.registration_name' | translate }}"
                    />
                    <mat-error *ngIf="resourceForm.get('registration_name').hasError('required')">
                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="form-field-wrapper">
            <div>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label class="text-light-color"
                        >{{ 'resources.create.form.fdr_registration_id' | translate }}:</mat-label
                    >
                    <input
                        matInput
                        id="registration_id"
                        name="registration_id"
                        required
                        [pattern]="id_validation"
                        formControlName="registration_id"
                        data-automation="registration_id"
                        placeholder="{{ 'resources.create.form.placeholder.fdr_registration_id' | translate }}"
                    />
                    <mat-error *ngIf="resourceForm.get('registration_id').hasError('required')">
                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                    <mat-error *ngIf="resourceForm.get('registration_id').hasError('pattern')">
                        <span class="input-entry">{{ 'common.validation.uuid' | translate }}</span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <!-- Resource Type -->
        <div class="form-field-wrapper">
            <div>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label class="text-light-color">
                        <span>{{ 'resources.create.form.resource_type' | translate }}</span>
                    </mat-label>
                    <mat-select
                        fieldToUpdate="type"
                        formControlName="type"
                        data-automation="resource_type"
                        placeholder="{{ 'resources.create.form.resource_type' | translate }}"
                        (selectionChange)="changeType()"
                        style="display: flex; align-items: center; height: 36px"
                    >
                        <mat-option *ngFor="let type of TYPES" [value]="type">
                            {{ 'resources.create.form.resource_types.' + type | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="flex flex-wrap" *ngIf="resource.type === 'none'">
        <div class="form-field-wrapper">
            <div>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label class="text-light-color">{{ 'resources.create.form.resource' | translate }}:</mat-label>
                    <input
                        matInput
                        id="resource_id"
                        name="resource_id"
                        required
                        formControlName="resource_id"
                        data-automation="resource_id"
                        placeholder="{{ 'resources.create.form.placeholder.resource' | translate }}"
                    />
                    <mat-error *ngIf="resourceForm.get('resource_id').hasError('required')">
                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                    <mat-error *ngIf="resourceForm.get('resource_id').hasError('pattern')">
                        <span class="input-entry">{{ 'common.validation.uuid' | translate }}</span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="flex flex-wrap" *ngIf="resource.type === 'storage'">
        <!-- DER.OS Program ID -->
        <div class="form-field-wrapper">
            <div class="flex">
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label class="text-light-color"><span matTooltip="{{ 'resources.create.form.der_os_program_id_help' | translate }}">{{ 'resources.create.form.der_os_program_id' | translate }}:</span></mat-label>
                    <input
                        matInput
                        id="derOsProgramId"
                        name="derOsProgramId"
                        required
                        formControlName="der_os_program_id"
                        data-automation="derOsProgramId"
                    />
                    <mat-error *ngIf="resourceForm.get('der_os_program_id').hasError('required')">
                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                    <mat-error *ngIf="resourceForm.get('der_os_program_id').hasError('pattern')">
                        <span class="input-entry">{{ 'common.validation.uuid' | translate }}</span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <!-- DER.OS Site IDs -->
        <div class="form-field-wrapper">
            <div class="flex">
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label class="text-light-color"><span matTooltip="{{ 'resources.create.form.der_os_site_ids_help' | translate }}">{{ 'resources.create.form.der_os_site_ids' | translate }}:</span></mat-label>
                    <input
                        matInput
                        id="derOsSiteIds"
                        name="derOsSiteIds"
                        required
                        formControlName="der_os_site_ids"
                        data-automation="derOsSiteIds"
                    />
                    <mat-error *ngIf="resourceForm.get('der_os_site_ids').hasError('required')">
                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                    <mat-error *ngIf="resourceForm.get('der_os_site_ids').hasError('pattern')">
                        <span class="input-entry">{{ 'common.validation.uuid' | translate }}</span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
</form>
