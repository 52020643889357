import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { from, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Event } from '@models';
import { GlobalAlertService, EventService } from '@services';

@Injectable()

export class EventResolver implements Resolve<Event[]> {
    constructor(
        private eventService: EventService,
        private globalAlertService: GlobalAlertService,
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const eventId = route.paramMap.get('eventId') || route.queryParamMap.get('eventId');
        if (eventId) {
            return this.eventService.getEvent$(eventId).pipe(
                catchError((err: unknown) => {
                    this.globalAlertService.setError(err.toString());
                    return throwError(() => new Error(err.toString()));
                }),
            );
        }

        return from(this.eventService.getPage()
            .then(res => res.data)
            .catch((err: unknown) => {
                this.globalAlertService.setError(err.toString());
                return throwError(() => new Error(err.toString()));
            })
        );
    }
}
