<div class="section-header-container">
    <div class="flex">
        <mat-icon
            *ngIf="back"
            class="back-btn"
            [color]="'primary'"
            (click)="back()"
        > arrow_back_ios_new </mat-icon>
        <h1 class="section-header">{{ content }}</h1>
    </div>
    <button
        *ngIf="editRoute"
        class="icon-btn x-btn-primary"
        [routerLink]="editRoute"
        color="accent"
    >
        <mat-icon>edit</mat-icon>
        <span>{{ 'actions.edit' | translate }}</span>
    </button>
</div>

