import { DOCUMENT } from '@angular/common';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  LoaderService,
  LoginRedirectService,
} from '@services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private loginRedirect: LoginRedirectService,
    private loaderService: LoaderService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map(event => event),
      catchError((err: unknown) => {
        // this.globalAlertService.setError(err.message);
        this.loaderService.terminate();
        if (!(err instanceof HttpErrorResponse)) {
          return throwError(() => new Error(err.toString()));
        }
        switch (err.status) {
          case 401:
            this.loginRedirect.loadLoginPage();
            break;
          // case 403:
          //   this.redirectToForbidden();
          //   break;
        }

        return throwError(() => new Error(err.error?.detail ?? err.message));
      }),
    );
  }

  redirectToForbidden(): void {
    const forbiddenPath = 'forbidden/index.html';
    this.document.location.href = `${document.location.origin}/${forbiddenPath}?type=forbidden`;
  }
}
