import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MarketContext } from '@models';

@Component({
  selector: 'event-markets-display',
  templateUrl: './index.html',
  styleUrls: ['./style.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class EventMarketDisplayComponent implements OnInit, OnDestroy {
  panelOpenedState: boolean = true;
  subscriptions: Subscription[] = [];

  private _market: MarketContext;
  private _index: number;

  @Input()
  set market(market: MarketContext) {
    this._market = market;
  }

  get market() {
    return this._market;
  }

  @Input()
  set index(index: number) {
    this._index = index;
  }

  get index() {
    return this._index;
  }

  constructor() {
    this.panelOpenedState = false;
  }

  async ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
