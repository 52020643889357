import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Locale } from '@models';

@Injectable()
export class LocalesService extends BaseService<Locale> {
  override path = '/api/v1/locales';
  locales: Locale[] = [];

  setLocales(): Promise<Locale[]> {
    const cached = localStorage.getItem('locales');
    if (cached) {
        this.locales.push(...JSON.parse(cached));
        return Promise.resolve(this.locales);
    }

    return this.getList()
      .toPromise()
      .then((locales) => {
          this.locales.push(...locales);
          localStorage.setItem('locales', JSON.stringify(locales));
          return this.locales;
      });
  }

  checkForLocale(locale_key: string): Locale {
    return this.locales.find(l => l.locale_name === locale_key);
  }
}
