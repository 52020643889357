import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { VEN } from '@models';

@Injectable()
export class VenService extends BaseService<VEN> {
  override path = '/api/v1/vens';

  getAllVens$(): Observable<VEN[]> {
    return this.getList();
  }

  getVen$(id: number | string): Observable<VEN> {
    return this.getById(id);
  }

  createVen$(resource: Partial<VEN>): Observable<VEN> {
    return this.add(resource);
  }

  updateVen$(id: number | string, context: VEN | Partial<VEN>): Observable<VEN> {
    return this.update(id, context);
  }

  deleteVen$(id: number | string): Observable<VEN> {
    return this.delete(id);
  }
}
