<details-page-header
    content="{{ 'events.details.title' | translate }}: '{{ event.event_id }}'"
    defaultBack="/events"
></details-page-header>
<div class="section-main-container" *ngIf="!noEvent">
    <section class="flex flex-wrap justify-between">
        <!-- Event ID -->
        <div class="flex flex-col w-1/3 mb-10">
            <div class="flex items-start">
                <span class="text-light-color text-bold">{{ 'events.table.flex_id' | translate }}:</span>
            </div>
            <div class="flex">
                <a href="//event-management.{{app_base_url}}/event/{{event.event_id}}">
                    {{ event.event_id }}
                </a>
            </div>
        </div>

        <!-- Event startTime -->
        <div class="flex flex-col w-1/3 mb-10">
            <div class="flex items-start">
                <span class="text-light-color text-bold">{{ 'events.table.start' | translate }}:</span>
            </div>
            <div class="flex">
                {{ event.start_dttm }}
            </div>
        </div>

        <!-- Event endTime -->
        <div class="flex flex-col w-1/3 mb-10">
            <div class="flex items-start">
                <span class="text-light-color text-bold">{{ 'events.table.end' | translate }}:</span>
            </div>
            <div class="flex">
                {{ event.end_dttm }}
            </div>
        </div>

        <!-- Event Market -->
        <div class="flex flex-col w-1/3 mb-10">
            <div class="flex items-start">
                <span class="text-light-color text-bold">{{ 'events.table.market_context' | translate }}:</span>
            </div>
            <div class="flex">
                {{ event.market.context_url }}
            </div>
        </div>

        <!-- Event archived -->
        <div class="flex flex-col w-1/3 mb-10">
            <div class="flex items-start">
                <span class="text-light-color text-bold">{{ 'events.archived' | translate }}:</span>
            </div>
            <div class="flex">
                {{ event.archived }}
            </div>
        </div>

        <!-- Event Cancelled -->
        <div class="flex flex-col w-1/3 mb-10">
            <div class="flex items-start">
                <span class="text-light-color text-bold">{{ 'events.cancelled' | translate }}:</span>
            </div>
            <div class="flex">
                {{ event.cancelled }}
            </div>
        </div>

        <!-- Event testEvent -->
        <div class="flex flex-col w-1/3 mb-10">
            <div class="flex items-start">
                <span class="text-light-color text-bold">{{ 'events.testEvent' | translate }}:</span>
            </div>
            <div class="flex">
                {{ event.test_event }}
            </div>
        </div>
    </section>

    <!-- Participation Plans-->
    <section>
        <div class="section-header-container">
            <h2>{{ 'events.details.participating_vens' | translate }}</h2>
        </div>

        <participation-plan-display
            *ngFor="let plan of event.participation_plans" [plan]="plan"
        ></participation-plan-display>

        <div *ngIf="event.participation_plans.length === 0">
            {{ 'events.details.plans.empty' | translate }}
        </div>
    </section>

    <!-- Markets -->
    <section>
        <div class="section-header-container">
            <h2>{{ 'events.table.market_context' | translate }}</h2>
        </div>
        <div *ngIf="hasMarket" class="section-main-container">
            <event-markets-display [market]="event.market"> </event-markets-display>
        </div>
        <div class="empty-section" *ngIf="!hasMarket">
            <div><mat-icon svgIcon="noData"></mat-icon></div>
            <h6>{{ 'events.details.markets.empty' | translate }}</h6>
            <div data-automation="empty-points" class="empty-filler">
                {{ 'events.details.markets.empty_filler' | translate }}
            </div>
        </div>
    </section>
</div>
