import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable()
export class LoginRedirectService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) { }

  loadLoginPage(): void {
     // Redirect to the OpenAM login screen
     const current = window.location.href;
     const qs = new URLSearchParams({goto: current, realm: 'enelx'});
     this.document.location.href = `${environment.login_url}?${qs.toString()}`;
  }
}
