<div class="inline-wrapper">
    <div class="flex justify-start items-center" *ngIf="!isEditing">
        <mat-checkbox *ngIf="isBoolean()" [formControl]="formControl" (change)="editingEndCheckbox()"></mat-checkbox>

        <div class="edit-wrapper flex" *ngIf="!isBoolean()">
            <div class="field-wrapper-container" [innerHTML]="innerHtml"></div>
            <mat-icon class="icon" *ngIf="!disableEdit" (click)="isEditing = !isEditing">edit</mat-icon>
        </div>
    </div>

    <div *ngIf="isEditing">
        <mat-form-field [ngSwitch]="inputType" appearance="outline">
            <input
                matInput
                *ngSwitchCase="'input'"
                [formControl]="formControl"
                (focusout)="editingEnd(formControl.value)"
                (keyup.enter)="editingEnd(formControl.value)"
                (keyup.escape)="escWithoutSaving()"
                placeholder="Enter {{ label }}"
                [required]="required"
                #inlineInput
            />

            <input
                matInput
                *ngSwitchCase="'number'"
                type="number"
                step="1"
                [formControl]="formControl"
                (focusout)="editingEnd(formControl.value)"
                (keyup.enter)="editingEnd(formControl.value)"
                (keyup.escape)="escWithoutSaving()"
                placeholder="Enter {{ label }}"
                [required]="required"
                #inlineInput
            />

            <input
                matInput
                *ngSwitchCase="'date'"
                type="datetime-local"
                [formControl]="formControl"
                (blur)="editingEnd(formControl.value)"
                (focusout)="editingEnd(formControl.value)"
                (keyup.enter)="editingEnd(formControl.value)"
                (keyup.escape)="escWithoutSaving()"
                placeholder="Enter {{ label }}"
                [required]="required"
                #inlineInput
            />

            <mat-select
                #inlineInput
                *ngSwitchCase="'select'"
                [formControl]="formControl"
                (selectionChange)="editingEnd(selectDisplayValue($event.value))"
                (keyup.escape)="escWithoutSaving()"
                (closed)="escWithoutSaving()"
                placeholder="{{ label }}"
                [required]="required"
            >
                <mat-option
                    *ngFor="let value of selectArray"
                    [value]="value[selectValueField]"
                    id="{{ value[selectIdField] }}"
                    >{{ value[selectDisplayField] }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-error *ngIf="formControl.hasError('required')">
            {{ 'common.validation.required' | translate }}
        </mat-error>
        <mat-error *ngIf="formControl.hasError('invalidDate')">
            {{ 'common.validation.start_end_date' | translate }}
        </mat-error>
        <mat-error *ngIf="formControl.hasError('pattern')">
            {{ 'common.validation.uuid' | translate }}
        </mat-error>
    </div>
</div>
<mat-spinner class="spinner" *ngIf="isLoading" diameter="30" color="primary"></mat-spinner>
