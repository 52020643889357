import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable} from 'rxjs';
import { BaseService } from './base.service';
import { MarketContext } from "@models";
import { HttpParams } from '@angular/common/http';

@Injectable()
export class MarketContextService extends BaseService<MarketContext> {
    override path = '/api/v1/markets';

    getAllMarketContexts$(): Observable<MarketContext[]> {
        return this.getList();
    }

   getAllMarketsBySearch$(params?: HttpParams): Observable<MarketContext[]> {
        return this.getList(params);
    }       

    getMarketContext$(contextId: number | string): Observable<MarketContext> {
        return this.getById(contextId);
    }

    createMarketContext$(context: Partial<MarketContext>): Observable<MarketContext> {
        return this.add(context);
    }

    updateMarketContext$(contextId : number | string, context : MarketContext | Partial<MarketContext>): Observable<MarketContext> {
        return this.update(contextId, context);
    }
    deleteMarketContext$(id: number | string): Observable<MarketContext> {
        return this.delete(id);
    }
    
    private openModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private groupList: BehaviorSubject<MarketContext[]> = new BehaviorSubject<MarketContext[]>([]);
  
    public setOpenModal(value: boolean): void {
      this.openModal.next(value);
    }
  
    public getOpenModal(): BehaviorSubject<boolean> {
      return this.openModal;
    }
  
    public setMarketList(value: MarketContext[]): void {
      this.groupList.next(value);
    }
  
    public getMarketList(): BehaviorSubject<MarketContext[]> {
      return this.groupList;
    }
}

