import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EventListViewComponent } from './index';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { EventTableModule } from '@components';

@NgModule({
    declarations: [EventListViewComponent],
    exports: [EventListViewComponent],
    imports: [
        CommonModule,
        EventTableModule,
        TranslateModule,
        MatTableModule,
        RouterModule,
        MatIconModule,
        MatSortModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatPaginatorModule,
        MatChipsModule,
        FormsModule,
        MatTooltipModule,
        MatCheckboxModule,
    ],
})
export class EventListViewModule {}
