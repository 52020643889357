import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  readonly forbiddenPath = '/forbidden/index.html';

  constructor() {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return true;
    // const { requiredPermissions } = route.data;
    // const hasPermissions = this.userService.hasPermission(requiredPermissions);

    // if (!hasPermissions) {
    //   document.location.href = document.location.origin + this.forbiddenPath;
    //   return false;
    // } else {
    //   return true;
    // }
  }
}
