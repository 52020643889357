import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { from, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalAlertService, VenService } from '@services';

@Injectable()

export class VenResolver implements Resolve<any> {
    constructor(
        private venService: VenService,
        private globalAlertService: GlobalAlertService,
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const contextId = route.paramMap.get('venId') || route.queryParamMap.get('venId');
        if (contextId) {
            return this.venService.getVen$(contextId).pipe(
                catchError((err: unknown) => {
                    this.globalAlertService.setError(err.toString());
                    return throwError(() => new Error(err.toString()));
                }),
            );
        }
        return from(this.venService.getPage()
            .then(res => res.data)
            .catch((err: unknown) => {
                this.globalAlertService.setError(err.toString());
                return throwError(() => new Error(err.toString()));
            }));
    }
}

