<details-page-header
    content="{{ 'resources.edit.title' | translate }}: '{{ resource.resource_id }}'"
    defaultBack="/resources"
></details-page-header>
<div class="section-main-container" *ngIf="!noResource">
    <form #resourceForm="ngForm" id="resourceForm">
        <section class="flex flex-wrap">
            <!-- Registration Name -->
            <div class="form-field-wrapper">
                <div class="flex items-start">
                    <span class="text-light-color">{{ 'resources.create.form.registration_name' | translate }}:</span>
                </div>
                <div class="flex">
                    <leviathan-jr-inline-edit
                        ngDefaultControl
                        htmlTag="span"
                        [inputType]="'input'"
                        [fieldToUpdate]="'registration_name'"
                        [(formControl)]="fieldControls['registration_name']"
                        [label]="'resources.placeholder.fdr_registration_name' | translate"
                        [callback]="handleEdit"
                    >
                    </leviathan-jr-inline-edit>
                </div>
            </div>

            <!-- Registration ID -->
            <div class="form-field-wrapper">
                <div class="flex items-start">
                    <span class="text-light-color">{{ 'resources.create.form.fdr_registration_id' | translate }}:</span>
                </div>
                <div class="flex">
                    <leviathan-jr-inline-edit
                        ngDefaultControl
                        htmlTag="span"
                        [inputType]="'input'"
                        [fieldToUpdate]="'registration_id'"
                        [(formControl)]="fieldControls['registration_id']"
                        [label]="'resources.placeholder.fdr_registration_id' | translate"
                        [pattern]="id_validation"
                        [callback]="handleEdit"
                    >
                    </leviathan-jr-inline-edit>
                </div>
            </div>

            <!-- Resource Type -->
            <div class="form-field-wrapper">
                <div class="flex items-start">
                    <span class="text-light-color" matTooltip="{{ 'resources.create.form.resource_type_help' | translate }}">{{ 'resources.create.form.resource_type' | translate }}:</span>
                </div>
                <div class="flex">
                    <mat-select
                        fieldToUpdate="type"
                        [(formControl)]="fieldControls['type']"
                        [label]="'resources.create.form.resource_type' | translate"
                        data-automation="resource_type"
                        (selectionChange)="changeType()"
                    >
                        <mat-option *ngFor="let type of TYPES" [value]="type">
                            {{ 'resources.create.form.resource_types.' + type | translate }}
                        </mat-option>
                    </mat-select>

                </div>
            </div>
        </section>

        <section class="flex flex-wrap" *ngIf="fieldControls['type'].value === 'none'">
            <!-- Resource ID -->
            <div class="form-field-wrapper">
                <div class="flex items-start">
                    <span class="text-light-color">{{ 'resources.create.form.resource' | translate }}:</span>
                </div>
                <div class="flex">
                    <leviathan-jr-inline-edit
                        ngDefaultControl
                        htmlTag="span"
                        inputType="input"
                        [fieldToUpdate]="'resource_id'"
                        [(formControl)]="fieldControls['resource_id']"
                        [label]="'resources.placeholder.resource_id' | translate"
                        [callback]="handleEdit"
                    >
                    </leviathan-jr-inline-edit>
                </div>
            </div>
        </section>

        <section class="flex flex-wrap" *ngIf="fieldControls['type'].value === 'storage'">
            <!-- DER.OS Program ID -->
            <div class="form-field-wrapper">
                <div class="flex items-start">
                    <span class="text-light-color" matTooltip="{{ 'resources.create.form.der_os_program_id_help' | translate }}">{{ 'resources.create.form.der_os_program_id' | translate }}:</span>
                </div>
                <div class="flex">
                    <leviathan-jr-inline-edit
                        ngDefaultControl
                        htmlTag="span"
                        inputType="input"
                        fieldToUpdate="resource_id"
                        [(formControl)]="der_os_program_id"
                        [label]="'resources.create.form.der_os_program_id' | translate"
                        [callback]="handleEdit"
                    >
                    </leviathan-jr-inline-edit>
                </div>
            </div>

            <!-- DER.OS Site IDs -->
            <div class="form-field-wrapper">
                <div class="flex items-start">
                    <span class="text-light-color" matTooltip="{{ 'resources.create.form.der_os_site_ids_help' | translate }}">{{ 'resources.create.form.der_os_site_ids' | translate }}:</span>
                </div>
                <div class="flex">
                    <leviathan-jr-inline-edit
                        ngDefaultControl
                        htmlTag="span"
                        inputType="input"
                        fieldToUpdate="resource_id"
                        [(formControl)]="der_os_site_ids"
                        [label]="'resources.create.form.der_os_site_ids' | translate"
                        [callback]="handleEdit"
                    >
                    </leviathan-jr-inline-edit>
                </div>
            </div>
        </section>

        <section class="flex flex-wrap">
            <!-- Resource archived -->
            <div class="form-field-wrapper">
                <div class="flex items-start">
                    <span class="text-light-color">{{ 'resources.create.form.archived' | translate }}:</span>
                </div>
                <div class="flex">
                    <leviathan-jr-inline-edit
                        ngDefaultControl
                        htmlTag="span"
                        [inputType]="'checkbox'"
                        [fieldToUpdate]="'archived'"
                        [(formControl)]="fieldControls['archived']"
                        [label]="resource.archived.toString()"
                        [callback]="handleEdit"
                    >
                    </leviathan-jr-inline-edit>
                </div>
            </div>
        </section>
    </form>
</div>

<leviathan-jr-vens-table
    [tableData]="resource.vens"
></leviathan-jr-vens-table>
