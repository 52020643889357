import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ResourceTableComponent } from './index';
import { MatTableModule } from '@angular/material/table';

@NgModule({
    declarations: [ResourceTableComponent],
    exports: [ResourceTableComponent],
    imports: [
        CommonModule,
        TranslateModule,
        MatTableModule,
        RouterModule,
    ],
})
export class ResourceTableModule {}
