import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { UnverifiedVen } from '@models';

@Injectable()
export class UnverifiedVenService extends BaseService<UnverifiedVen> {
  override path = '/api/v1/unverified-vens';

  getAllUnverifiedVens$(): Observable<UnverifiedVen[]> {
    return this.getList();
  }

  getUnverifiedVen$(id: number | string): Observable<UnverifiedVen> {
    return this.getById(id);
  }

  createUnverifiedVen$(resource: Partial<UnverifiedVen>): Observable<UnverifiedVen> {
    return this.add(resource);
  }

  deleteUnverifiedVen$(id: number | string): Observable<UnverifiedVen> {
    return this.delete(id);
  }
  promoteUnverifiedVen$(id: string): Observable<UnverifiedVen> {
    return this.update(id);
  }
}
