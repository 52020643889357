export interface Timezone {
  id: string;
  display_label: string;
  timezone_name: string;
}

export class Timezone {
  constructor() {
    this.id = '26';
    this.display_label = '(GMT-05:00) Eastern Time (US & Canada)';
    this.timezone_name = 'America/New_York';
  }
}
