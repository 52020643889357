import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseService } from '@services';

export interface ExportModalData {
    name: string;
    service: BaseService<any>;
    headers: string[];
    mapper: (any) => string[];
    params: {[k: string]: number | boolean | string};
}

@Component({
    selector: 'export-modal',
    template: `
        <h1 mat-dialog-title>{{ data.name }} CSV Export</h1>
        <div mat-dialog-content class="mt-5">
          <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
        </div>
        <div class="flex justify-between mt-5" mat-dialog-actions>
          <button class="btn btn-tertiary" (click)="onNoClick()">Close</button>
          <button class="btn btn-primary" [disabled]="progress < 100" (click)="download()" cdkFocusInitial>Download</button>
        </div>
    `,
})
export class ExportModalComponent {
    rows: any[];
    locale: string;
    progress: number;
    complete: boolean;

    constructor(
        public dialogRef: MatDialogRef<ExportModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ExportModalData,
    ) {}

    ngOnInit() {
        const params = {...this.data.params, page: 1, per_page: 100};
        this.progress = 0;
        this.complete = false;
        this.rows = [this.data.headers];
        this.loadData(params);
    }

    loadData(params) {
        this.data.service
            .getPage(params)
            .then(({data, meta}) => {
                if (this.complete) return;

                this.progress = Math.min((params.page * params.per_page) / meta.total, 1) * 100;
                if (this.progress < 100) {
                    this.loadData({...params, page: params.page + 1});
                }

                this.rows.push(...data.map(obj => this.data.mapper(obj)));

                if (this.progress === 100) {
                    this.complete = true;
                }
            });
    }

    download() {
        const csvContent = this.rows.join('\r\n');
        const name = this.data.name.toLowerCase().replace(/\s+/, '-');

        // Turn csv content string into a download file
        const a = document.createElement('a');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `ljr-${name}-export-${Date.now()}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.onNoClick();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.complete = true;
    }
}

