import { Component, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Event } from '@models';
import { GlobalAlertService } from '@services';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'event-details-view',
  templateUrl: './index.html',
  styleUrls: ['./style.scss'],
})
export class EventDetailsViewComponent implements OnInit, OnDestroy {
  PROD: boolean = false;
  NO_EVENT = 'Oops, the Event you requested was not found';

  noEvent: boolean = false;

  subscriptions: Subscription[] = [];
  event: Event;

  app_base_url: string = environment.app_base;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private messageService: GlobalAlertService,
    private router: Router,
  ) {
    this.PROD = isDevMode() ? false : true;
    this.NO_EVENT = this.translateService.instant('events.details.notification.no_event');

    this.subscriptions.push(
      this.route.data.subscribe((data) => {
        if (Object.keys(data['data']).length === 0) {
          this.messageService.setError(this.NO_EVENT);
          this.noEvent = true;
          this.router.navigate([`/events`]);
        }
        this.event = data['data'];
      }),
    );
  }

  get hasMarket() {
    return Boolean(this.event.market);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscriber) => subscriber.unsubscribe());
  }
}
