import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'leviathan-jr-inline-edit',
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.scss'],
})
export class InlineEditComponent<T> implements OnInit, OnChanges {
  shouldUpdate$ = new Subject<boolean>();
  @Input() htmlTag: 'h1' | 'span' | 'div' | 'a';
  @Input() inputType: 'input' | 'checkbox' | 'textarea' | 'select' | 'number' | 'date';
  @Input() required: boolean = true;
  @Input() formControl: UntypedFormControl;
  @Input() label: string;
  @Input() fieldToUpdate: string;
  @Input() validations: any;
  @Input() disableEdit: boolean = false;

  @Input() selectArray?: T[];
  @Input() selectDisplayField?: keyof T;
  @Input() selectValueField?: keyof T;
  @Input() selectIdField?: keyof T;

  @Input() link?: string;

  @Input() callback: (formControl: UntypedFormControl, fieldToUpdate: string, shouldUpdate$) => void;

  @Output() formControlChange = new EventEmitter<UntypedFormControl>();

  @ViewChild('inlineInput', { static: false })
  set inlineInput(element: ElementRef<HTMLInputElement> | any) {
    if (element) {
      if (this.inputType === 'select') {
        element.open();
      } else {
        element.nativeElement.focus();
      }
      this.cdr.detectChanges();
    }
  }

  isEditing = false;
  isLoading = false;
  innerHtml: string;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.disableEdit) {
      this.formControl.disable();
    }
    this.refreshInnerHtml(this.selectDisplayValue(this.formControl.value));
  }

  ngOnChanges(): void {
    this.refreshInnerHtml(this.selectDisplayValue(this.formControl.value));
  }

  editingEnd(): void {
    if (!this.formControl.valid) return;
    if (!this.formControl.dirty) {
      this.escWithoutSaving();
      return;
    }
    this.isLoading = true;
    this.callback(this.formControl, this.fieldToUpdate, this.shouldUpdate$);
    this.shouldUpdate$.subscribe((value) => {
      if (value) {
        this.formControlChange.emit(this.formControl);
        this.refreshInnerHtml(this.formControl.value);
      }
      this.isEditing = false;
      this.isLoading = false;
    });
  }

  editingEndCheckbox() {
    this.isLoading = true;
    this.callback(this.formControl, this.fieldToUpdate, this.shouldUpdate$);
    this.shouldUpdate$.subscribe(() => {
      this.isLoading = false;
    });
  }

  escWithoutSaving(): void {
    this.isEditing = false;
  }

  refreshInnerHtml(label: string): void {
    if (label == null || label.toString().trim().length === 0) {
      label = '-';
    }
    if (this.htmlTag == 'a') {
      this.innerHtml = `<a href="${this.link}">${label}</a><${this.htmlTag}>${label}</${this.htmlTag}>`;
    } else {
      this.innerHtml = `<${this.htmlTag}>${label}</${this.htmlTag}>`;
    }
  }

  selectDisplayValue(value: string): string {
    if (this.inputType === 'select') {
      if (this.selectArray.length < 1 || value == '' || value == null) {
        return '-';
      }
      let t = this.selectArray.find((c) => {
        return c[this.selectValueField.valueOf()] == value[this.selectValueField.valueOf()];
      });
      if (!t) {
        t = this.selectArray[0];
      }
      return t[this.selectDisplayField.valueOf()];
    } else {
      return value;
    }
  }

  isBoolean() {
    return this.inputType === 'checkbox';
  }

  hasValidations() {
    return this.validations && this.validations.length > 0;
  }

  getValidation(type: string) {
    const foundValidation = this.validations.find((validation) => validation.operator === type);
    return foundValidation.value || '';
  }
}
