import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Group, MarketContext, Resource, VEN } from '@models';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '@components';
import { GlobalAlertService, VenService } from '@services';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'ven-edit-view',
  templateUrl: './index.html',
  styleUrls: ['./style.scss'],
})
export class VenEditViewComponent implements OnInit, OnDestroy {
  VEN_SUCCESS = 'Ven was saved successfully';
  SIGNAL_STYLES: string[] = ['reduceBy', 'reduceTo'];
  SUBSCRIPTION_STYLES: string[] = ['resources', 'groups', 'markets'];

  resources: Resource[] = [];
  groups: Group[] = [];
  markets: MarketContext[] = [];
  subscriptions: Subscription[] = [];
  subscriptionStyle;
  ven: Partial<VEN>;

  venForm: UntypedFormGroup;
  submitting: boolean = false;

  constructor(
    private translateService: TranslateService,
    public dialog: MatDialog,
    private venService: VenService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: GlobalAlertService,
    // private resourceService: ResourceService,
    // private groupService: GroupService,
    // private marketContextService: MarketContextService,
  ) {
    this.VEN_SUCCESS = this.translateService.instant('vens.create.form.notification.success');
  }

  ngOnInit(): void {
    this.subscriptions.push(
        this.route.data.subscribe((res) => {
            this.ven = (res || {})['data'] || {fingerprint: '', signal_style: 'reduceBy'};
            if (this.ven.resources?.length) {
                this.subscriptionStyle = 'resources';
            } else if (this.ven.groups?.length) {
                this.subscriptionStyle = 'groups';
            } else if (this.ven.markets?.length) {
                this.subscriptionStyle = 'markets';
            } else {
                this.subscriptionStyle = 'resources';
            }
            this.makeForm();
        })
    );
  }

  makeForm() {
    this.venForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.ven.name, Validators.required),
      ven_id: new UntypedFormControl(this.ven.ven_id, Validators.required),
      fingerprint: new UntypedFormControl(this.ven.fingerprint),
      signal_style: new UntypedFormControl(this.ven.signal_style, Validators.required),
      opt_out_enabled: new UntypedFormControl(this.ven.opt_out_enabled ?? true),
      archived: new UntypedFormControl(this.ven.archived ?? false),
    });
  }

  changeSubscriptionStyle() {
    this.ven.resources = [];
    this.ven.groups = [];
    this.ven.markets = [];
  }

  handleVenCancel() {
    this.submitting = false;
    if (this.ven.id) {
        this.router.navigate([`/vens`, this.ven.id]);
    } else {
        this.router.navigate([`/vens`]);
    }
  }

  save() {
    this.submitting = true;
    try {
      // Assemble payload
      const payload = {...this.ven, ...this.venForm.getRawValue()};
      for (const type of ['resources', 'groups', 'markets']) {
        payload[type] = (payload[type] || []).map(s => ({id: s.id}));
      }

      let venCall;
      if (this.ven.id) {
          venCall = this.venService.update(this.ven.id, payload);
      } else {
        venCall = this.venService.createVen$(payload);
      }
      const venSub = venCall.subscribe({
        next: (ven) => {
          this.ngZone.run(() => {
            this.messageService.setSuccess(this.VEN_SUCCESS);
            this.router.navigateByUrl(`vens/${ven.id}`);
          });
        },
        error: (err) => {
          console.log(err);
        },
      });
      this.subscriptions.push(venSub);
    } finally {
      this.submitting = false;
      this.dialog.closeAll();
    }
  }

  updateRegistration(registration: any, remove?: boolean) {
    if (remove) {
      this.ven.registration = null;
    } else {
      this.ven.registration = registration;
    }
  }

  openCancelOrAcceptDialog() {
    this.dialog.open(DialogComponent, {
      width: '531px',
      data: {
        title: this.translateService.instant(`vens.${ this.ven.id ? 'edit' : 'create' }.verification`),
        secondButtonText: this.translateService.instant('actions.accept'),
        firstButtonText: this.translateService.instant('actions.cancel'),
        secondButtonCallback: () => this.save(),
        firstButtonCallback: () => this.dialog.closeAll(),
      },
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
