import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { MarketContext } from '@models';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GlobalAlertService, LoaderService, MarketContextService } from '@services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'market-context-details',
  templateUrl: './index.html',
  styleUrls: ['./style.scss'],
})
export class MarketContextDetailsViewComponent implements OnInit, OnDestroy {
  BAD_REQUEST = 'Oops, There was a problem with your request';
  UPDATE_SUCCESS = 'Market was updated successfully';
  fieldControls: any[] = [];
  noMarket:boolean = false;
  NO_MARKET = 'Ooops, The Market you requested was not found';
  subscriptions: Subscription[] = [];
  marketContext: MarketContext;
  id_validation = '^(\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12})$';

  @Input() editContextForm: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private marketContextService: MarketContextService,
    private loaderService: LoaderService,
    private messageService: GlobalAlertService,
    private translateService: TranslateService,
    private router: Router,
  ) {
    this.BAD_REQUEST = this.translateService.instant('notification.bad_request');
    this.UPDATE_SUCCESS = this.translateService.instant('markets.edit.notification.success');
    this.subscriptions.push(
      this.route.data.subscribe((data) => {
        if (Object.keys(data['data']).length === 0) {
          this.messageService.setError(this.NO_MARKET);
          this.noMarket = true;
          this.router.navigate([`/markets`]);
        } else {
          this.marketContext = this.pruneObject(data['data']);
          this.setUpForm();
        }
      }),
    );
  }

  pruneObject(object: any): any {
    Object.keys(object).forEach((key) => {
      if (object[key] == null) {
        object[key] = '';
      }
    });
    return object;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscriber) => subscriber.unsubscribe());
  }

  handleEdit = (
    formControl: UntypedFormControl,
    fieldToUpdate: keyof MarketContext,
    shouldUpdate$?: Subject<boolean>,
  ): Observable<unknown> => {
    this.loaderService.setIgnoreNext('market-contexts');
    this.marketContext[fieldToUpdate.valueOf()] = formControl.value;
    this.updateMarketContext(shouldUpdate$, fieldToUpdate);
    return null;
  };

  updateMarketContext(shouldUpdate$?: Subject<boolean> | undefined, fieldToUpdate?: string | undefined): void {
    const updateMarketContextSub = this.marketContextService.updateMarketContext$(this.marketContext.id, this.marketContext).subscribe({
      next :(marketContext: MarketContext) => {
        if (shouldUpdate$) {
          shouldUpdate$.next(true);
        }
        this.marketContext = marketContext;
        this.messageService.setSuccess(this.UPDATE_SUCCESS);
        return marketContext;
      },
      error: () => {
        this.messageService.setError(this.BAD_REQUEST);
        this.fieldControls[fieldToUpdate].setErrors({ incorrect: true });
        if (shouldUpdate$) {
          shouldUpdate$.next(false);
        }
      },
    });
    this.subscriptions.push(updateMarketContextSub);
  }

  private setUpForm(): void {
    Object.keys(this.marketContext).forEach((param) => {
      if (typeof this.marketContext[param] == 'boolean') {
        this.marketContext[param] = JSON.parse(this.marketContext[param]);
        this.fieldControls[param] = new UntypedFormControl(JSON.parse(this.marketContext[param]));
      } else {
        this.fieldControls[param] = new UntypedFormControl(this.marketContext[param]);
      }
    });
  }
}
