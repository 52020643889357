import { Environment } from './environment.interface';

export const environment: Environment = {
    production: true,
    environment: 'preprd',
    login_url: `http://openam.preprd-apps.enelx.com/openam/XUI/`,
    global_nav_url: 'https://global-nav-v2.preprd-svcs.enernoc.net/api/v2',
    app_base: 'preprd-apps.enelx.com',
    open_am_cookie_domain: 'preprd-apps.enelx.com',
	default_brand: 'enelx',
    default_locale: 'en-us'
};

