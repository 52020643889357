import { MarketContext } from './market-context.model';
import { ParticipationPlan } from './participation-plan.model';
import { VEN } from './ven.model';

export interface Event {
    id: string;
    event_id: string;
    start_dttm: string;
    end_dttm: string;
    test_event: boolean;
    archived: boolean;
    cancelled: boolean;
    market: MarketContext | { id: number};
    participation_plans: ParticipationPlan[];
}

export class Event {
    constructor() {
        this.id = null;
        this.event_id = null;
        this.market = null;
        this.start_dttm = null;
        this.end_dttm = null;
        this.test_event = null;
        this.cancelled = false;
        this.archived = null;
    }
}

export interface EventConfirmation {
    confirmation_dttm: string;
    request_id: string;
    opted_in: boolean;
    ven: VEN;
}

export interface Signal {
    display_label: string;
    name: string;
    id: string;
    type: string;
    intervals: Interval[];
}

export interface Interval {
    id: number;
    end_date: string;
    value: number;
}
