import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { PermissionEnum, PermissionType } from '@models';

const appName = 'LEVIATHAN_JR_OADR_VTN';
const REFRESH_INTERVAL = 60000;

@Injectable()
export class PermissionService extends BaseService<PermissionType> {
  private permissions: PermissionType;
  private lastRequest: number;
  override path = '/api/v1/user/permissions';

  getPermissions(): Promise<void> {
      if (!this.permissions || !this.lastRequest || Date.now() > this.lastRequest + REFRESH_INTERVAL) {
          return this.requestPermissions()
            .toPromise()
            .then((res) => {
                this.lastRequest = Date.now();
                this.permissions = res;
            });
      }
      return Promise.resolve();
  }

  hasPermission(requiredPermissions: PermissionEnum[]): boolean {
    const appPermissions = this.permissions[appName] ?? [];

    if (!appPermissions.length || !requiredPermissions.length) {
      return false;
    }

    return requiredPermissions.every(permission =>
      appPermissions.includes(permission),
    );
  }

  private requestPermissions(): Observable<PermissionType> {
    return this.get();
  }
}
