<div class="mb-5">
    <mat-expansion-panel
        [expanded]="true"
        (opened)="panelOpenedState = true"
        (closed)="panelOpenedState = false"
        togglePosition="before"
        class="expansion-panel panel_{{ index }}"
    >
        <mat-expansion-panel-header class="panel-override" collapsedHeight="74px" expandedHeight="48px">
            <mat-panel-title class="point-title">
                <div data-automation="marketId">
                    <a [routerLink]="['/vens', plan.ven.id]">{{ plan.ven.name }} ({{ plan.ven.ven_id }})</a>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>


        <div class="flex flex-col">
            <div class="flex mt-5">
                <!-- Event Status -->
                <div class="flex flex-col w-1/3 mb-10">
                    <div class="flex items-start">
                        <span class="text-light-color text-bold">{{ 'events.table.status' | translate }}:</span>
                    </div>
                    <span class="static-detail">{{ plan.status }}</span>
                </div>

                <!-- Modification number -->
                <div class="flex flex-col w-1/3 mb-10">
                    <div class="flex items-start">
                        <span class="text-light-color text-bold">{{ 'events.modification_number' | translate }}:</span>
                    </div>
                    <div class="flex">
                        {{ plan.modification_number }}
                    </div>
                </div>
            </div>

            <h4>{{ 'events.details.plans.confirmations' | translate }}</h4>
            <table class="table" [dataSource]="plan.event_confirmations" mat-table>
                <!-- Confirmation Dttm Column -->
                <ng-container matColumnDef="confirmation_dttm">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'events.details.plans.confirmation_dttm' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.confirmation_dttm }}
                    </td>
                </ng-container>

                <!-- Confirmation Dttm Column -->
                <ng-container matColumnDef="modification_number">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'events.details.plans.modification_number' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.modification_number }}
                    </td>
                </ng-container>

                <!-- Opt Type Column -->
                <ng-container matColumnDef="opt_type">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'events.details.plans.opted_type' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="element.opted_in">
                            {{ 'events.details.plans.opted_in' | translate }}
                        </div>
                        <div *ngIf="element.opted_in === false">
                            {{ 'events.details.plans.opted_out' | translate }}
                        </div>
                    </td>
                </ng-container>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" [attr.colspan]="confirmationCols.length" style="text-align: center">
                        {{ 'events.details.confirmations.empty' | translate }}
                    </td>
                </tr>

                <tr mat-header-row *matHeaderRowDef="confirmationCols"></tr>
                <tr mat-row *matRowDef="let row; columns: confirmationCols"></tr>
            </table>
        </div>

        <div *ngIf="plan.resources.length > 0" class="mt-5">
            <h4>{{ 'events.details.plans.resources' | translate }}</h4>
            <resource-table [resources]="plan.resources"></resource-table>
        </div>

        <div *ngIf="plan.groups.length > 0" class="mt-5">
            <h4>{{ 'events.details.plans.groups' | translate }}</h4>
            <group-table [resources]="plan.groups"></group-table>
        </div>
    </mat-expansion-panel>
</div>
