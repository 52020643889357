export const GRANULARITIES = [
    {label: 'undefined', value: 0},
    {label: 'onemin', value: 60000},
    {label: 'fivemin', value: 300000},
    {label: 'fifteenmin', value: 900000},
    {label: 'thirtymin', value: 1800000},
    {label: 'onehour', value: 3600000},
    {label: 'oneday', value: 86400000},
    {label: 'onemonth', value: 2592000000},
];

