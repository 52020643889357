import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GlobalAlertOptions } from '@models';

@Injectable()
export class GlobalAlertService {
  private messageEmitter = new Subject<GlobalAlertOptions>();
  message$ = this.messageEmitter.asObservable();

  setSuccess(message: string, timeout: number = 5000): void {
      this.setMessage('success', message, timeout);
  }

  setWarning(message: string, timeout: number = 5000): void {
      this.setMessage('warning', message, timeout);
  }

  setError(message: string, timeout: number = 5000): void {
      this.setMessage('error', message, timeout);
  }

  private setMessage(type: 'success' | 'warning' | 'error', message: string, timeout: number) {
    const options = new GlobalAlertOptions(type, message, timeout);
    this.messageEmitter.next(options);
  }
}
