import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MarketContext } from '@models';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '@components';
import { MarketContextService, GlobalAlertService} from '@services';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'market-context-edit',
  templateUrl: './index.html',
  styleUrls: ['./style.scss'],
})
export class MarketContextEditViewComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  BAD_REQUEST = 'Oops, There was a problem with your request';
  MARKET_SUCCESS = 'Market was created successfully';

  DEFAULT_URL = 'http://<Operator Name>/enelx/<Program name>/<market name>-DR';
  marketContext: Partial<MarketContext> = {
    archived: false,
    context_url: this.DEFAULT_URL,
  };
  id_validation = '^(\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12})$';
  marketForm = new UntypedFormGroup({
    operator: new UntypedFormControl('', Validators.required),
    operator_name: new UntypedFormControl(''),
    program: new UntypedFormControl('', Validators.required),
    program_name: new UntypedFormControl(''),
    context: new UntypedFormControl('', Validators.required),
  });
  get operator() {
    return this.marketForm.get('operator');
  }
  get program() {
    return this.marketForm.get('program');
  }

  submitting = false;
  constructor(
    private translateService: TranslateService,
    public dialog: MatDialog,
    private marketContextService: MarketContextService,
    private ngZone: NgZone,
    private router: Router,
    private messageService: GlobalAlertService,
  ) {
    this.BAD_REQUEST = this.translateService.instant('notification.bad_request');
    this.MARKET_SUCCESS = this.translateService.instant('markets.create.notification.success');
  }

  ngOnInit(): void {
    const operatorSub = this.marketForm.get('operator').valueChanges.subscribe((operator) => {
      this.marketContext.operator_id = operator;
    });
    const operatorNameSub = this.marketForm.get('operator_name').valueChanges.subscribe((operator) => {
      this.marketContext.operator_name = operator;
    });
    const programSub = this.marketForm.get('program').valueChanges.subscribe((program) => {
      this.marketContext.program_id = program;
    });
    const programNameSub = this.marketForm.get('program_name').valueChanges.subscribe((program) => {
      this.marketContext.program_name = program;
    });
    const contextSub = this.marketForm.get('context').valueChanges.subscribe((context) => {
      this.marketContext.context_url = context;
    });
    this.subscriptions.push(...[operatorSub, operatorNameSub, programSub, programNameSub, contextSub]);
  }

  handleEventCancel() {
    this.submitting = false;
    this.router.navigate([`/market-contexts`]);
  }

  createMarketContext() {
    this.submitting = true;
    try {
      const createMarketContextSub = this.marketContextService.createMarketContext$(this.marketContext).subscribe({
        next: (context) => {
          this.ngZone.run(() => {
            this.messageService.setSuccess(this.MARKET_SUCCESS);
            this.router.navigateByUrl(`market/${context.id}`);
          });
        },
        error: () => {
          this.messageService.setError(this.BAD_REQUEST);
        },
      });
      this.subscriptions.push(createMarketContextSub);
    } finally {
      this.submitting = false;
      this.dialog.closeAll();
    }
  }

  openCancelOrAcceptDialog() {
    this.dialog.open(DialogComponent, {
      width: '531px',
      data: {
        title: this.translateService.instant('markets.create.verification'),
        secondButtonText: this.translateService.instant('actions.accept'),
        firstButtonText: this.translateService.instant('actions.cancel'),
        secondButtonCallback: () => this.createMarketContext(),
        firstButtonCallback: () => this.dialog.closeAll(),
      },
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscriber) => subscriber.unsubscribe());
  }
}
