import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BaseTableViewComponent } from '../../common/base-table-view';
import { UnverifiedVen } from '@models';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '@components';
import { GlobalAlertService, UnverifiedVenService } from '@services';

@Component({
  selector: 'unverified-ven-list-view',
  templateUrl: './index.html',
  styleUrls: ['./style.scss'],
})
export class UnverifiedVenListViewComponent extends BaseTableViewComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  unverifiedVen: UnverifiedVen[];
  BAD_REQUEST = 'Oops, There was a problem with your request';
  PROMOTE_SUCCESS: 'Unverified Ven has been successfully promoted';
  UNVERIFIED_VENS_DELETED_SUCCESS = 'Unverified Ven was deleted successfully';

  columnsToDisplay = ['id', 'name', 'fingerprint', 'remote_address', 'last_seen', 'actions'];

  constructor(
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private unverifiedVenService: UnverifiedVenService,
    private messageService: GlobalAlertService,
  ) {
    super();
    this.BAD_REQUEST = this.translateService.instant('notification.bad_request');
    this.UNVERIFIED_VENS_DELETED_SUCCESS = this.translateService.instant('unverified_vens.delete.success');
    this.PROMOTE_SUCCESS = this.translateService.instant('unverified_vens.promote.notification.success');
  }

  ngOnInit(): void {
      this.initialize(this.route);
  }

  override dataCall(qp) {
    this.router.navigate(['unverified-vens'], {replaceUrl: true, queryParams: qp});
    return this.unverifiedVenService.getPage(qp);
  }

  deleteUnverifiedVen(unverifiedVen: UnverifiedVen) {
    try {
      const deleteUnverifiedVenSub = this.unverifiedVenService.deleteUnverifiedVen$(unverifiedVen.id).subscribe({
        next: (response) => {
          this.messageService.setSuccess(this.UNVERIFIED_VENS_DELETED_SUCCESS);
            this.loadData();
        },
        error: () => {
          this.messageService.setError(this.BAD_REQUEST);
        },
      });
      this.subscriptions.push(deleteUnverifiedVenSub);
    } finally {
      this.closeOutDialogs();
    }
  }

  openDeleteDialog(unverifiedVen: UnverifiedVen) {
    this.dialog.open(DialogComponent, {
      width: '531px',
      data: {
        title: this.translateService.instant('unverified_vens.dialog.delete'),
        secondButtonText: this.translateService.instant('actions.accept'),
        firstButtonText: this.translateService.instant('actions.cancel'),
        secondButtonCallback: () => this.deleteUnverifiedVen(unverifiedVen),
        firstButtonCallback: () => this.closeOutDialogs(),
      },
    });
  }

  openPromoteDialog(ven: UnverifiedVen) {
    this.dialog.open(DialogComponent, {
      width: '531px',
      data: {
        title: this.translateService.instant('unverified_vens.dialog.promote'),
        secondButtonText: this.translateService.instant('actions.accept'),
        firstButtonText: this.translateService.instant('actions.cancel'),
        secondButtonCallback: () => this.promoteUnverifiedVen(ven.id),
        firstButtonCallback: () => this.closeOutDialogs(),
      },
    });
  }

  promoteUnverifiedVen(ven_id: string) {
    try {
      const promoteUnverifiedVenSub = this.unverifiedVenService.promoteUnverifiedVen$(ven_id).subscribe({
        next: () => {
          this.messageService.setSuccess(this.PROMOTE_SUCCESS);
          this.loadData();
        },
        error: () => {
          this.messageService.setError(this.BAD_REQUEST);
        },
      });
      this.subscriptions.push(promoteUnverifiedVenSub);
    } finally {
      this.closeOutDialogs();
    }
  }

  closeOutDialogs() {
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscriber) => subscriber.unsubscribe());
  }
}
