export { ComProductService } from './com-product.service';
export { ComRegistrationService } from './com-registration.service';
export { CookieService } from './cookie.service';
export { DashboardService } from './dashboard.service';
export { DialogService } from './dialog.service';
export { EventService } from './event.service'
export { VenService } from './ven.service';
export { RegistrationService } from './registration.service';
export { UnverifiedVenService } from './unverified-ven.service';
export { MarketContextService } from './market-context.service';
export { ResourceService } from './resource.service';
export { GroupService } from './group.service';
export { GlobalAlertService } from './global-alert.service';
export { I18nService } from './i18n.service';
export { IconsService } from './icon.service';
export { LoaderService } from './loader.service';
export { LocalesService } from './locales.service';
export { LoginRedirectService } from './login-redirect.service';
export { PermissionService } from './permission.service';
export { BaseService } from './base.service';
export { PointService } from './point.service';
export { RouterService } from './router.service';
export { ReportService } from './report.service';
export { SidenavService } from './sidenav.service';
export { TimezonesService } from './timezones.service';
