import {
  Component,
  Input,
  EventEmitter,
  OnChanges,
  Output,
} from '@angular/core';
import { BaseService, GroupService, MarketContextService, ResourceService } from '@services';

@Component({
  selector: 'edit-subscriptions',
  templateUrl: './index.html',
  styleUrls: ['./style.scss'],
})
export class EditSubscriptionsComponent implements OnChanges {
  @Input() selected: any[];
  @Input() type: 'resources' | 'groups' | 'markets';
  @Output() update = new EventEmitter();

  fetchedType: string;
  allAvailable: any[] = [];
  available: any[] = [];
  columnsToDisplay = ['action', 'id'];

  public loading: boolean = false;

  constructor(
      private resourceService: ResourceService,
      private groupService: GroupService,
      private marketService: MarketContextService,
  ) {}

  async ngOnChanges() {
    if (this.type === this.fetchedType) {
      return;
    }

    this.selected = this.selected || [];
    this.getService().getList().subscribe((data) => {
      this.allAvailable = data;
      this.selected = this.selected.map(s => ({
        ...s,
        ...(this.allAvailable.find(q => q.id === s.id) || {}),
      }));
      this.fetchedType = this.type;
      this.refreshAvailable();
    });
  }

  toggleElement(element: any, include: boolean) {
    if (include) {
      this.selected = [...this.selected, element];
    } else {
      this.selected = this.selected.filter(q => q.id !== element.id);
    }
    this.updateSelected();
  }

  removeAll() {
    this.selected = [];
    this.updateSelected();
  }

  refreshAvailable() {
    const selected = new Set(this.selected.map(s => s.id));
    this.available = this.allAvailable.filter((element) => !selected.has(element.id));
  }

  updateSelected() {
    this.update.emit(this.selected);
    this.refreshAvailable();
  }

  private getService(): BaseService<any> {
    if (this.type === 'resources') {
        return this.resourceService;
    } else if (this.type === 'groups') {
        return this.groupService;
    }
    return this.marketService;
  }
}
