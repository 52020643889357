<mat-progress-bar
    class="mt-10"
    *ngIf="subscriptionsTotal"
    mode="determinate"
    [value]="(100 * subscriptionsFetched) / subscriptionsTotal">
</mat-progress-bar>

<div *ngIf="warning" class="warning">
    <mat-icon class="mr-3">warning</mat-icon>
    {{ warning | translate }}
</div>

<div *ngIf="ven && reportForm && !subscriptionsTotal" class="flex flex-col">
    <div class="section-header-container">
        <div class="flex-col">
            <h1 class="section-header">{{ 'reports.edit.header' | translate }}</h1>
            <div *ngIf="report.type === 'TELEMETRY_USAGE'">{{ 'reports.edit.usage.subheader' | translate }}</div>
            <div *ngIf="report.type === 'x-OFFERS'">{{ 'reports.edit.offers.subheader' | translate }}</div>
        </div>
        <div class="right-column">
            <button
                class="icon-btn x-btn-secondary"
                aria-label="Cancel"
                (click)="handleEventCancel()"
                data-automation="reportCancel"
            >
                <mat-icon>clear</mat-icon>
                <span class="btn-text">{{ 'actions.cancel' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                aria-label="Submit"
                [disabled]="reportForm.invalid"
                (click)="openCancelOrAcceptDialog()"
                type="submit"
                data-automation="reportSubmit"
            >
                <mat-icon>done</mat-icon>
                <div *ngIf="submitting" class="btn-spinner">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>
                <span class="btn-text">{{ 'actions.submit' | translate }}</span>
            </button>
        </div>
    </div>

    <form #createReport="ngForm" id="reportForm" [formGroup]="reportForm">
        <div class="form-wrapper flex flex-wrap form-group">
            <div class="form-field-wrapper w-1/4">
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label class="text-light-color">{{ 'reports.edit.form.granularity' | translate }}:</mat-label>
                    <mat-select formControlName="granularity" data-automation="granularity">
                        <mat-option *ngFor="let gran of GRANULARITIES" [value]="gran.value">
                            {{ 'reports.edit.form.granularity.' + gran.label | translate }}
                        </mat-option>
                    </mat-select>
                    <mat-hint style="margin: 16px 0">{{ 'reports.edit.form.granularity.hint' | translate }}</mat-hint>
                </mat-form-field>
            </div>
            <div class="form-field-wrapper w-1/4">
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label class="text-light-color">{{ 'reports.edit.form.rbd' | translate }}:</mat-label>
                    <mat-select formControlName="report_back_duration" data-automation="report_back_duration">
                        <!-- values for report_back_duration are the same as the granularity values -->
                        <mat-option *ngFor="let gran of GRANULARITIES" [value]="gran.value">
                            {{ 'reports.edit.form.granularity.' + gran.label | translate }}
                        </mat-option>
                    </mat-select>
                    <mat-hint style="margin: 16px 0">{{ 'reports.edit.form.rbd.hint' | translate }}</mat-hint>
                </mat-form-field>
            </div>
            <div class="form-field-wrapper w-1/4">
                <mat-form-field floatLabel="always" appearance="outline">
                  <mat-label>{{ 'reports.edit.form.range' | translate }}</mat-label>
                  <mat-date-range-input [rangePicker]="range">
                    <input matStartDate placeholder="Start date" formControlName="start_dttm">
                    <input matEndDate placeholder="End date" formControlName="end_dttm">
                  </mat-date-range-input>
                  <mat-hint style="margin: 16px 0">{{ 'reports.edit.form.range.hint' | translate }}</mat-hint>
                  <mat-datepicker-toggle matSuffix [for]="range"></mat-datepicker-toggle>
                  <mat-date-range-picker touchUi #range></mat-date-range-picker>
                </mat-form-field>
            </div>
            <div class="w-1/4">
              <mat-checkbox
                  [disabled]="!allowRequestedDttm()"
                  [checked]="report.requested_dttm !== null"
                  (change)="setReady($event)"
                  matTooltip="{{ 'reports.edit.form.ready_to_request.hint' | translate }}"
              >
                {{ 'reports.edit.form.ready_to_request' | translate }}
              </mat-checkbox>
            </div>
        </div>
    </form>

    <div *ngIf="report && report.descriptions" class="flex flex-col mt-25">
        <h1 class="mb-5">{{ 'reports.edit.descriptions.title' | translate }}</h1>
        <table class="table" [dataSource]="report.descriptions" mat-table>

            <!-- rID -->
            <ng-container matColumnDef="rID">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'reports.edit.descriptions.rid' | translate }}
                </th>
                <td mat-cell *matCellDef="let desc">
                    <ng-container >
                        {{ desc.rID }}
                    </ng-container>
                </td>
            </ng-container>

            <!-- readingType -->
            <ng-container matColumnDef="readingType">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'reports.edit.descriptions.reading_type' | translate }}
                </th>
                <td mat-cell *matCellDef="let desc">
                    <ng-container >
                        {{ desc.readingType }}
                    </ng-container>
                </td>
            </ng-container>

            <!-- reportDataSource -->
            <ng-container matColumnDef="reportDataSource">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'reports.edit.descriptions.report_data_source' | translate }}
                </th>
                <td mat-cell *matCellDef="let desc">
                    <ng-container *ngIf="desc.reportDataSource && desc.reportDataSource.resourceID">
                        {{ 'vens.table.cell.resource_id' | translate }}: {{ desc.reportDataSource.resourceID }}
                    </ng-container>
                    <ng-container *ngIf="desc.reportDataSource && desc.reportDataSource.groupID">
                        {{ 'vens.table.cell.group_id' | translate }}: {{ desc.reportDataSource.groupID }}
                    </ng-container>
                </td>
            </ng-container>

            <!-- Link Selection -->
            <ng-container matColumnDef="link">
                <th mat-header-cell *matHeaderCellDef>
                    <div *ngIf="report.type === 'TELEMETRY_USAGE'">{{ 'reports.edit.descriptions.point' | translate }}</div>
                    <div *ngIf="report.type === 'x-OFFERS'">{{ 'reports.edit.descriptions.registration' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let desc">
                    <ng-container>
                        <mat-form-field floatLabel="always" appearance="outline" class="mb-5">
                            <mat-label *ngIf="report.type === 'TELEMETRY_USAGE'" class="text-light-color">{{ 'reports.edit.descriptions.point' | translate }}:</mat-label>
                            <mat-label *ngIf="report.type === 'x-OFFERS'" class="text-light-color">{{ 'reports.edit.descriptions.registration' | translate }}:</mat-label>
                            <mat-select data-automation="link" [(ngModel)]="report.id_map[desc.rID]" (selectionChange)="selectLink()">
                                <mat-option [value]="undefined"></mat-option>
                                <mat-option *ngFor="let link of selectableLinks(desc)" [value]="link.value">
                                    {{ link.display_labels | locale }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="descriptionCols"></tr>
            <tr mat-row *matRowDef="let row; columns: descriptionCols"></tr>
        </table>
    </div>
</div>
