import { Group, MarketContext, Resource, VEN } from "@models"
import { TranslateService } from "@ngx-translate/core"

export const resourcesBody = (element: Resource, translateService: TranslateService, RESOURCES_TYPE) => [
    element.resource_id,
    element.registration_id,
    element.registration_name,
    translateService.instant(`${RESOURCES_TYPE}.${element.type || 'none'}`),
    element.archived,
    element.vens.map((vens) => vens.id)
];

export const groupBody = (element: Group) => [
    element.portfolio_id,
    element.portfolio_name,
    element.product_id,
    element.product_name,
    element.group_id,
    element.archived,
    element.vens.map((vens) => vens.id)
];

export const marketContextBody = (element: MarketContext) => [
    element.context_url,
    element.operator_id,
    element.operator_name,
    element.program_id,
    element.program_name,
    element.archived,
    element.vens.map((vens) => vens.id)
];
export const vensBody = (element: VEN) => [
    element.name,
    element.ven_id,
    element.fingerprint,
    element.registration,
    element.signal_style,
    element.resources.map((resources) => resources.id),
    element.groups.map((group) => group.id),
    element.markets.map((market) => market.id)
];