import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keepOnlyThreeMonthCharacter',
})
export class KeepOnlyThreeMonthCharacterPipe implements PipeTransform {
  transform(value: string): string {
    const valuesArray = value.split(' ');
    valuesArray[1] = valuesArray[1].substring(0, 3);
    valuesArray[5] = valuesArray[5].substring(0, 3);
    return valuesArray.join(' ');
  }
}
