import { VEN } from "./ven.model";

export interface Group {
    id: string;
    product_id: string;
    product_name: string;
    portfolio_id: string;
    portfolio_name: string;
    group_id: string;
    archived: boolean;
    vens?: Array<VEN>;
}

export class Group {
    constructor() {
        this.id = null;
        this.product_id = null;
        this.product_name = null;
        this.portfolio_id = null
        this.portfolio_name = null;
        this.group_id = null;
        this.archived = null;
        this.vens = [];
    }
}
