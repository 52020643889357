import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DetailsPageHeaderModule } from '@components';
import { ResourceDetailsViewComponent } from './index';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTooltipModule } from '@angular/material/tooltip';
import { InlineEditModule, VensTableModule } from '@components';
import { RouterModule } from "@angular/router";
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [ResourceDetailsViewComponent],
  exports: [ResourceDetailsViewComponent],
    imports: [
        CommonModule,
        InlineEditModule,
        TranslateModule,
        DetailsPageHeaderModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatIconModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        RouterModule,
        MatTableModule,
        VensTableModule
    ],
})
export class ResourceDetailsViewModule {}
