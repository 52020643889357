import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ResourceListViewComponent } from './index';
import { MatTableModule } from "@angular/material/table";
import { RouterModule } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ExportModalModule } from '../../components/export-modal/module';

@NgModule({
  declarations: [ResourceListViewComponent],
  exports: [ResourceListViewComponent],
    imports: [
        CommonModule,
        TranslateModule,
        MatTableModule,
        RouterModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatPaginatorModule,
        MatChipsModule,
        FormsModule,
        MatCheckboxModule,
        MatTooltipModule,
        ExportModalModule,
    ],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA
    ],
})
export class ResourceListViewModule {}
