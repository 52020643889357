import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {GlobalAlertService, MarketContextService} from '@services';

@Injectable()
export class MarketContextsResolver implements Resolve<any> {
    constructor(
        private marketContextsService: MarketContextService,
        private globalAlertService: GlobalAlertService,
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const contextId = route.paramMap.get('contextId') || route.queryParamMap.get('contextId');
        if (contextId) {
            return this.marketContextsService.getMarketContext$(contextId).pipe(
                catchError((err: unknown) => {
                    this.globalAlertService.setError(err.toString());
                    return throwError(() => new Error(err.toString()));
                }),
            );
        }
        return this.marketContextsService.getAllMarketContexts$().pipe(
            catchError((err: unknown) => {
                this.globalAlertService.setError(err.toString());
                return throwError(() => new Error(err.toString()));
            }),
        );
    }
}
