<div class="section-header-container flex-col">
    <div class="section-greeting">
        <mat-icon>info_outline</mat-icon>
        <h3>{{ 'registrations.greeting' | translate }}</h3>
    </div>
</div>
<div class="section-main-container">
    <div class="table-section">
        <div class="section-header-container">
            <h2>{{ 'registrations.title' | translate }}</h2>
        </div>
        <div class="options">
            <div class="search-container">
                <mat-form-field class="search-bar" appearance="outline">
                    <input
                        matInput
                        class="search-input"
                        type="text"
                        placeholder="{{ 'events.search' | translate }}"
                        [(ngModel)]="_search"
                        (keyup.enter)="setSearch()"
                    />
                    <button matSuffix mat-icon-button aria-label="Search" (click)="setSearch()">
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>
                <mat-chip-list *ngIf="search" style="margin-top: 5px; margin-left: 15px">
                    <mat-chip [selectable]="false" (removed)="removeSearch()">
                        {{ search }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
        <div class="table-container-wrapper">
            <table class="table actions-table" [dataSource]="dataSource" mat-table>
                <!-- Registration Column -->
                <ng-container matColumnDef="registration">
                    <th class="reg-column" mat-header-cell *matHeaderCellDef>
                        {{ 'registrations.table.registration' | translate }}
                    </th>
                    <td class="reg-column" mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.registration_id; else dashEmpty">
                            <span>{{ element.registration_id }}</span>
                        </ng-container>
                        <ng-template #dashEmpty> - </ng-template>
                    </td>
                </ng-container>

                <!-- VEN Column -->
                <ng-container matColumnDef="ven">
                    <th class="ven-column" mat-header-cell *matHeaderCellDef>
                        {{ 'registrations.table.ven' | translate }}
                    </th>
                    <td class="ven-column" mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.ven; else dashEmpty">
                            <span>{{ element.ven.ven_id }}</span>
                        </ng-container>
                        <ng-template #dashEmpty> - </ng-template>
                    </td>
                </ng-container>

                <!-- Poll Column -->
                <ng-container matColumnDef="poll">
                    <th class="poll-column" mat-header-cell *matHeaderCellDef>
                        {{ 'registrations.table.poll' | translate }}
                    </th>
                    <td class="poll-column" mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.poll_rate; else dashEmpty">
                            <span>{{ element.poll_rate }}</span>
                        </ng-container>
                        <ng-template #dashEmpty> - </ng-template>
                    </td>
                </ng-container>

                <!-- Buttons Column -->
                <ng-container matColumnDef="actions" stickyEnd>
                    <th class="action-column" mat-header-cell class="actions-header" *matHeaderCellDef>Actions</th>
                    <td class="action-column" mat-cell class="actions-cell" *matCellDef="let element">
                        <div class="btn-container">
                            <button
                                matTooltip="{{ 'actions.delete' | translate }}"
                                class="action-btn"
                                mat-icon-button
                                (click)="openDeleteDialog(element)"
                            >
                                <mat-icon>delete_outline</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" [attr.colspan]="columnsToDisplay.length" style="text-align: center">
                        {{ 'registrations.table.empty' | translate }}
                    </td>
                </tr>
            </table>
        </div>
        <mat-paginator
            [disabled]="loading"
            [length]="total"
            [pageIndex]="page - 1"
            [pageSize]="per_page"
            [pageSizeOptions]="[5, 10, 20, 50]"
            (page)="pageEvent($event)">
        </mat-paginator>
    </div>
</div>
