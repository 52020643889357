import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-shared-services';
import { Subscription } from 'rxjs';
import { LoaderService, SidenavService } from '@services';
import { environment } from 'src/environments/environment';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'leviathan-jr-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('searchInput') input: ElementRef;
  sidenavOpen = false;
  disabledInput = false;
  navUrl: string = environment.global_nav_url;
  subscriptions: Subscription[] = [];
  show = false;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private cookieService: CookieService,
    private sidenavService: SidenavService,
    public loaderService: LoaderService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.loaderService.regexEndpoints = ['ess-parameters', 'permission', 'run', 'v1\/points'];
    this.matIconRegistry.addSvgIcon(
      'noData',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg-icons/no-data.svg'),
    );
  }

  ngOnInit(): void {
    const { default_brand, default_locale } = environment;
    const locale = this.cookieService.getI18NLocale() || default_locale;
    const brand = this.cookieService.getI18NBrand() || default_brand.toUpperCase();
    this.translateService.setDefaultLang(`${default_locale}.${default_brand.toUpperCase()}`);
    let translateSubscription = this.translateService.use(`${locale}.${brand}`).subscribe(() => {
      this.show = true;
      this.translateService.get('APP.TITLE').subscribe((result) => {
        document.title = result;
      });
    });

    const closeSubscription = this.sidenavService.close$.subscribe(() => (this.sidenavOpen = false));

    // TODO: make this call in appInitializer token without scenarios (lazy-load scenarios)

    this.subscriptions.push(...[translateSubscription, closeSubscription]);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ngAfterViewInit(): void {
    const logo = document.querySelector('.logo') as HTMLElement;
    if (logo) {
        logo.style.cursor = 'pointer';
        logo.addEventListener('click', () => {
          this.router.navigateByUrl('/');
        });
    }

    this.sidenavService.setSidenav(this.sidenav);
  }

  isLargeScreen(): boolean {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width > 1280;
  }
}
