<table class="table" [dataSource]="groups" mat-table>
    <!-- Group Label Column -->
    <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef>
            {{ 'groups.table.label' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
            <a [routerLink]="['/groups', element.id ? element.id : null]">{{
                element.group_id ? element.group_id : '-'
            }}</a>
        </td>
    </ng-container>

    <!-- Portfolio id Column -->
    <ng-container matColumnDef="portfolio">
        <th mat-header-cell *matHeaderCellDef>
            {{ 'groups.table.portfolio' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
            <span>
                <span class="title-bold">{{ 'groups.table.cell.portfolio_id' | translate }}:</span>
                {{ element.portfolio_id ? element.portfolio_id : '-' }}
            </span>
            <br />
            <span>
                <span class="title-bold">{{ 'groups.table.cell.portfolio_name' | translate }}:</span>
                {{ element.portfolio_name ? element.portfolio_name : '-' }}
            </span>
        </td>
    </ng-container>

    <!-- Product Name Column -->
    <ng-container matColumnDef="product">
        <th mat-header-cell *matHeaderCellDef>
            {{ 'groups.table.product' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
            <span>
                <span class="title-bold">{{ 'groups.table.cell.product_id' | translate }}:</span>
                {{ element.product_id ? element.product_id : '-' }}
            </span>
            <br />
            <span>
                <span class="title-bold">{{ 'groups.table.cell.product_name' | translate }}:</span>
                {{ element.product_name ? element.product_name : '-' }}
            </span>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="groupCols"></tr>
    <tr mat-row *matRowDef="let row; columns: groupCols"></tr>
</table>
