import {Injectable} from '@angular/core';
import{ Observable } from 'rxjs';
import { BaseService } from './base.service';
import { Event } from "@models";

@Injectable()
export class EventService extends BaseService<Event> {
    override path = '/api/v1/events';

    getAllEvents$(): Observable<Event[]> {
        return this.getList();
    }

    getEvent$(eventId: number | string): Observable<Event> {
        return this.getById(eventId);
    }

    updateEvent$(eventId : number | string, event: Event | Partial<Event>): Observable<Event> {
        return this.update(eventId, event);
    }
}

