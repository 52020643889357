import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CookieService, LoginRedirectService } from '@services';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    private loginRedirect: LoginRedirectService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
      if (!this.cookieService.getEnocSession()) {
        this.loginRedirect.loadLoginPage();
      }
      const requestAuth = request.clone({
        setHeaders: {
          Authorization: this.cookieService.getEnocSession(),
        },
      });
      return next.handle(requestAuth);
  }
}
