<div class="console-menu">
  <div class="w-full flex justify-center items-center title-heading">
    <div class="flex justify-start items-center justify-self-start margin-right-auto icon-header header-container">
      <div class="console-header" routerLink="/">
        <div class="icon-container">
          <mat-icon svgIcon='manage-white' class='header-icon'></mat-icon>
        </div>
       <div>
          <h1>{{ 'tabs.header' | translate}}</h1>
        </div>
      </div>
      <div class="site-tabs">
        <div class="flex items-baseline">
          <h3 routerLink="/" routerLinkActive="active-heading" [routerLinkActiveOptions]="{exact: true}" id="lrj-home-tab" class="tab">
            {{ 'tabs.home' | translate}}
          </h3>
          <h3 [matMenuTriggerFor]="vensMenu"
              [ngClass]="{'active-heading':
                vens.isActive ||
                unverifiedVens.isActive ||
                registrations.isActive ||
                editVen.isActive
                }"
              id="lrj-oadr-tab" class="tab">
            {{ 'tabs.oadr' | translate}}<mat-icon>keyboard_arrow_down</mat-icon>
          </h3>
          <mat-menu #vensMenu="matMenu">
            <div mat-menu-item routerLink="vens" routerLinkActive #vens="routerLinkActive">
              <h3 class="tab">{{ 'tabs.oadr.vens' | translate}}</h3>
            </div>
            <div mat-menu-item routerLink="unverified-vens" routerLinkActive #unverifiedVens="routerLinkActive">
              <h3 class="tab">{{ 'tabs.oadr.unverified_vens' | translate}}</h3>
            </div>
            <div mat-menu-item routerLink="ven-registrations" routerLinkActive #registrations="routerLinkActive">
              <h3 class="tab">{{ 'tabs.oadr.registrations' | translate}}</h3>
            </div>
          </mat-menu>
          <h3 [matMenuTriggerFor]="eventsMenu"
              [ngClass]="{'active-heading':
                events.isActive ||
                markets.isActive ||
                details.isActive ||
                resources.isActive ||
                groups.isActive ||
                createContext.isActive ||
                editContext.isActive ||
                createResource.isActive ||
                createGroup.isActive ||
                editResource.isActive ||
                editGroup.isActive
                }"
              id="lrj-events-tab" class="tab">
            {{ 'tabs.event' | translate}}<mat-icon>keyboard_arrow_down</mat-icon>
          </h3>
          <mat-menu #eventsMenu="matMenu">
            <div mat-menu-item routerLink="events" routerLinkActive #events="routerLinkActive">
              <h3 class="tab">{{ 'tabs.event.events' | translate}}</h3>
            </div>
            <div mat-menu-item routerLink="market-contexts" routerLinkActive #markets="routerLinkActive">
              <h3 class="tab">{{ 'tabs.event.market_contexts' | translate}}</h3>
            </div>
            <div mat-menu-item routerLink="resources" routerLinkActive #resources="routerLinkActive">
              <h3 class="tab">{{ 'tabs.event.resources' | translate}}</h3>
            </div>
            <div mat-menu-item routerLink="groups" routerLinkActive #groups="routerLinkActive">
              <h3 class="tab">{{ 'tabs.event.groups' | translate}}</h3>
            </div>
          </mat-menu>

          <!--Hidden routerLinks to trigger the active heading on correct tabs-->
          <span hidden routerLink="events" routerLinkActive #details="routerLinkActive"></span>
          <span hidden routerLink="market-contexts" routerLinkActive #editContext="routerLinkActive"></span>
          <span hidden routerLink="resources" routerLinkActive #editResource="routerLinkActive"></span>
          <span hidden routerLink="groups" routerLinkActive #editGroup="routerLinkActive"></span>
          <span hidden routerLink="vens" routerLinkActive #editVen="routerLinkActive"></span>
          <span hidden routerLink="market-contexts/create" routerLinkActive #createContext="routerLinkActive"></span>
          <span hidden routerLink="resources/create" routerLinkActive #createResource="routerLinkActive"></span>
          <span hidden routerLink="groups/create" routerLinkActive #createGroup="routerLinkActive"></span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="page-container">
  <router-outlet></router-outlet>
</div>
