import { Component } from '@angular/core';
import { GlobalAlertOptions } from '@models';
import { GlobalAlertService } from '@services';

@Component({
    selector: 'leviathan-jr-global-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
    isErrorAlertVisible: boolean;
    message: GlobalAlertOptions;

    constructor(private globalAlertService: GlobalAlertService) {
        this.isErrorAlertVisible = false;
        this.globalAlertService.message$.subscribe(message => {
            this.displayAlert(message);
        });
    }

    private displayAlert(message): void {
        this.message = message;
        this.isErrorAlertVisible = true;
        setTimeout(() => {
            this.isErrorAlertVisible = false;
        }, this.message.timeout);
    }

    get isError(): boolean {
        return this.message.type === 'error';
    }

    get isSuccess(): boolean {
        return this.message.type === 'success';
    }

    get isWarning(): boolean {
        return this.message.type === 'warning';
    }

    get messageClassName(): {[className: string]: boolean} {
        return {
            'global-message': true,
            alert: true,
            'alert-danger': this.isError,
            'alert-warning': this.isWarning,
            'alert-success': this.isSuccess,
        };
    }

    get iconClassName(): {[className: string]: boolean} {
        return {
            icon: true,
            fa: true,
            'fa-ban': this.isError,
            'fa-exclamation-triangle': this.isWarning,
            'fa-check-circle': this.isSuccess,
        };
    }
}
