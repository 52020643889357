export function millisecondsToIsoDuration(ms) {
    // Calculate years, months, days, hours, minutes, seconds, and milliseconds
    const years = Math.floor(ms / (365.25 * 24 * 60 * 60 * 1000));  // Approximate years
    ms -= years * (365.25 * 24 * 60 * 60 * 1000);

    const months = Math.floor(ms / (30 * 24 * 60 * 60 * 1000));  // Approximate months
    ms -= months * (30 * 24 * 60 * 60 * 1000);

    const days = Math.floor(ms / (24 * 60 * 60 * 1000));
    ms -= days * (24 * 60 * 60 * 1000);

    const hours = Math.floor(ms / (60 * 60 * 1000));
    ms -= hours * (60 * 60 * 1000);

    const minutes = Math.floor(ms / (60 * 1000));
    ms -= minutes * (60 * 1000);

    const seconds = Math.floor(ms / 1000);
    ms -= seconds * 1000;

    // Format the ISO 8601 duration string
    let isoDuration = "P";  // Start of duration

    if (years > 0) isoDuration += `${years}Y`;
    if (months > 0) isoDuration += `${months}M`;
    if (days > 0) isoDuration += `${days}D`;

    if (hours > 0 || minutes > 0 || seconds > 0) {
        isoDuration += "T";  // Time section separator
        if (hours > 0) isoDuration += `${hours}H`;
        if (minutes > 0) isoDuration += `${minutes}M`;
        if (seconds > 0) isoDuration += `${seconds}S`;
    }

    return isoDuration;
}