<div class="section-header-container flex-col">
    <div class="section-greeting">
        <mat-icon>info_outline</mat-icon>
        <h3>{{ 'markets.greeting' | translate }}</h3>
    </div>
</div>
<div class="section-main-container">
    <div class="table-section">
        <div class="section-header-container">
            <h2>{{ 'markets.title' | translate }}</h2>
            <div class="button-group">
                <dsy-button class="btn-color" density="compact" label="Small Button" icon-side="left" icon-name="add"
                    icon-library="system" icon-size="1em" icon-white="" variant="primary" shape="round"
                    (click)="navigateTo()" color="accent">
                    {{ 'markets.create.button' | translate }}
                </dsy-button>
                <dsy-button class="btn-color" density="compact" label="Small Button" icon-side="left" icon-name="arrow-up"
                    icon-library="system" icon-size="1em" icon-white="" variant="primary" shape="round" (click)="openModal()">
                    {{ 'markets.export.button' | translate }}
                </dsy-button>
            </div>
        </div>
        <div class="options">
            <div class="search-container">
                <mat-form-field class="search-bar" appearance="outline">
                    <input
                        matInput
                        class="search-input"
                        type="text"
                        placeholder="{{ 'events.search' | translate }}"
                        [(ngModel)]="_search"
                        (keyup.enter)="setSearch()"
                    />
                    <button matSuffix mat-icon-button aria-label="Search" (click)="setSearch()">
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>
                <mat-chip-list *ngIf="search" style="margin-top: 5px; margin-left: 15px">
                    <mat-chip [selectable]="false" (removed)="removeSearch()">
                        {{ search }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
            <mat-checkbox [(ngModel)]="archived" (change)="updateParams()">
                <h3>{{ 'actions.show_archived' | translate }}</h3>
            </mat-checkbox>
        </div>
        <div class="table-container-wrapper">
            <table class="table actions-table" [dataSource]="dataSource" mat-table>
                <!-- Market id Column -->
                <ng-container matColumnDef="marketId">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'markets.table.context' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.id && element.id !== null; else dashEmpty">
                            <a [routerLink]="['/market-contexts', element.id]">{{ element.context_url }}</a>
                        </ng-container>
                        <ng-template #dashEmpty> - </ng-template>
                    </td>
                </ng-container>
                <!-- Operator id Column -->
                <ng-container matColumnDef="operator">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'markets.table.operator' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span>
                            <span class="title-bold">{{ 'markets.table.cell.operator_id' | translate }}:</span>
                            {{ element.operator_id ? element.operator_id : '-' }}
                        </span>
                        <br />
                        <span>
                            <span class="title-bold">{{ 'markets.table.cell.operator_name' | translate }}:</span>
                            {{ element.operator_name ? element.operator_name : '-' }}
                        </span>
                    </td>
                </ng-container>

                <!-- Program Id Column -->
                <ng-container matColumnDef="program">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'markets.table.program' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span>
                            <span class="title-bold">{{ 'markets.table.cell.program_id' | translate }}:</span>
                            {{ element.program_id ? element.program_id : '-' }}
                        </span>
                        <br />
                        <span>
                            <span class="title-bold">{{ 'markets.table.cell.program_name' | translate }}:</span>
                            {{ element.program_name ? element.program_name : '-' }}
                        </span>
                    </td>
                </ng-container>

                <!-- Buttons Column -->
                <ng-container matColumnDef="actions" stickyEnd>
                    <th mat-header-cell class="actions-header" *matHeaderCellDef>
                        {{ 'markets.table.actions' | translate }}
                    </th>
                    <td mat-cell class="actions-cell" *matCellDef="let element" [ngSwitch]="element.archived">
                        <div class="btn-container">
                            <button
                                matTooltip="{{ 'actions.edit' | translate }}"
                                class="action-btn"
                                mat-icon-button
                                [routerLink]="['/market-contexts', element.id]"
                            >
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button
                                matTooltip="{{ 'actions.disable' | translate }}"
                                class="action-btn"
                                mat-icon-button
                                *ngSwitchCase="false"
                                (click)="openDisableDialog(element)"
                            >
                                <mat-icon>do_not_disturb</mat-icon>
                            </button>
                            <button
                                matTooltip="{{ 'actions.restore' | translate }}"
                                class="action-btn"
                                mat-icon-button
                                *ngSwitchCase="true"
                                (click)="openRestoreDialog(element)"
                            >
                                <mat-icon>restore</mat-icon>
                            </button>
                            <button
                                matTooltip="{{ 'actions.delete' | translate }}"
                                class="action-btn"
                                mat-icon-button
                                (click)="openDeleteDialog(element)"
                            >
                                <mat-icon>delete_outline</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" [attr.colspan]="columnsToDisplay.length" style="text-align: center">
                        {{ 'markets.table.empty' | translate }}
                    </td>
                </tr>
            </table>
        </div>
        <mat-paginator
            [disabled]="loading"
            [length]="total"
            [pageIndex]="page - 1"
            [pageSize]="per_page"
            [pageSizeOptions]="[5, 10, 20, 50]"
            (page)="pageEvent($event)">
        </mat-paginator>
    </div>
</div>
<leviathan-jr-modal *ngIf="isOpenModal"></leviathan-jr-modal>