import { Component } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Report, Resource, VEN } from '@models';
import { GlobalAlertService, ReportService } from '@services';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '@components';
import { TranslateService } from '@ngx-translate/core';
import { GRANULARITIES } from '../../constants';

@Component({
  selector: 'ven-details-view',
  templateUrl: './index.html',
  styleUrls: ['./style.scss'],
})
export class VenDetailsViewComponent {
  fieldControls: any[] = [];
  BAD_REQUEST = 'Oops, there was a problem with your request';
  SIGNAL_STYLES: string[] = ['reduceBy', 'reduceTo'];
  GRANULARITIES = GRANULARITIES.reduce((res, g) => ({...res, [g.value]: g.label}), {});

  noVen = false;
  NO_VEN = 'Ooops, The Ven you requested was not found';
  subscriptions: Subscription[] = [];
  ven: VEN;
  reports: Report[];

  availableResources: Resource[] = [];
  id_validation = '^(\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12})$';
  nullableFields = ['registration', 'ven_id', 'resources', 'markets', 'groups'];
  marketCols = ['market_id', 'operator', 'program'];
  reportCols = ['direction', 'report_type', 'granularity', 'descriptions_count', 'range', 'requested_dttm', 'canceled', 'actions'];

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private messageService: GlobalAlertService,
    private reportService: ReportService,
    private router: Router,
  ) {
    this.BAD_REQUEST = this.translateService.instant('notification.bad_request');
    this.subscriptions.push(
      this.route.data.subscribe((data) => {
        if (!data || !data['data']) {
          this.messageService.setError(this.NO_VEN);
          this.noVen = true;
          this.router.navigate([`/vens`]);
        } else {
          this.ven = data['data'];
          this.fetchReports();
        }
      })
    );
  }

  fetchReports() {
    const params = new HttpParams({fromObject: {per_page: 1000, ven_id: this.ven.id}});
    this.reportService.getPage(params)
      .then(res => this.reports = res.data);
  }

  reportActionSuccess(message) {
    const msg = this.translateService.instant('vens.details.reports.delete.success');
    this.messageService.setSuccess(msg);
    this.fetchReports();
    this.dialog.closeAll();
  }

  openCancelReportDialog(report: Report) {
    this.dialog.open(DialogComponent, {
      width: '531px',
      data: {
        title: this.translateService.instant('vens.details.reports.cancel.confirm'),
        secondButtonText: this.translateService.instant('actions.accept'),
        firstButtonText: this.translateService.instant('actions.cancel'),
        secondButtonCallback: () => {
            this.reportService
                .update(report.id, {canceled_dttm: new Date().toISOString()})
                .toPromise()
                .then(() => this.reportActionSuccess('vens.details.reports.cancel.success'));
        },
        firstButtonCallback: () => this.dialog.closeAll(),
      },
    });
  }

  openArchiveReportDialog(report: Report) {
    this.dialog.open(DialogComponent, {
      width: '531px',
      data: {
        title: this.translateService.instant('vens.details.reports.archive.confirm'),
        secondButtonText: this.translateService.instant('actions.accept'),
        firstButtonText: this.translateService.instant('actions.cancel'),
        secondButtonCallback: () => {
            this.reportService
                .update(report.id, {archived: true})
                .toPromise()
                .then(() => this.reportActionSuccess('vens.details.reports.archive.success'));
        },
        firstButtonCallback: () => this.dialog.closeAll(),
      },
    });
  }
  navigateCreateReports(){
    this.router.navigate([`vens/${this.ven.id}/vtn-reports/create`]);
  }
}
