export interface UnverifiedVen {
    id?: string;
    ven_id: string;
    display_label: string;
    fingerprint: string;
    remote_ip: string;
    end_time: string;
    last_seen_dttm: string;
}

export class UnverifiedVen {
    constructor() {
        this.id = null;
        this.fingerprint = null;
        this.display_label = null;
        this.remote_ip = null;
        this.end_time = null;
        this.last_seen_dttm = null;
    }
}
