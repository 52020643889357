import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ParticipationPlanDisplayComponent } from './index';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { GroupTableModule, ResourceTableModule } from '@components';


@NgModule({
  declarations: [ParticipationPlanDisplayComponent],
  exports: [ParticipationPlanDisplayComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    MatTableModule,
    MatTooltipModule,
    MatExpansionModule,
    ResourceTableModule,
    GroupTableModule,
  ],
})
export class ParticipationPlanDisplayModule {}
