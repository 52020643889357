<div *ngIf="show">
<leviathan-jr-global-alert></leviathan-jr-global-alert>

<global-nav [navUrl]="navUrl"></global-nav>

<mat-sidenav-container (backdropClick)="sidenavOpen = false">
    <mat-sidenav-content data-automation="sidenav-content" [class.sidenav-content-stretch]="!sidenavOpen">
        <div data-automation="scrollable-container" class="full overflow-auto">
            <div data-automation="main-container" class="main-container">
                <leviathan-jr-loader></leviathan-jr-loader>
                <leviathan-jr></leviathan-jr>
                <global-footer></global-footer>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
</div>
