<div class="section-header-container">
    <div class="flex-col">
        <h1 class="section-header">{{ 'markets.create.header' | translate }}</h1>
    </div>
    <div class="right-column">
        <button
            class="icon-btn x-btn-secondary"
            aria-label="Cancel"
            (click)="handleEventCancel()"
            data-automation="contextCancel"
        >
            <mat-icon>clear</mat-icon>
            <span class="btn-text">{{ 'actions.cancel' | translate }}</span>
        </button>

        <button
            class="icon-btn x-btn-primary"
            aria-label="Submit"
            [disabled]="marketForm.invalid"
            (click)="openCancelOrAcceptDialog()"
            type="submit"
            data-automation="contextSubmit"
        >
            <mat-icon>done</mat-icon>
            <div *ngIf="submitting" class="btn-spinner">
                <mat-spinner diameter="15"></mat-spinner>
            </div>
            <span class="btn-text">{{ 'actions.submit' | translate }}</span>
        </button>
    </div>
</div>
<form #createContext="ngForm" id="marketForm" [formGroup]="marketForm">
    <div class="form-wrapper flex flex-wrap form-group">
        <!--Operator ID -->
        <div class="form-field-wrapper">
            <div>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label class="text-light-color">{{ 'markets.create.form.operator' | translate }}:</mat-label>
                    <input
                        matInput
                        id="operator"
                        name="operator"
                        required
                        [pattern]="id_validation"
                        formControlName="operator"
                        data-automation="operator"
                        placeholder="{{ 'markets.create.form.placeholder.operator' | translate }}"
                    />
                    <mat-error *ngIf="operator.hasError('required')">
                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                    <mat-error *ngIf="operator.hasError('pattern')">
                        <span class="input-entry">{{ 'common.validation.uuid' | translate }}</span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <!--Operator Name -->
        <div class="form-field-wrapper">
            <div>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label class="text-light-color"
                        >{{ 'markets.create.form.operator_name' | translate }}:</mat-label
                    >
                    <input
                        matInput
                        formControlName="operator_name"
                        data-automation="operator_name"
                        placeholder="{{ 'markets.create.form.placeholder.operator_name' | translate }}"
                    />
                </mat-form-field>
            </div>
        </div>

        <!--Program ID -->
        <div class="form-field-wrapper">
            <div>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label class="text-light-color">{{ 'markets.create.form.program' | translate }}:</mat-label>
                    <input
                        id="program"
                        name="program"
                        required
                        matInput
                        [pattern]="id_validation"
                        formControlName="program"
                        data-automation="program"
                        placeholder="{{ 'markets.create.form.placeholder.program' | translate }}"
                    />
                    <mat-error *ngIf="program.hasError('required')">
                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                    <mat-error *ngIf="program.hasError('pattern')">
                        <span class="input-entry">{{ 'common.validation.uuid' | translate }}</span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <!--Program Name -->
        <div class="form-field-wrapper">
            <div>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label class="text-light-color"
                        >{{ 'markets.create.form.program_name' | translate }}:</mat-label
                    >
                    <input
                        matInput
                        formControlName="program_name"
                        data-automation="program_name"
                        placeholder="{{ 'markets.create.form.placeholder.program_name' | translate }}"
                    />
                </mat-form-field>
            </div>
        </div>

        <!-- Context URI -->
        <div class="form-field-wrapper">
            <div>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label class="text-light-color">{{ 'markets.create.form.context' | translate }}:</mat-label>
                    <input
                        matInput
                        formControlName="context"
                        data-automation="context"
                        placeholder="{{ 'markets.create.form.placeholder.context' | translate }}"
                    />
                    <mat-error *ngIf="marketForm.get('context').hasError('required')">
                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                </mat-form-field>
                <div class="hint">
                    <span class="hint">{{ DEFAULT_URL }}</span>
                </div>
            </div>
        </div>
    </div>
</form>
