interface ReportDescription {
    rID: string;
    reportType: string;
    readingType: string;
    marketContext: string;
    samplingRate: any;
    reportDataSource: any;
}


export interface Report {
    id?: string;
    report_request_id: string;
    specifier_id: string;
    type: string;
    id_map: {[rid: string]: string};
    ven_id: number;
    granularity: number;
    report_back_duration: number;
    start_dttm: string;
    end_dttm: string;
    requested_dttm?: string;
    descriptions: ReportDescription[];
    ven_acknowledged_dttm?: string;
    canceled_dttm?: string;
    ven_canceled_dttm?: string;
    archived?: boolean;
}

export class Report implements Report {}
