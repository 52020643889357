import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Resource } from '@models';
import { GlobalAlertService, ResourceService } from '@services';

@Injectable()

export class DetailResourceResolver implements Resolve<Resource> {
    constructor(
        private resourceService: ResourceService,
        private globalAlertService: GlobalAlertService,
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Resource> {
        const resourceId = route.paramMap.get('resourceId') || route.queryParamMap.get('resourceId');
        return this.resourceService.getResource$(resourceId).pipe(
            catchError((err: unknown) => {
                this.globalAlertService.setError(err.toString());
                return throwError(() => new Error(err.toString()));
            }),
        );
    }
}
