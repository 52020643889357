<div class="section-header-container">
    <div class="flex-col">
        <h1 class="section-header">{{ 'groups.create.header' | translate }}</h1>
    </div>
    <div class="right-column">
        <button
            class="icon-btn x-btn-secondary"
            aria-label="Cancel"
            (click)="handleEventCancel()"
            data-automation="contextCancel"
        >
            <mat-icon>clear</mat-icon>
            <span class="btn-text">{{ 'actions.cancel' | translate }}</span>
        </button>

        <button
            class="icon-btn x-btn-primary"
            aria-label="Submit"
            [disabled]="groupForm.invalid"
            (click)="openCancelOrAcceptDialog()"
            type="submit"
            data-automation="contextSubmit"
        >
            <mat-icon>done</mat-icon>
            <div *ngIf="submitting" class="btn-spinner">
                <mat-spinner diameter="15"></mat-spinner>
            </div>
            <span class="btn-text">{{ 'actions.submit' | translate }}</span>
        </button>
    </div>
</div>
<form #createGroup="ngForm" id="groupForm" [formGroup]="groupForm">
    <div class="form-wrapper flex flex-wrap form-group">
        <!-- Portfolio ID -->
        <div class="form-field-wrapper">
            <div>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label class="text-light-color">{{ 'groups.create.form.portfolio' | translate }}:</mat-label>
                    <input
                        matInput
                        id="portfolio"
                        name="portfolio"
                        required
                        [pattern]="id_validation"
                        formControlName="portfolio"
                        data-automation="portfolio"
                        placeholder="{{ 'groups.create.form.placeholder.portfolio' | translate }}"
                    />

                    <mat-error *ngIf="portfolio.hasError('required')">
                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                    <mat-error *ngIf="portfolio.hasError('pattern')">
                        <span class="input-entry">{{ 'common.validation.uuid' | translate }}</span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <!-- Portfolio Name -->
        <div class="form-field-wrapper">
            <div>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label class="text-light-color"
                        >{{ 'groups.create.form.portfolio_name' | translate }}:</mat-label
                    >
                    <input
                        matInput
                        formControlName="portfolio_name"
                        data-automation="portfolio_name"
                        placeholder="{{ 'groups.create.form.placeholder.portfolio_name' | translate }}"
                    />
                </mat-form-field>
            </div>
        </div>

        <!-- Product ID -->
        <div class="form-field-wrapper">
            <div>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label class="text-light-color">{{ 'groups.create.form.product' | translate }}:</mat-label>
                    <input
                        matInput
                        id="product"
                        name="product"
                        required
                        [pattern]="id_validation"
                        formControlName="product"
                        data-automation="product"
                        placeholder="{{ 'groups.create.form.placeholder.product' | translate }}"
                    />
                    <mat-error *ngIf="product.hasError('required')">
                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                    <mat-error *ngIf="product.hasError('pattern')">
                        <span class="input-entry">{{ 'common.validation.uuid' | translate }}</span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <!-- Product Name -->
        <div class="form-field-wrapper">
            <div>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label class="text-light-color">{{ 'groups.create.form.product_name' | translate }}:</mat-label>
                    <input
                        matInput
                        formControlName="product_name"
                        data-automation="product_name"
                        placeholder="{{ 'groups.create.form.placeholder.product_name' | translate }}"
                    />
                </mat-form-field>
            </div>
        </div>

        <!-- Group Label -->
        <div class="form-field-wrapper">
            <div>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label class="text-light-color">{{ 'groups.create.form.label' | translate }}:</mat-label>
                    <input
                        matInput
                        formControlName="label"
                        data-automation="label"
                        placeholder="{{ 'groups.create.form.placeholder.label' | translate }}"
                    />
                    <mat-error *ngIf="groupForm.get('label').hasError('required')">
                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
</form>
