import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { VensTableComponent } from './vens-table.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [VensTableComponent],
  exports: [VensTableComponent],
  imports: [BrowserModule, TranslateModule, MatTableModule, MatIconModule, RouterModule],
  bootstrap: [VensTableComponent],
})
export class VensTableModule { }
